import React, { useContext, useEffect, useState } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import Logo from '../multimedia/imagenes/logo-iniciales-oscuro.png'
import { context } from '../Context/Context';

const NavbarPortal = () => {

    const navbar = document.getElementById('navbar-multimedia');

    const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
    const [transformed, setTransformed] = useState(false);
    const [toggleSearch, setToggleSearch] = useState(false);
    const [query, setQuery] = useState("");
    const [checked, setChecked] = useState(false);
    const navegar = useNavigate()

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;
            if (currentScrollPos > prevScrollPos) {
                setTransformed(true);
            } else {
                setTransformed(false);
                setToggleSearch(false)
            }
            setPrevScrollPos(currentScrollPos);
        };
    
        if (window.innerWidth >= 1000) {
            window.addEventListener('scroll', handleScroll);
        }
    
        return () => {
            if (window.innerWidth >= 1000) {
                window.removeEventListener('scroll', handleScroll);
            }
        };
    }, [prevScrollPos]);
    
    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && query.trim() !== '') {
            navegar(`/portal-de-noticias/busqueda/${query}`);
        }
    };
    
    return(
        <nav className={`navbar-multimedia container-fluid px-0 mx-0 ${transformed ? 'transformed' : ''}`} id='navbar-multimedia'>
            <div className="container-fluid px-0 mx-0">
                <div className="d-flex flex-column">
                    <div className="search-bar mx-3 rounded-4" onMouseEnter={()=>{
                        setToggleSearch(true)
                    }}>
                        <div className="d-flex w-100 justify-content-between align-items-center h-100 ps-3">
                            <div className="search-container">
                                <svg width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round"
                                    onClick={()=>{
                                        if(query!=""){
                                            navegar(`/portal-de-noticias/busqueda/${query}`)
                                        }
                                    }} 
                                    >
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" />
                                    <path d="M21 21l-6 -6" />
                                </svg>
                                <input className={`search-field rounded-4 ${toggleSearch ? 'search-field-expanded' : ''}`} placeholder='Escriba su busqueda aquí...' type="text" onChange={(e) => { setQuery(e.target.value) }} onKeyPress={handleKeyPress} />
                            </div>
                            <div className="socials h-100 pe-3">
                                <ul className='d-flex h-100 align-items-center list-unstyled gap-3'>
                                    <li>
                                        <a href="https://www.youtube.com/channel/UC2beEC4cxsAIzpcYtyryb-Q" target='_blank'>
                                            <svg width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                <path d="M3 5m0 4a4 4 0 0 1 4 -4h10a4 4 0 0 1 4 4v6a4 4 0 0 1 -4 4h-10a4 4 0 0 1 -4 -4z" />
                                                <path d="M10 9l5 3l-5 3z" />
                                            </svg>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/fispsalud/" target='_blank'>
                                            <svg width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                <path d="M4 4m0 4a4 4 0 0 1 4 -4h8a4 4 0 0 1 4 4v8a4 4 0 0 1 -4 4h-8a4 4 0 0 1 -4 -4z" />
                                                <path d="M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                                                <path d="M16.5 7.5l0 .01" />
                                            </svg>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.facebook.com/fispsalud" target='_blank'>
                                            <svg width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3" />
                                            </svg>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/company/fisp-org/" target='_blank'>
                                            <svg width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                <path d="M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z" />
                                                <path d="M8 11l0 5" />
                                                <path d="M8 8l0 .01" />
                                                <path d="M12 16l0 -5" />
                                                <path d="M16 16v-3a2 2 0 0 0 -4 0" />
                                            </svg>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.tiktok.com/@fispsalud" target='_blank'>
                                            <svg width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M9 12a4 4 0 1 0 4 4v-12a5 5 0 0 0 5 5"></path>
                                            </svg>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://open.spotify.com/show/5VlWWkUHnHON4PIodMQw79?si=mKHoxjCURxW336L2W-R1CA&nd=1" target='_blank'>
                                            <svg width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                                                <path d="M8 11.973c2.5 -1.473 5.5 -.973 7.5 .527" />
                                                <path d="M9 15c1.5 -1 4 -1 5 .5" />
                                                <path d="M7 9c2 -1 6 -2 10 .5" />
                                            </svg>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <input type="checkbox" id="hamburger_checkbox"  onClick={()=>{setChecked(!checked)}}/>
                            <label className="hamburger_button me-3" htmlFor="hamburger_checkbox">
                                <span></span>
                                <span></span>
                                <span></span>
                            </label>
                        </div>
                    </div>
                    <div className="container-xxl d-flex align-items-center flex-column">
                        <div className="logo-container">
                            <Link className='h-100 d-flex align-items-center' to="/">
                                <img className='w-100 h-75' src={Logo} alt="logo-fisp" />
                            </Link>
                        </div>
                        <div className={`items ${checked ? 'hamburger_expanded' : ''} w-100 d-flex justify-content-center`}>
                            <li className='section-item p-2 roboto'><Link to='/portal-de-noticias/categoria/datafisp'>DataFISP</Link></li>
                            <li className='section-item p-2 roboto'><Link to='/portal-de-noticias/categoria/desde-adentro'>Desde Adentro</Link></li>
                            <li className='section-item p-2 roboto'><Link to='/portal-de-noticias/categoria/vida-sana'>Vida Sana</Link></li>
                            <li className='section-item p-2 roboto'><Link to='/portal-de-noticias/categoria/real-food'>Real Food</Link></li>
                            <li className='section-item p-2 roboto'><Link to='/portal-de-noticias/categoria/el-nudo'>El Nudo</Link></li>
                            <li className='section-item p-2 roboto'><Link to='/portal-de-noticias/categoria/tecnofisp'>TecnoFISP</Link></li>
                            <li className='section-item p-2 roboto'><Link to='/portal-de-noticias/categoria/en-foco'>En Foco</Link></li>
                            <li className='section-item p-2 roboto'><Link to='/portal-de-noticias/categoria/eso-que-no-sabias'>Eso que no sabías</Link></li>
                            <div className="socials-mobile h-100">
                                <ul className='d-flex align-items-center list-unstyled gap-3 mb-0'>
                                    <li>
                                        <a href="https://www.youtube.com/channel/UC2beEC4cxsAIzpcYtyryb-Q" target='_blank'>
                                            <svg width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                <path d="M3 5m0 4a4 4 0 0 1 4 -4h10a4 4 0 0 1 4 4v6a4 4 0 0 1 -4 4h-10a4 4 0 0 1 -4 -4z" />
                                                <path d="M10 9l5 3l-5 3z" />
                                            </svg>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/fispsalud/" target='_blank'>
                                            <svg width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                <path d="M4 4m0 4a4 4 0 0 1 4 -4h8a4 4 0 0 1 4 4v8a4 4 0 0 1 -4 4h-8a4 4 0 0 1 -4 -4z" />
                                                <path d="M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                                                <path d="M16.5 7.5l0 .01" />
                                            </svg>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.facebook.com/fispsalud" target='_blank'>
                                            <svg width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3" />
                                            </svg>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/company/fisp-org/" target='_blank'>
                                            <svg width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                <path d="M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z" />
                                                <path d="M8 11l0 5" />
                                                <path d="M8 8l0 .01" />
                                                <path d="M12 16l0 -5" />
                                                <path d="M16 16v-3a2 2 0 0 0 -4 0" />
                                            </svg>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.tiktok.com/@fispsalud" target='_blank'>
                                            <svg width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M9 12a4 4 0 1 0 4 4v-12a5 5 0 0 0 5 5"></path>
                                            </svg>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://open.spotify.com/show/5VlWWkUHnHON4PIodMQw79?si=mKHoxjCURxW336L2W-R1CA&nd=1" target='_blank'>
                                            <svg width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                                                <path d="M8 11.973c2.5 -1.473 5.5 -.973 7.5 .527" />
                                                <path d="M9 15c1.5 -1 4 -1 5 .5" />
                                                <path d="M7 9c2 -1 6 -2 10 .5" />
                                            </svg>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default NavbarPortal;