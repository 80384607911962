import React from 'react'

const TemarioNutricion = () => {
        return (
            <div className='temario-container border w-100 p-5'>
                <b>Actualidad en Nutrición</b>
                    <br /><br />
                    <span className='fw-light'>1.1. Bases moleculares de la nutrición</span><br />
                    <span className='fw-light'>1.2. Actualización en la composición de alimentos</span><br />
                    <span className='fw-light'>1.3. Tablas de composición de alimentos </span><br />

                    <span className='fw-light'>1.4. Fitoquímicos y compuestos no nutritivos</span><br />
                    <span className='fw-light'>1.5. Nuevos alimentos</span><br />

                    <span className='fw-light'>1.5.1. Nutrientes funcionales y compuestos bioactivos</span><br />
                    <span className='fw-light'>1.5.2. Probióticos y Prebióticos</span><br />
                    <span className='fw-light'>1.8. El agua y la correcta hidratación</span><br />

                    <span className='fw-light'>1.10. Nuevo etiquetado alimentario en Argentina </span><br />

                    &nbsp;
                    <br /><br />
                    <b>Tendencias actuales en nutrición</b>
                    <br /><br />
                    &nbsp;

                    <span className='fw-light'>2.1. Nutrigenética y Nutrigenómica</span><br />

                    <span className='fw-light'>2.2. Inmunonutrición. Interacciones nutrición-sistema inmune.</span><br />
                    <span className='fw-light'>2.3. Antioxidantes naturales.</span><br />

                    <span className='fw-light'>2.4. Regulación fisiológica de la alimentación. Apetito y saciedad</span><br />
                    <span className='fw-light'>2.5. Sistema circadiano. El horario de la comida es la clave.</span><br />
                    <br /><br />
                    <b>Valoración del estado nutricional.</b>
                    <br /><br />
                    <span className='fw-light'>3.1. Bioenergética y nutrición</span><br />

                    <span className='fw-light'>3.1.1. Necesidades energéticas</span><br />
                    <span className='fw-light'>3.1.2. Gasto energético diario</span><br />

                    <span className='fw-light'>3.2. Valoración del estado nutricional</span><br />

                    <span className='fw-light'>3.2.1. Análisis de la composición corporal por antropometría</span><br />
                    <span className='fw-light'>3.2.2. Nuevos métodos de evaluación. Análisis InBody.</span><br />

                    <span className='fw-light'>3.4. Actualización en requerimientos nutricionales e ingestas recomendadas</span><br />
                    <span className='fw-light'>3.5. Alimentación en el adulto sano. Dietas de moda. </span><br />
                    <span className='fw-light'>3.6. Alimentación en la menopausia</span><br />
                    <span className='fw-light'>3.7. Nutrición en el adulto mayor</span><br />
                    <br /><br />
                    <b>Alimentación en la práctica deportiva</b>
                    <br /><br />
                    <span className='fw-light'>4.1. Fisiología del ejercicio</span><br />

                    <span className='fw-light'>4.2. Adaptación metabólica</span><br />

                    <span className='fw-light'>4.3. Indicación de Macronutrientes y Micronutrientes</span><br />

                    <span className='fw-light'>4.4. Composición corporal: masa grasa vs masa muscular </span><br />

                    <span className='fw-light'>4.5. Suplementos deportivos. </span><br />

                    <span className='fw-light'>4.6. Hidratación en el deporte: Regulación y necesidades. Tipos de bebidas</span><br />
                    <br /><br />
                    <b>Nutrición hospitalaria</b>
                    <br /><br />
                    <span className='fw-light'>5.1. La alimentación en el ámbito hospitalario</span><br />
                    <span className='fw-light'>5.2. Seguridad alimentaria en los hospitales</span><br />
                    <span className='fw-light'>5.3. Planificación y gestión de las dietas hospitalarias. </span><br />

                    <span className='fw-light'>5.4. Dietas basales hospitalarias vía oral</span><br />

                    <span className='fw-light'>5.4.1. Dieta basal del adulto</span><br />
                    <span className='fw-light'>5.4.2. Dieta basal pediátrica</span><br />

                    <span className='fw-light'>5.5. Interacción bidireccional fármaco-nutrientes</span><br />

                    <span className='fw-light'>5.6. Nutrición artificial en el adulto</span><br />

                    <span className='fw-light'>5.6.1 Nutrición enteral</span><br />

                    <span className='fw-light'>5.6.2 Nutrición parenteral</span><br />
                    <br /><br />
                    <b>Nutrición en patologías del aparato digestivo</b>
                    <br /><br />
                    <span className='fw-light'>6.1. Nutrición en alteraciones orales</span><br />

                    <span className='fw-light'>6.1.1. Xerostomía</span><br />
                    <span className='fw-light'>6.1.2. Halitosis</span><br />

                    <span className='fw-light'>6.1.3. Mucositis oral</span><br />

                    <span className='fw-light'>6.2. Nutrición en alteraciones esófago-gástricas</span><br />

                    <span className='fw-light'>6.2.1. Reflujo gastroesofágico</span><br />
                    <span className='fw-light'>6.2.2. Úlceras gástricas</span><br />
                    <span className='fw-light'>6.2.3. Disfagias</span><br />

                    <span className='fw-light'>6.3. Nutrición en Síndromes postquirúrgicos</span><br />

                    <span className='fw-light'>6.3.1. Cirugía gástrica</span><br />
                    <span className='fw-light'>6.3.2. Intestino corto</span><br />

                    <span className='fw-light'>6.4. Nutrición en los trastornos de la función intestinal</span><br />

                    <span className='fw-light'>6.4.1. Estreñimiento</span><br />
                    <span className='fw-light'>6.4.2. Diarrea</span><br />

                    <span className='fw-light'>6.5. Nutrición en Síndromes de malabsorción</span><br />
                    <span className='fw-light'>6.6. Nutrición en patología colónica</span><br />

                    <span className='fw-light'>6.6.1. Colon irritable</span><br />
                    <span className='fw-light'>6.6.2. Diverticulosis</span><br />

                    <span className='fw-light'>6.7. Nutrición en Enfermedad inflamatoria intestinal (EII)</span><br />
                    <span className='fw-light'>6.8. Alergias e intolerancias alimentarias más frecuentes de repercusión gastrointestinal</span><br />
                    <span className='fw-light'>6.9. Nutrición en Hepatopatías</span><br />

                    <span className='fw-light'>6.9.1. Hipertensión portal</span><br />
                    <span className='fw-light'>6.9.2. Encefalopatía hepática</span><br />

                    <span className='fw-light'>6.9.3 Enfermedad hepática grasa no alcohólica (EHNA)</span><br />
                    <span className='fw-light'>6.9.4. Trasplante hepático</span><br />

                    <span className='fw-light'>6.10. Nutrición en patología biliar. Litiasis biliar</span><br />
                    <span className='fw-light'>6.11. Nutrición en Pancreatopatías</span><br />

                    <span className='fw-light'>6.11.1. Pancreatitis aguda</span><br />
                    <span className='fw-light'>6.11.2. Pancreatitis crónica</span><br />
                    <br /><br />
                    <b>Nutrición en enfermedades endocrino-metabólicas</b>
                    <br /><br />
                    <span className='fw-light'>7.1. Dislipidemia y arteriosclerosis</span><br />

                    <span className='fw-light'>7.1.1. Etiología. </span><br />
                    <span className='fw-light'>7.1.2. Fisiopatología de las dislipemias</span><br />
                    <span className='fw-light'>7.1.3. Diagnóstico de la enfermedad y sus comorbilidades</span><br />
                    <span className='fw-light'>7.1.4. Bases del tratamiento </span><br />
                    <span className='fw-light'>7.1.5. Tratamiento dietético. </span><br />
                    <span className='fw-light'>7.1.6. Tratamiento farmacológico.</span><br />
                    <span className='fw-light'>7.2. Diabetes Mellitus</span><br />

                    <span className='fw-light'>7.2.1. Etiología. </span><br />
                    <span className='fw-light'>7.2.2. Fisiopatología de la diabetes.</span><br />
                    <span className='fw-light'>7.2.3. Diagnóstico de la enfermedad y sus comorbilidades</span><br />
                    <span className='fw-light'>7.2.4. Bases del tratamiento en el paciente insulinodependiente </span><br />

                    <span className='fw-light'>7.2.5. Bases del tratamiento en el paciente con Diabetes tipo 2</span><br />
                    <span className='fw-light'>7.2.6. Tratamiento dietético. </span><br />
                    <span className='fw-light'>7.2.7. Tratamiento farmacológico.</span><br />
                    <span className='fw-light'>7.3. Hipertensión y enfermedad cardiovascular</span><br />

                    <span className='fw-light'>7.3.1. Etiología. </span><br />
                    <span className='fw-light'>7.3.2. Fisiopatología de la hipertensión arterial</span><br />
                    <span className='fw-light'>7.3.3. Diagnóstico de la enfermedad y sus comorbilidades</span><br />
                    <span className='fw-light'>7.3.4. Bases del tratamiento.</span><br />
                    <span className='fw-light'>7.3.5. Tratamiento dietético. </span><br />
                    <span className='fw-light'>7.3.6. Tratamiento farmacológico.</span><br />
                    <span className='fw-light'>7.4. Obesidad</span><br />

                    <span className='fw-light'>7.4.1. Etiología. </span><br />
                    <span className='fw-light'>7.4.2. Fisiopatología de la obesidad</span><br />
                    <span className='fw-light'>7.4.3. Diagnóstico de la enfermedad </span><br />
                    <span className='fw-light'>7.4.4. Bases del tratamiento de la obesidad y sus comorbilidades</span><br />
                    <span className='fw-light'>7.4.5. Tratamiento dietético.</span><br />
                    <span className='fw-light'>7.4.6. Tratamiento farmacológico actualizado</span><br />

                    <span className='fw-light'>7.4.8. Tratamiento quirúrgico</span><br />

                    <span className='fw-light'>7.4.8.1. Indicaciones</span><br />
                    <span className='fw-light'>7.4.8.2. Técnicas</span><br />
                    <span className='fw-light'>7.4.8.3. Complicaciones</span><br />
                    <span className='fw-light'>7.4.8.4. Manejo dietético post cirugía</span><br />

                    <span className='fw-light'>7.4.10. Actividad física en la obesidad como parte del tratamiento</span><br />

                    <span className='fw-light'>7.4.10.1. Correcta indicación</span><br />

                    <span className='fw-light'>7.4.10.2. Prevenir mediante el movimiento</span><br />

                    &nbsp;
                    <br /><br />
                    <b>Nutrición en enfermedades renales</b>
                    <br /><br />
                    &nbsp;

                    <span className='fw-light'>8.1. Insuficiencia renal crónica (IRC) en prediálisis</span><br />
                    <span className='fw-light'>8.2. Insuficiencia renal crónica (IRC) en diálisis</span><br />
                    <span className='fw-light'>8.3. Gota e hiperuricemia</span><br />
                    <br /><br />
                    <b>Nutrición en enfermedades neurológicas</b>
                    <br /><br />
                    &nbsp;

                    <span className='fw-light'>9.1. Enfermedad de Parkinson</span><br />
                    <span className='fw-light'>9.2. Enfermedad de Alzheimer</span><br />
                    <br /><br />
                    <b>Nutrición en situaciones especiales</b><span className='fw-light'> </span><br />
                    <br /><br />
                    <span className="fw-light">Nutrición en situaciones de estrés metabólico. Sepsis. Politraumatismo. Quemados. Nutrición en el paciente oncológico. Síndrome de Inmunodeficiencia Adquirida.</span>
                    <br /><br />
                    <span className='fw-light'>10.1. Nutrición en situaciones de estrés metabólico</span><br />

                    <span className='fw-light'>10.1.1. Sepsis</span><br />
                    <span className='fw-light'>10.1.2. Politraumatismo</span><br />
                    <span className='fw-light'>10.1.3. Quemados</span><br />

                    <span className='fw-light'>10.2. Nutrición en el paciente oncológico</span><br />

                    <span className='fw-light'>10.3.1. Síndrome de Inmunodeficiencia Adquirida</span><br />
                    <br /><br />
                    <b>Nutrición en enfermedades carenciales</b>
                    <br /><br />
                    <span className='fw-light'>11.1. Malnutrición</span><br />

                    <span className='fw-light'>11.1.1. Malnutrición hospitalaria</span><br />
                    <span className='fw-light'>11.1.2. El ciclo de ayuno y realimentación</span><br />

                    <span className='fw-light'>11.2. Osteopenia y Osteoporosis </span><br />
                    <span className='fw-light'>11.3. Déficits de vitaminas</span><br />
                    <span className='fw-light'>11.4. Anemia. Hemocromatosis</span><br />
                    <br /><br />
                    <b>Nutrición para la prevención del envejecimiento</b>
                    <br /><br />
                    &nbsp;

                    <span className='fw-light'>12.1. Definición de envejecimiento</span><br />

                    <span className='fw-light'>12.2. ¿Podemos considerar al envejecimiento una enfermedad?</span><br />

                    <span className='fw-light'>12.3. Terapéutica nutricional hoy y a futuro.</span><br />
            </div>
        );
}

export default TemarioNutricion