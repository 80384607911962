import React, { useContext, useEffect, useState } from 'react'
import Axios from "axios";
import { context } from '../Context/Context';
import FISP from '../multimedia/imagenes/LOGO.png'
import { Link } from 'react-router-dom';
import {Adsense} from '@ctrl/react-adsense';
const SideArticles = () => {
    const { lastArticles } = useContext(context);
    const [apartado,setApartado] = useState([])

    const obtenerApartado = ()=>{
        Axios.get("https://srv452353.hstgr.cloud/obtenerapartado/")
        .then((response) => {
            console.log("Apartado recibido con éxito")
            setApartado(Array.from(response.data))
        })
        .catch((error) => {
            console.log("Error al traer el apartado")
            console.error(error);
        })
    }

    const formatText = (text) => {
        return text.replace(/-/g, ' ');
    }

    useEffect(()=>{obtenerApartado()},[])

    return (
        <div className="side-articles px-0 mx-0 pe-3">
            <div className="side-articles-container d-flex flex-column gap-4">
                {apartado.map((item,index)=>{
                    return(
                        <Link to={`/portal-de-noticias/categoria/${item.categoria}`} key={index}>
                            <div className={`side-article-item col-lg-12 rounded-4 pt-4 pb-2 ${item.categoria}`}>
                                <div className="px-4">
                                    <img className='w-100 h-100' src={item.imagen} alt={item.categoria} />
                                    <h4 className={`${item.categoria=="vida-sana" ? 'text-dark' : 'text-light'} text-light fs-4 roboto mt-3 py-0 uppercase`}>{formatText(item.categoria)}</h4>
                                    <p className={`${item.categoria=="vida-sana" ? 'text-dark' : 'text-light'} text-light fs-5 text-balanced lh-1 roboto`}>{item.descripcion}</p>
                                </div>
                            </div>
                        </Link>
                    )
                })}
                <div className="ad-container">
                    <Adsense
                        className='w-100'
                        client="ca-pub-8715176583976119"
                        slot="3022124200"
                        style={{ display: 'block' }}
                        layout="in-article"
                        responsive='true'
                        format="auto"
                    />
                </div>
                <a href="/educativo">
                    <div className="vertical-logo-banner w-100 rounded-4 toggle-hid">
                        <img className='w-100' src={FISP} alt="FISP Banner" />
                        <p className='roboto fw-ultrabold fs-3 text-balanced text-center'>¡Mira nuestra oferta educativa!</p>
                    </div>
                </a>
            </div>
        </div>
    )
}

export default SideArticles;