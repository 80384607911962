import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import transicionEscalera from './Transiciones/transicionEscalera'
import transicionBottomTop from './Transiciones/transicionBottomTop'
import NavbarPortal from './Navbars/NavbarPortal'
import SideArticles from './Portal/SideArticles'
import FooterPortal from './Portal/FooterPortal'
import bannerEducativo from './multimedia/imagenes/portal/banner_educativo.jpg'
import bannerServicios from './multimedia/imagenes/portal/banner_servicios.jpg'
import bannerYoutube from './multimedia/imagenes/portal/banner_youtube.jpg'
import bannerEducativoCelular from './multimedia/imagenes/portal/banner_educativo_celular.jpg'
import bannerServiciosCelular from './multimedia/imagenes/portal/banner_servicios_celular.jpg'
import bannerYoutubeCelular from './multimedia/imagenes/portal/banner_youtube_celular.jpg'
import {Adsense} from '@ctrl/react-adsense';
import Axios from "axios";

const PortalV2 = () => {

    const isMountedRef = useRef(false);

    const skeletonObject = {
        titulo: "Cargando titulo...",
        url: "skeleton-url",
        descripcion: "Cargando descripcion...",
        imagen: "skeleton-img",
    }

    // const videoEmbed = "https://www.youtube.com/embed/cb12KmMMDJA"
    // const video = "https://www.youtube.com/watch?v=QCvwEBFYNoU"

    const [noticiasHome,setNoticiasHome] = useState([])
    const [reelsHome,setReelsHome] = useState([])
    const [primerNota,setPrimerNota] = useState(skeletonObject)
    const [segundaNota,setSegundaNota] = useState(skeletonObject)
    const [tercerNota,setTercerNota] = useState(skeletonObject)
    const [cuartaNota,setCuartaNota] = useState(skeletonObject)
    const [quintaNota,setQuintaNota] = useState(skeletonObject)
    const [sextaNota,setSextaNota] = useState(skeletonObject)
    const [tiempoLocal,setTiempoLocal] = useState("")
    const [stream,setStream] = useState("")
    
    const obtenerNotas = ()=>{
        Axios.get("https://srv452353.hstgr.cloud/obtenerhome/")
        .then((response) => {
            console.log("Noticias del home recibidas con éxito")
            setNoticiasHome(Array.from(response.data))
            ordenarNotasHome(Array.from(response.data))
        })
        .catch((error) => {
            console.log("Error al traer las noticias del home")
            console.error(error);
        })
    }
    const obtenerReels = ()=>{
        Axios.get("https://srv452353.hstgr.cloud/obtenerreels/")
        .then((response) => {
            console.log("Reels del home recibidas con éxito")
            setReelsHome(Array.from(response.data))
        })
        .catch((error) => {
            console.log("Error al traer los reels del home")
            console.error(error);
        })
    }
    const obtenerStream = ()=>{
        Axios.get("https://srv452353.hstgr.cloud/obtenerstream/")
        .then((response) => {
            console.log("Stream recibido con éxito")
            setStream(Array.from(response.data)) 
        })
        .catch((error) => {
            console.log("Error al traer el stream del home")
            console.error(error);
        })
    }
    const obtenerTiempo = ()=>{
        const fecha = new Date()
        const horas = fecha.getHours()
        const minutos = fecha.getMinutes()
        const concat = `${horas}${minutos}`
        setTiempoLocal(concat)
    }
    const ordenarNotasHome = (noticiasArray)=>{
        setPrimerNota(noticiasArray.find(nota=>nota.presente_home == 1 && nota.orden_home == 1))
        setSegundaNota(noticiasArray.find(nota=>nota.presente_home == 1 && nota.orden_home == 2))
        setTercerNota(noticiasArray.find(nota=>nota.presente_home == 1 && nota.orden_home == 3))
        setCuartaNota(noticiasArray.find(nota=>nota.presente_home == 1 && nota.orden_home == 4))
        setQuintaNota(noticiasArray.find(nota=>nota.presente_home == 1 && nota.orden_home == 5))
        setSextaNota(noticiasArray.find(nota=>nota.presente_home == 1 && nota.orden_home == 6))
    }

    useEffect(()=>{
        if (!isMountedRef.current) {
            window.scroll(0, 0);
            isMountedRef.current = true;
        }
        obtenerNotas()
        obtenerReels()
        obtenerStream()
        obtenerTiempo()
    },[])

    return (
        <>
            <main className='multimedia-home-container' id='main-element'>
                <div className="container-xxl px-0 d-flex">
                    <NavbarPortal />
                    <div className="articles-body-container d-flex w-100">
                        {
                            noticiasHome.length>0 ? (
                                <div className='main-articles-container px-3'>
                                    <div className="main-articles-section d-flex flex-column px-0 mx-0">
                                        {window.innerWidth >= 600 ? (<Link className='banner w-100' to="/educativo"><img className='banner-educativo w-100 mb-4 rounded-4' src={bannerEducativo} alt="Banner educativo Fisp" /></Link>) : (<Link className='banner w-100' to="/educativo"><img className='w-100 mb-4 rounded-4 banner-educativo' src={bannerEducativoCelular} alt="Banner educativo celular Fisp" /></Link>)}
                                        <div className="mixed-articles-container d-flex px-0 mx-0">
                                            <div className="col-lg-7 col-12 h-100">
                                                <div className="d-flex flex-column">
                                                    <div className="main-article d-flex flex-column justify-content-center focus-article pe-0 pe-md-4">
                                                        <h1 className='fw-bold mt-4 pt-sm-5 pt-1 roboto'><Link className='fw-bold roboto' to={primerNota.url}>{primerNota.titulo}</Link></h1>
                                                        <h2 className='roboto mt-3 fs-4'>{primerNota.descripcion}</h2>
                                                        <Link to={primerNota.url}><img className='w-100 rounded-4 w-100 mt-4' src={primerNota.imagen} alt={primerNota.titulo} /></Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-5 col-12 px-2 h-100" style={{ margin: "0 auto" }}>
                                                <div className="d-flex flex-column gap-3">
                                                    {
                                                        tiempoLocal>=1900 && tiempoLocal<2100 ? (
                                                            <div className="home-stream-container px-lg-3 px-0">
                                                                <h3 className='fs-4 mb-3'><a className='fw-bold roboto fs-4' href={`https://www.youtube.com/watch?v=${stream[0].stream_embed_id}`} target='_blank' rel='no-opener'>Mirá nuestra transmisión en vivo</a></h3>
                                                                <iframe width="560" height="349" src={`https://www.youtube.com/embed/${stream[0].stream_embed_id}`} frameborder="0" allowfullscreen></iframe>
                                                            </div>
                                                        ) : (
                                                            <div className="home-article d-flex flex-column px-lg-3 px-0">
                                                                <h3 className='fs-4'><Link className='fw-bold roboto fs-4'to={segundaNota.url}>{segundaNota.titulo}</Link></h3>
                                                                <h4 className='fs-5 fw-normal roboto line-clamp-3'>{segundaNota.descripcion}</h4>
                                                                <Link to={segundaNota.url}><img className='w-100 w-100 ratio-16x9 object-fit-cover rounded-4 mt-2' src={segundaNota.imagen} alt={segundaNota.titulo} /></Link>
                                                            </div>
                                                        )
                                                    }
                                                    <div className="home-article d-flex flex-column px-lg-3 px-0">
                                                        <h3 className='fs-4'><Link className='fw-bold roboto fs-4'to={tercerNota.url}>{tercerNota.titulo}</Link></h3>
                                                        <h4 className='fs-5 fw-normal roboto'>{tercerNota.descripcion}</h4>
                                                        <Link to={tercerNota.url}><img className='w-100 w-100 ratio-16x9 object-fit-cover rounded-4 mt-2' src={tercerNota.imagen} alt={tercerNota.titulo} /></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ad-container mt-5">
                                        {/* {window.innerWidth >= 500 ? () : ()} */}
                                        {window.innerWidth >= 600 ? (<Link className='banner w-100' to="/servicios"><img className='banner-servicios w-100 mb-4 rounded-4' src={bannerServicios} alt="Banner educativo Fisp" /></Link>) : (<Link className='banner w-100' to="/servicios"><img className='banner-servicios w-100 mb-4 rounded-4' src={bannerServiciosCelular} alt="Banner educativo Fisp" /></Link>)}
                                        <Adsense
                                            className='w-100'
                                            client="ca-pub-8715176583976119"
                                            slot="3087387590"
                                            style={{ display: 'block' }}
                                            layout="in-article"
                                            format="fluid"
                                        />
                                    </div>
                                    <div className="articles-section d-flex">
                                        <div className="col-lg-4 col-12 mt-4 mt-md-5 pe-md-2 px-2 focus-article">
                                            <div className="regular-article border-0 d-flex flex-column mt-lg-5 mt-0">
                                                <h3 className='fs-4'><Link className='fw-bold roboto fs-4' to={cuartaNota.url}>{cuartaNota.titulo}</Link></h3>
                                                <p className='line-clamp-3 roboto'>{cuartaNota.descripcion}</p>
                                                <Link to={cuartaNota.url}><img className='w-100 rounded-4' src={cuartaNota.imagen} alt={cuartaNota.titulo} /></Link>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-12 mt-4 mt-md-5 px-2">
                                            <div className="regular-article d-flex flex-column mt-lg-5 mt-0">
                                                <h3 className='fs-4'><Link className='fw-bold roboto fs-4' to={quintaNota.url}>{quintaNota.titulo}</Link></h3>
                                                <p className='line-clamp-3 roboto'>{quintaNota.descripcion}</p>
                                                <Link to={quintaNota.url}><img className='w-100 rounded-4' src={quintaNota.imagen} alt={quintaNota.titulo} /></Link>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-12 mt-4 mt-md-5 px-2">
                                            <div className="regular-article d-flex flex-column mt-lg-5 mt-0">
                                                <h3 className='fs-4'><Link className='fw-bold roboto fs-4' to={sextaNota.url}>{sextaNota.titulo}</Link></h3>
                                                <p className='line-clamp-3 roboto'>{sextaNota.descripcion}</p>
                                                <Link to={sextaNota.url}><img className='w-100 rounded-4' src={sextaNota.imagen} alt={sextaNota.titulo} /></Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ad-container mt-5">
                                        {window.innerWidth >= 600 ? (<a className='banner w-100' href="https://www.youtube.com/@FISPTV" target='_blank'><img className='banner-youtube w-100 mb-4 rounded-4' src={bannerYoutube} alt="Banner educativo Fisp" /></a>) : (<a className='banner w-100' href="https://www.youtube.com/@FISPTV" target='_blank'><img className='banner-youtube w-100 mb-4 rounded-4' src={bannerYoutubeCelular} alt="Banner Youtube Fisp" /></a>) }
                                        <Adsense
                                            className='w-100'
                                            client="ca-pub-8715176583976119"
                                            slot="3769736262"
                                            style={{ display: 'block' }}
                                            layout="in-article"
                                            format="fluid"
                                        />
                                    </div>
                                    <div className="d-flex flex-column gap-3">
                                        <div className="mt-5 mb-4 border"></div>
                                        <div className="d-flex reels-section justify-content-evenly overflow-hidden">
                                            {reelsHome.map((reel,index)=>{
                                                return(
                                                    <iframe key={index} className='rounded-4 reel' src={reel.link} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="main-articles-container rounded-4 position-relative border border-primary d-flex justify-content-center mx-3" style={{'--bs-border-opacity': '.1'}}>
                                    <div className="skeleton-loader"></div>
                                </div>
                            )
                        }
                        <SideArticles />
                    </div>
                </div>
            </main>
            <FooterPortal />
        </>
    )
}

export default window.innerWidth >= 1000 ? transicionEscalera(PortalV2) : transicionBottomTop(PortalV2);
