import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import RegularNavbar from '../../Navbars/RegularNavbar';
import transicionEscalera from '../../Transiciones/transicionEscalera';
import transicionBottomTop from '../../Transiciones/transicionBottomTop';

import Vid from '../../multimedia/videos/servicios-tecnologicos/bg-tecnologico.mp4'
import Frame1 from './iconos/Frame 1.png'
import Frame2 from './iconos/Frame 2.png'
import Frame3 from './iconos/Frame 3.png'
import Frame4 from './iconos/Frame 4.png'
import Frame5 from './iconos/Frame 5.png'
import Frame6 from './iconos/Frame 6.png'
import Frame7 from './iconos/Frame 7.png'
import Frame8 from './iconos/Frame 8.png'

const services = [
    { icon: Frame1, text: 'Plataforma para Sistemas de Salud', path: "/servicios/servicios-tecnologicos/plataforma-para-sistemas-de-salud"},
    { icon: Frame2, text: 'Telemedicina de Urgencias', path: "/servicios/servicios-tecnologicos/telemedicina-de-urgencias"},
    { icon: Frame3, text: 'Firma Digital', path: "/servicios/servicios-tecnologicos/firma-digital"},
    { icon: Frame4, text: 'Software de Diagnóstico por Imágenes', path: "/servicios/servicios-tecnologicos/software-de-diagnostico-por-imagenes"},
    { icon: Frame5, text: 'Consultoría Tecnológica', path: "/servicios/servicios-tecnologicos/consultoria-tecnologica"},
    { icon: Frame7, text: 'Salud en Ciudades Inteligentes', path: "/servicios/servicios-tecnologicos/salud-en-ciudades-inteligentes"},
    { icon: Frame6, text: 'Interoperabilidad X Road', path: "/servicios/servicios-tecnologicos/interoperabilidad-x-road"},
    { icon: Frame8, text: 'Sistemas de ERP O Sociales', path: "/servicios/servicios-tecnologicos/sistemas-de-erp-o-sociales"},
];

const ServiciosTecnolGicosV3 = () => {
    
    const [isNavHidden, setIsNavHidden] = useState(true);
    const [prevScrollPos, setPrevScrollPos] = useState(100);
    const [loaded, setLoaded] = useState(false);
    
    useEffect(() => {
        
        const handleScroll = () => {
            let currentScrollPos = 0
            currentScrollPos = window.pageYOffset;
            if (prevScrollPos < currentScrollPos) {
                console.log(currentScrollPos);
                if (isNavHidden) {
                    navBar.style.transform = 'translateY(0)';
                    setIsNavHidden(false);
                }
            } else {
                if (!isNavHidden && window.innerWidth >= 600) {
                    navBar.style.transform = 'translateY(110%)';
                    setIsNavHidden(true);
                }
            }
            setPrevScrollPos(currentScrollPos);
        };

        const navBar = document.querySelector('nav');
        if (!loaded) {
            window.scroll(0, 0);
            navBar.style.transform = 'translateY(110%)'
            setLoaded(true);
        }  
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isNavHidden, prevScrollPos]);

    return (
        <div id="serv-t">
            <div className="d-flex flex-column">
                <div className="w-100">
                    <div id="serv-t-video-container" className="intro d-flex justify-content-center w-100">
                        <video id='serv-t-video' className="w-100" autoPlay muted loop playsInline>
                            <source src={Vid} type='video/mp4' />
                        </video>
                    </div>
                    <div id="serv-t-title-container" className="d-flex flex-column align-items-center justify-content-center w-100">
                        <div id="serv-t-title-scaling" className="d-flex flex-column align-items-center justify-content-center">
                            <h1 id="serv-t-title" className='text-light d-flex flex-column'>
                                <span id="serv-t-title-1" className="poppins-bold m-0">SERVICIOS</span>
                                <span id="serv-t-title-2" className="poppins-bold m-0">TECNOLOGICOS</span>
                                <span id="serv-t-title-3" className="poppins-bold m-0">MEDICOS</span>
                            </h1>
                            <h5 id="serv-t-subtitle" className='poppins-light text-light mt-3'>En el ámbito de la salud, la tecnología juega un papel fundamental en la mejora de la atención médica y la optimización de los procesos clínicos</h5>
                        </div>
                    </div>
                    <div id="serv-t-title-drop" className="w-100"></div>
                </div>
                <div id="serv-t-section-1" className="d-flex justify-content-center">
                    <div id="serv-t-grid" className="d-flex justify-content-center mb-3">
                        {services.map((service, index) => (
                            <div key={index} className="serv-t-grid-item d-flex flex-column align-items-center">
                                <Link to={service.path}><img src={service.icon} alt="" /></Link>
                                <Link to={service.path}><h5 className="mb-0 mt-3 pt-1 text-center text-light poppins-light text-decoration-none" dangerouslySetInnerHTML={{ __html: service.text }}></h5></Link>
                            </div>
                        ))}
                    </div>
                </div>
                <div id="serv-t-section-2" className="d-flex justify-content-center flex-column w-100">
                    <div id="serv-t-section-2-glass" className="d-flex justify-content-center flex-column h-100 w-100">
                        <div id="serv-t-text-container" className="d-flex flex-column">
                            <p className="poppins-light fs-5 text-light m-0">En FISP, creemos firmemente que la implementación de tecnologías de salud es un paso esencial hacia un sistema de atención médica más sostenible y centrado en el paciente.</p>
                            <p className="poppins-light fs-5 text-light m-0">Hemos asumido el compromiso de ayudar a las administraciones a avanzar hacia un futuro de atención médica más eficiente, transparente y centrada en el paciente, aprovechando las últimas innovaciones tecnológicas.</p>
                            <p className="poppins-light fs-5 text-light m-0">Con una sólida trayectoria en la implementación exitosa de soluciones de tecnología de la salud en todo el mundo. Nuestra experiencia abarca desde la gestión de registros médicos electrónicos hasta la telemedicina y la optimización de procesos hospitalarios.</p>
                            <p className="poppins-light fs-5 text-light m-0">Conocemos los desafios únicos que enfrentan las instituciones gubernamentales de salud y estamos listos para abordarlos de manera eficaz.</p>
                        </div>
                    </div>
                </div>
                <nav id="nav-ivo" className="d-flex align-items-center justify-content-end w-100">
                    <div id="nav-ivo-container" className="d-flex align-items-center justify-content-end w-100">
                        <RegularNavbar />
                    </div>
                </nav>
            </div>
        </div>
    );
}

export default window.innerWidth >= 1000 ? transicionEscalera(ServiciosTecnolGicosV3) : transicionBottomTop(ServiciosTecnolGicosV3);
