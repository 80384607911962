import React from 'react'

const TemarioEnfermeriaConsumosProblematicos = () => {
    return (
        <div className='temario-container border w-100 p-5'>
            <span>Unidad 1: Conceptos Básicos y Contexto del Consumo Problemático de Sustancias</span><br /><br />
            <span>Introducción al Consumo Problemático de Sustancias</span>
            <ul>
                <li className="fw-light">Definición y clasificación de sustancias psicoactivas, diferencias entre uso, abuso y dependencia, impacto social y sanitario del consumo problemático</li>
                <li className="fw-light">Teoría aplicada: Modelo de Adaptación de Roy</li>
                <li className="fw-light">Intervenciones de enfermería: Valoración inicial del paciente, identificación de patrones de uso de sustancias, y elaboración de un plan de cuidados basado en la teoría de adaptación</li>
            </ul>

            <span>Epidemiología del Consumo Problemático</span>
            <ul>
                <li className="fw-light">Estadísticas globales y latinoamericanas, factores de riesgo y protección, poblaciones vulnerables</li>
                <li className="fw-light">Teoría aplicada: Teoría de la Promoción de la Salud de Pender</li>
                <li className="fw-light">Intervenciones de enfermería: Identificación de factores de riesgo, educación comunitaria sobre prevención, y desarrollo de programas de promoción de la salud</li>
            </ul>

            <span>Aspectos Psicosociales del Consumo Problemático</span>
            <ul>
                <li className="fw-light">Factores psicosociales y ambientales que influyen en el consumo, estigma y discriminación, estrategias de intervención social</li>
                <li className="fw-light">Teoría aplicada: Teoría del Autocuidado de Orem</li>
                <li className="fw-light">Intervenciones de enfermería: Fomentar el autocuidado y trabajar en la reducción del estigma mediante la educación y el apoyo psicológico</li>
            </ul>

            <span>Fundamentos de la Toxicología</span>
            <ul>
                <li className="fw-light">Principios básicos de toxicología clínica, efectos agudos y crónicos de las sustancias, interpretación de análisis toxicológicos</li>
                <li className="fw-light">Teoría aplicada: Modelo de los Sistemas de Neuman</li>
                <li className="fw-light">Intervenciones de enfermería: Monitoreo y análisis toxicológico, estabilización en crisis tóxicas</li>
            </ul>

            <span>Unidad 2: Intervención y Manejo Clínico del Consumo Problemático</span><br /><br />
            <span>Mecanismos de acción de las sustancias psicoactivas</span>
            <ul>
                <li className="fw-light">Mecanismos de acción de las principales sustancias, interacciones farmacológicas, tratamientos farmacológicos en la desintoxicación</li>
                <li className="fw-light">Teoría aplicada: Teoría del Déficit de Autocuidado de Orem</li>
                <li className="fw-light">Intervenciones de enfermería: Administración de medicamentos, monitoreo de efectos secundarios, y apoyo en la adherencia al tratamiento</li>
            </ul>

            <span>Diagnóstico y Evaluación del Consumo Problemático</span>
            <ul>
                <li className="fw-light">Criterios diagnósticos (DSM-5, CIE-10), herramientas de evaluación y entrevistas clínicas, planificación del tratamiento</li>
                <li className="fw-light">Teoría aplicada: Modelo de Adaptación de Roy</li>
                <li className="fw-light">Intervenciones de enfermería: Realización de entrevistas clínicas, uso de herramientas diagnósticas, plan de cuidados individualizado</li>
            </ul>

            <span>Enfermería en la Intervención Temprana</span>
            <ul>
                <li className="fw-light">Identificación de signos y síntomas tempranos, protocolos de intervención temprana, educación y prevención en la comunidad</li>
                <li className="fw-light">Teoría aplicada: Teoría de la Transición de Meleis</li>
                <li className="fw-light">Intervenciones de enfermería: Detección precoz, intervención en la comunidad</li>
            </ul>

            <span>Estrategias Terapéuticas y Motivacionales</span>
            <ul>
                <li className="fw-light">Entrevista motivacional, terapias cognitivo-conductuales, rol de la enfermería en la adherencia al tratamiento</li>
                <li className="fw-light">Teoría aplicada: Teoría Humanista de Paterson y Zderad</li>
                <li className="fw-light">Intervenciones de enfermería: Técnicas motivacionales, acompañamiento terapéutico</li>
            </ul>

            <span>Unidad 3: Manejo de Crisis, Reducción de Daños y Abordaje Ético</span><br /><br />
            <span>Urgencias y Emergencias en el Consumo Problemático</span>
            <ul>
                <li className="fw-light">Manejo de sobredosis y abstinencia, protocolos de atención en situaciones críticas, coordinación con equipos de emergencia</li>
                <li className="fw-light">Teoría aplicada: Modelo de Sistemas de Neuman</li>
                <li className="fw-light">Intervenciones de enfermería: Atención inmediata en urgencias, estabilización, coordinación</li>
            </ul>

            <span>Intervención Familiar y Comunitaria</span>
            <ul>
                <li className="fw-light">Impacto del consumo en la familia, estrategias de intervención familiar, programas comunitarios de prevención y tratamiento</li>
                <li className="fw-light">Teoría aplicada: Teoría del Cuidado Transcultural de Leininger</li>
                <li className="fw-light">Intervenciones de enfermería: Educación familiar, redes de apoyo comunitario</li>
            </ul>

            <span>Modelos de Reducción de Daños</span>
            <ul>
                <li className="fw-light">Principios de la reducción de daños, programas de intercambio de jeringas, salas de consumo supervisado, evaluación del impacto de estas estrategias</li>
                <li className="fw-light">Teoría aplicada: Teoría de la Promoción de la Salud de Pender</li>
                <li className="fw-light">Intervenciones de enfermería: Implementación de programas de reducción de daños, educación sobre prácticas seguras</li>
            </ul>

            <span>Salud Mental y Comorbilidad en el Consumo Problemático</span>
            <ul>
                <li className="fw-light">Trastornos psiquiátricos asociados, abordaje integral de la dualidad diagnóstica, coordinación interdisciplinaria en el tratamiento</li>
                <li className="fw-light">Teoría aplicada: Teoría del Cuidado Humano de Watson</li>
                <li className="fw-light">Intervenciones de enfermería: Coordinación con otros profesionales, seguimiento de comorbilidades</li>
            </ul>

            <span>Políticas Públicas y Legislación en Consumo Problemático</span>
            <ul>
                <li className="fw-light">Marco legal latinoamericano sobre el consumo de sustancias, políticas públicas de prevención y tratamiento, rol del enfermero en la defensa de derechos y políticas sanitarias</li>
                <li className="fw-light">Teoría aplicada: Modelo de Promoción de la Salud de Pender</li>
                <li className="fw-light">Intervenciones de enfermería: Participación en políticas públicas, defensa de los derechos del paciente</li>
            </ul>

            <span>Abordaje Ético en la Enfermería y Consumo Problemático</span>
            <ul>
                <li className="fw-light">Dilemas éticos en la atención de pacientes con consumo problemático, confidencialidad y consentimiento informado, reflexión sobre la práctica profesional</li>
                <li className="fw-light">Teoría aplicada: Teoría de la Ética del Cuidado de Tronto</li>
                <li className="fw-light">Intervenciones de enfermería: Manejo ético de la información del paciente, obtención de consentimiento informado</li>
            </ul>

            <span>Proyecto Integrador de Intervención en Consumo Problemático</span>
            <ul>
                <li className="fw-light">Elaboración de un proyecto de intervención en una comunidad o institución</li>
                <li className="fw-light">Teoría aplicada: Teoría del Liderazgo Transformacional (Adaptada de Kouzes y Posner)</li>
                <li className="fw-light">Intervenciones de enfermería: Liderazgo en la planificación de proyectos, trabajo en equipo interdisciplinario</li>
            </ul>

            <span>Autocuidado para Profesionales y Cierre de la Diplomatura</span>
            <ul>
                <li className="fw-light">Estrategias de autocuidado para profesionales de enfermería en entornos de alta demanda emocional y física, como la atención a personas con consumo problemático de sustancias.</li>
                <li className="fw-light">Gestión del estrés, el agotamiento y la prevención del síndrome de burnout.</li>
                <li className="fw-light">Importancia del apoyo entre colegas y la creación de redes de autocuidado en el equipo de trabajo.</li>
                <li className="fw-light">Reflexión grupal sobre los aprendizajes adquiridos y su aplicación en la práctica diaria.</li>
                <li className="fw-light">Examen final escrito y/o práctico como cierre de la diplomatura.</li>
                <li className="fw-light">Teoría aplicada: Teoría del Cuidado Humano de Watson, enfocada en el cuidado integral del profesional de salud, reconociendo la importancia de cuidar de sí mismo para poder ofrecer una atención de calidad a los pacientes. Esta teoría refuerza la idea de que el autocuidado y el bienestar del enfermero son esenciales para una práctica profesional ética, efectiva y humana.</li>
            </ul>
        </div>
    )
}

export default TemarioEnfermeriaConsumosProblematicos
