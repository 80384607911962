import React from 'react'
import { Link } from 'react-router-dom'

const FooterEducativo = () => {
    return (
        <div className='container-fluid footer-educativo'>
            <div className="container-xxl h-100 d-flex flex-column align-items-center justify-content-center">
                <div className='footer-items-container h-50 d-flex align-items-center'>
                    <div className="col h-100 py-3 d-flex justify-content-center me-4">
                        <div className='d-flex flex-column gap-2'>
                            <h5>CONOCENOS</h5>
                            <h6><Link to="/la-fundacion">Quienes somos</Link></h6>
                            <h6><Link to="/terminos-y-condiciones">Terminos y condiciones</Link></h6>
                            <h6><Link to="/staff">Staff</Link></h6>
                        </div>
                    </div>
                    <div className="col h-100 py-3 d-flex justify-content-center">
                        <div className="d-flex flex-column gap-2">
                            <h5>CONTACTO</h5>
                            <h6>Cerrito 1050, Piso 9 CABA, Buenos Aires, C1010</h6>
                            <h6>
                                <a className='d-flex align-items-center gap-1' href="https://mail.google.com/mail/?view=cm&to=info@fisp.org.ar">
                                    <svg width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#888888" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                        <path d="M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z" />
                                        <path d="M3 7l9 6l9 -6" />
                                    </svg>
                                    info@fisp.org.ar
                                </a>
                            </h6>
                            <h6>
                                <a className='d-flex align-items-center gap-1' href="tel:+541152522597">
                                    <svg width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#888888" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                        <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
                                    </svg>
                                    +54 9 11 5252-2597
                                </a>
                            </h6>
                        </div>
                    </div>
                    <div className="col h-100 py-3 d-flex justify-content-center">
                        <div className="d-flex flex-column gap-2">
                            <h5>REDES</h5>
                            <div className="d-flex flex-column gap-1">
                                <div className="col d-flex gap-2 px-0">
                                    <a href="https://www.youtube.com/channel/UC2beEC4cxsAIzpcYtyryb-Q" target='_blank'>
                                        <svg width="32" height="32" viewBox="0 0 24 24" strokeWidth="1" stroke="#888888" fill="none">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <path d="M3 5m0 4a4 4 0 0 1 4 -4h10a4 4 0 0 1 4 4v6a4 4 0 0 1 -4 4h-10a4 4 0 0 1 -4 -4z" />
                                            <path d="M10 9l5 3l-5 3z" />
                                        </svg>
                                    </a>
                                    <a href="https://www.instagram.com/fispsalud/" target='_blank'>
                                        <svg width="32" height="32" viewBox="0 0 24 24" strokeWidth="1" stroke="#888888" fill="none">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <path d="M4 4m0 4a4 4 0 0 1 4 -4h8a4 4 0 0 1 4 4v8a4 4 0 0 1 -4 4h-8a4 4 0 0 1 -4 -4z" />
                                            <path d="M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                                            <path d="M16.5 7.5l0 .01" />
                                        </svg>
                                    </a>
                                    <a href="https://www.facebook.com/fispsalud" target='_blank'>
                                        <svg width="32" height="32" viewBox="0 0 24 24" strokeWidth="1" stroke="#888888" fill="none">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3" />
                                        </svg>
                                    </a>
                                </div>
                                <div className="col d-flex gap-2 px-0">
                                    <a href="https://www.linkedin.com/company/fisp-org/" target='_blank'>
                                        <svg width="32" height="32" viewBox="0 0 24 24" strokeWidth="1" stroke="#888888" fill="none">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <path d="M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z" />
                                            <path d="M8 11l0 5" />
                                            <path d="M8 8l0 .01" />
                                            <path d="M12 16l0 -5" />
                                            <path d="M16 16v-3a2 2 0 0 0 -4 0" />
                                        </svg>
                                    </a>
                                    <a href="https://www.tiktok.com/@fispsalud" target='_blank'>
                                        <svg width="32" height="32" viewBox="0 0 24 24" strokeWidth="1" stroke="#888888" fill="none">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <path d="M9 12a4 4 0 1 0 4 4v-12a5 5 0 0 0 5 5"></path>
                                        </svg>
                                    </a>
                                    <a href="https://open.spotify.com/show/5VlWWkUHnHON4PIodMQw79?si=mKHoxjCURxW336L2W-R1CA&nd=1" target='_blank'>
                                        <svg width="32" height="32" viewBox="0 0 24 24" strokeWidth="1" stroke="#888888" fill="none">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                                            <path d="M8 11.973c2.5 -1.473 5.5 -.973 7.5 .527" />
                                            <path d="M9 15c1.5 -1 4 -1 5 .5" />
                                            <path d="M7 9c2 -1 6 -2 10 .5" />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div className="mt-3 w-75 d-flex justify-content-center copyright">
                    <h6>© Copyright FISP 2024</h6>
                </div>
            </div>
        </div>
    )
}

export default FooterEducativo