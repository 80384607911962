import { useParams } from 'react-router-dom';
import CuidadosPediatricosRespiratorios from '../CuidadosPediatricosRespiratorios';
import EnfermeriaOncologica from '../EnfermeriaOncologica';
import PaginaError from '../../../PaginaError';
import EnfermeriaCuidadosPaliativos from '../EnfermeriaCuidadosPaliativos';
import GestionServiciosEmpresasSalud from '../GestionServiciosEmpresasSalud';
import NutricionDeportiva from '../NutricionDeportiva';
import ToxinaBotulinica from '../ToxinaBotulinica';
import UrgenciasToxicologia from '../UrgenciasToxicologia';
import NutricionDiabetes from '../NutricionDiabetes';
import Skincare from '../Skincare';
import EnfermeriaNeurologica from '../EnfermeriaNeurologica';
import TCA from '../TCA';
import TerapiaIntensivaPediatrica from '../TerapiaIntensivaPediatrica';
import BioremodelacionFacial from '../BioremodelacionFacial';
import UrgenciasNeurocardiologicas from '../UrgenciasNeurocardiologicas';
import IAMedica from '../IAMedica';
import EnfermeriaConsumosProblematicos from '../EnfermeriaConsumosProblematicos';

const DiplomaturaRedirect = () => {
    const { curso } = useParams();
    let serviceComponent;
    switch (curso) {
        // case 'cuidados-pediatricos-respiratorios':
        //     serviceComponent = <CuidadosPediatricosRespiratorios />
        //     break;
        case 'bioremodelacion-facial':
            serviceComponent = <BioremodelacionFacial />
            break;
        case 'enfermeria-oncologica':
            serviceComponent = <EnfermeriaOncologica />
            break;
        case 'enfermeria-cuidados-paliativos-en-pacientes-adultos-y-pediatricos':
            serviceComponent = <EnfermeriaCuidadosPaliativos />
            break;
        case 'gestion-de-servicios-y-empresas-de-salud':
            serviceComponent = <GestionServiciosEmpresasSalud />
            break;
        case 'nutricion-deportiva-de-alto-rendimiento':
            serviceComponent = <NutricionDeportiva />
            break;
        case 'urgencias-en-toxicologia':
            serviceComponent = <UrgenciasToxicologia />
            break;
        // case 'nutricion-y-diabetes-tipo-2':
        //     serviceComponent = <NutricionDiabetes />
        //     break;
        case 'skincare':
            serviceComponent = <Skincare />
            break;
        // case 'enfermeria-neurologica':
        //     serviceComponent = <EnfermeriaNeurologica />
        //     break;
        // case 'tca-abordaje-integral-y-enfoque-practico':
        //     serviceComponent = <TCA />
        //     break;
        case 'terapia-intensiva-pediatrica':
            serviceComponent = <TerapiaIntensivaPediatrica />
            break;
        case 'urgencias-neurocardiologicas':
            serviceComponent = <UrgenciasNeurocardiologicas />
            break;
        case 'inteligencia-artificial-medica':
            serviceComponent = <IAMedica />
            break;
        case 'enfermeria-consumos-problematicos':
            serviceComponent = <EnfermeriaConsumosProblematicos />
            break;
        default:
            serviceComponent = <PaginaError />
            break;
    }

    return serviceComponent
};

export default DiplomaturaRedirect;