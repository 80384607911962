import React from 'react'

const TemarioEnfermeriaNeurologica = () => {
    return (
        <div className='temario-container border w-100 p-5'>
            <span>MÓDULO 1: Anatomía y Evaluación Neurológica. Rol de Enfermería</span>
            <br /><br />
            <ul>
                <span>Objetivos específicos:</span>
                <br /><br />
                <li className='fw-light'>Comprender la anatomía y fisiología del sistema nervioso central (SNC) y el sistema nervioso periférico (SNP) para proporcionar una base sólida de conocimientos sobre la estructura y función neurológica y su relevancia en el cuidado de los pacientes.</li>
                <li className='fw-light'>Dominar las técnicas de anamnesis de enfermería específicas para evaluar de manera exhaustiva los antecedentes clínicos y neurológicos de los pacientes, identificando factores de riesgo, síntomas y características relevantes para un adecuado diagnóstico y plan de cuidados.</li>
                <li className='fw-light'>Adquirir habilidades prácticas en el examen físico neurológico, incluyendo la evaluación de la función motora, sensitiva y de los reflejos, para identificar alteraciones neurológicas y realizar una valoración integral de los pacientes.</li>
                <li className='fw-light'>Familiarizarse con los estudios complementarios utilizados en neurología, como la punción lumbar, el electroencefalograma (EEG) y los estudios neurofisiológicos, comprendiendo su finalidad, indicaciones, procedimientos y cuidados de enfermería asociados a cada uno de ellos.</li>
                <li className='fw-light'>Desarrollar conocimientos y habilidades en la interpretación de estudios por imágenes neurológicas, como la resonancia magnética (RM) y la tomografía computarizada (TC), con el fin de comprender los hallazgos radiológicos y colaborar en la identificación y seguimiento de enfermedades y lesiones neurológicas.</li>
                <li className='fw-light'>Dominar los test de valoración neurológica utilizados en la práctica clínica, como la Escala de Glasgow y la Escala NIHSS (National Institutes of Health Stroke Scale), entre otras, para evaluar el nivel de conciencia, la gravedad de un accidente cerebrovascular u otras afecciones neurológicas, y facilitar la comunicación interprofesional.</li>
                <li className='fw-light'>Adquirir competencias en el manejo de los cuidados de enfermería específicos para pacientes neurológicos, incluyendo la movilización y el manejo del paciente encamado, la prevención y tratamiento de complicaciones, y la promoción del autocuidado y bienestar.</li>
                <li className='fw-light'>Desarrollar habilidades de comunicación efectiva y empatía en la relación con pacientes neurológicos y sus familias, reconociendo la importancia del apoyo emocional y educación sobre la enfermedad, tratamientos y recursos disponibles.</li>
            </ul>
        </div>
    )
}
/*
            <br />
            <span></span>
            <br /><br />
            <ul>
                <span></span>
                <br /><br />
                <li className='fw-light'></li>
                <li className='fw-light'></li>
                <li className='fw-light'></li>
            </ul>
*/

export default TemarioEnfermeriaNeurologica