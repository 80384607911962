import React from 'react'

const TemarioNeurocardiologicas = () => {
    return (
        <div className='temario-container border w-100 p-5'>
            <span>MÓDULO 1: EMERGENCIAS CARDIOVASCULARES</span><br /><br />

            <span>UNIDAD 1: “Infarto agudo de miocardio y otras causas de dolor torácico en la Emergencia”</span><br /><br />
            <ul>
                <li className="fw-light">1.1. Fisiopatología del infarto agudo de miocardio</li>
                <li className="fw-light">1.2. Clasificación y Diagnóstico Diferencial</li>
                <li className="fw-light">1.3. Evaluación en la unidad de emergencias: “Código Infarto” y Escalas de estratificación de riesgo isquémico/hemorrágico</li>
            </ul>

            <span>UNIDAD 2: “Síndrome aórtico agudo y Taponamiento cardíaco en la Emergencia”</span><br /><br />
            <ul>
                <li className="fw-light">2.1. Definición, Fisiopatología y Clasificación del Síndrome aórtico agudo</li>
                <li className="fw-light">2.2. Evaluación Clínica y Diagnóstico por Imagen en SAA</li>
            </ul>

            <span>UNIDAD 3: “Tromboembolismo de Pulmón y otras causas de disnea en la Emergencia”</span><br /><br />
            <ul>
                <li className="fw-light">3.1. Fisiopatología de la trombosis venosa profunda y el tromboembolismo de pulmón</li>
                <li className="fw-light">3.2. Diagnósticos Diferenciales y métodos diagnósticos</li>
                <li className="fw-light">3.3. Evaluación y Escalas de Severidad</li>
            </ul>

            <span>UNIDAD 4: “Crisis Hipertensivas: Hipertensión arterial en la Emergencia”</span><br /><br />
            <ul>
                <li className="fw-light">4.1. Fisiopatología de la hipertensión arterial</li>
                <li className="fw-light">4.2. Métodos diagnósticos</li>
                <li className="fw-light">4.3. Clasificación y valoración de severidad</li>
                <li className="fw-light">4.4. Tratamiento</li>
            </ul>

            <span>UNIDAD 5: “Arritmias y paro cardíaco en la Emergencia”</span><br /><br />
            <ul>
                <li className="fw-light">5.1. Taquicardias supraventriculares</li>
                <li className="fw-light">5.2. Taquicardia y Fibrilación ventricular</li>
                <li className="fw-light">5.3. Trastornos de la conducción cardíaca</li>
            </ul>

            <span>UNIDAD 6: “Insuficiencia cardíaca aguda y shock cardiogénico en la emergencia”</span><br /><br />
            <ul>
                <li className="fw-light">6.1. Fisiopatología de la insuficiencia cardíaca aguda</li>
                <li className="fw-light">6.2. Diagnóstico, evaluación clínica y métodos complementarios</li>
                <li className="fw-light">6.3. Clasificación y marcadores pronósticos</li>
            </ul>

            <span>UNIDAD 7: “ACV Isquémico en la Emergencia”</span><br /><br />
            <ul>
                <li className="fw-light">7.1. Situación y epidemiología del ACV en Argentina</li>
                <li className="fw-light">7.2. Clasificación etiológica y Diagnóstico Diferencial</li>
                <li className="fw-light">7.3. Evaluación y Escalas de Gravedad</li>
                <li className="fw-light">7.4. Manejo pre-hospitalario del ACV isquémico agudo</li>
            </ul>
        </div>
    )
}

export default TemarioNeurocardiologicas
