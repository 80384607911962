// IMAGENES y VIDEOS
import videoServicios from './multimedia/videos/video-servicios.mp4'
import ServiciosTecnologicos from '../Componentes/multimedia/imagenes/servicios/foto-servicios-tecnologicos.jpg'
import EmpresasSaludables from '../Componentes/multimedia/imagenes/servicios/empresas-saludables.jpg'
import ProduccionYMarketing from '../Componentes/multimedia/imagenes/servicios/marketing-produccion.jpg'
import ServiciosMedicos from '../Componentes/multimedia/imagenes/servicios/servicios-medicos.jpg'
import Confluencia from '../Componentes/multimedia/imagenes/Logos - Empresas/Confluencia.png'
import Diagnostica from '../Componentes/multimedia/imagenes/Logos - Empresas/Diagnostica.png'
import EXO from '../Componentes/multimedia/imagenes/Logos - Empresas/ExoLogo.png'
import Maxener from '../Componentes/multimedia/imagenes/Logos - Empresas/Maxener.png'
import Prevemed from '../Componentes/multimedia/imagenes/Logos - Empresas/Prevemed.png'
import Socolinsky from '../Componentes/multimedia/imagenes/Logos - Empresas/Socolinsky.png'
import Soflex from '../Componentes/multimedia/imagenes/Logos - Empresas/Soflex.png'
import UBA from '../Componentes/multimedia/imagenes/Logos - Empresas/UBA.png'
import Vadigu from '../Componentes/multimedia/imagenes/Logos - Empresas/Vadigu.png' 
import Virtech from '../Componentes/multimedia/imagenes/Logos - Empresas/Virtech.png'
import Danone from '../Componentes/multimedia/imagenes/Logos - Empresas/Danone.png'
import Hilton from '../Componentes/multimedia/imagenes/Logos - Empresas/Hilton.png'
import Access from '../Componentes/multimedia/imagenes/Logos - Empresas/Access.png'
import Absign from '../Componentes/multimedia/imagenes/Logos - Empresas/Absign.png'
import Infomedical from '../Componentes/multimedia/imagenes/Logos - Empresas/Infomedical.png'
import Aerocare from '../Componentes/multimedia/imagenes/Logos - Empresas/Aerocare.png'
import Thinknet from '../Componentes/multimedia/imagenes/Logos - Empresas/Thinknet.png'
import Bioboxmed from '../Componentes/multimedia/imagenes/Logos - Empresas/Bioboxmed.png'
import Telecom from '../Componentes/multimedia/imagenes/Logos - Empresas/Telecom.png'
import Atlas from '../Componentes/multimedia/imagenes/Logos - Empresas/Atlas.png'
import Cudim from '../Componentes/multimedia/imagenes/Logos - Empresas/Cudim.png'

import React, {useEffect,useRef} from 'react'
import RegularNavbar from './Navbars/RegularNavbar'
import SideNav from './Navbars/SideNav'
import { Link } from 'react-router-dom';
import gsap from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'
import transicionEscalera from './Transiciones/transicionEscalera'
import transicionBottomTop from './Transiciones/transicionBottomTop'

gsap.registerPlugin(ScrollTrigger)

const Servicios = () => {
    const isMountedRef = useRef(false);
    useEffect(()=>{
        if (!isMountedRef.current) {
            window.scroll(0, 0);
            isMountedRef.current = true;
        }
    },[])

    const logos = [
        [
            { imagen: Confluencia, nombre: "logo-empresa-Confluencia" },
            { imagen: Diagnostica, nombre: "logo-empresa-Diagnostica" },
            { imagen: EXO, nombre: "logo-empresa-EXO" },
            { imagen: Maxener, nombre: "logo-empresa-Maxener" },
            { imagen: Prevemed, nombre: "logo-empresa-Prevemed" },
            { imagen: Danone, nombre: "logo-empresa-Danone" },
            { imagen: Aerocare, nombre: "logo-empresa-Aerocare" },
            { imagen: Thinknet, nombre: "logo-empresa-Thinknet", clase: "img-dropshadow" },
            { imagen: Bioboxmed, nombre: "logo-empresa-Bioboxmed" },
            { imagen: Telecom, nombre: "logo-empresa-Telecom" },
        ],
        [
            { imagen: Socolinsky, nombre: "logo-empresa-Socolinsky" },
            { imagen: Soflex, nombre: "logo-empresa-Soflex" },
            { imagen: UBA, nombre: "logo-empresa-UBA" },
            { imagen: Vadigu, nombre: "logo-empresa-Vadigu" },
            { imagen: Virtech, nombre: "logo-empresa-Virtech" },
            { imagen: Hilton, nombre: "logo-empresa-Hilton" },
            { imagen: Access, nombre: "logo-empresa-Access" },
            { imagen: Absign, nombre: "logo-empresa-Absign" },
            { imagen: Infomedical, nombre: "logo-empresa-Infomedical" },
            { imagen: Atlas, nombre: "logo-empresa-Atlas" },
            { imagen: Cudim, nombre: "logo-empresa-Cudim" },
        ]
    ];
    return (
        <>
            <main className="services-page-container overflow-x-hidden" id='main-element'>
                <SideNav />
                <div className="video-container">
                    <div className="overlay">
                        <div className='d-flex flex-column'>
                            <h1 className='fs-medium-title neo'>POTENCIAMOS LOS SERVICIOS</h1>
                            <h1 className='fs-medium-title neo'>DE TECNOLOGÍA Y SALUD</h1>
                            <h1 className='fs-medium-title neo'>DE TU ORGANIZACIÓN</h1>
                        </div>
                    </div>
                    <video autoPlay muted loop playsInline>
                        <source src={videoServicios} type='video/mp4' />
                    </video>
                </div>
                <div className="container-fluid py-4 service-section-bar">
                    <div className="d-flex justify-content-center">
                        <h3 className='poppins fw-semibold my-1'>NUESTROS SERVICIOS</h3>
                    </div>
                </div>
                <div className="container-fluid services">
                    <div className="row">
                        <div className="service col-md-6 col-sm-12 px-0">
                            <Link to='servicios-tecnologicos'>
                                <img loading='lazy' className="service-image h-100" src={ServiciosTecnologicos} alt="Servicios Tecnologicos" />
                                <div className="service-content d-flex flex-column justify-content-end px-5 py-4 gap-2">
                                    <h3>SERVICIOS TECNOLÓGICOS</h3>
                                    <button className='mb-4'>LEER MAS &gt;&gt;</button>
                                </div>
                            </Link>
                        </div>
                        <div className="service col-md-6 col-sm-12 px-0">
                            <Link to='programa-de-wellness-corporativo'>
                            <img loading='lazy' className="service-image" src={EmpresasSaludables} alt="Wellness Corporativo" />
                            <div className="service-content d-flex flex-column justify-content-end px-5 py-4 gap-2">
                                <h3>WELLNESS CORPORATIVO</h3>
                                <button className='mb-4'>LEER MAS &gt;&gt;</button>
                            </div>
                            </Link>
                        </div>
                        <div className="service col-md-6 col-sm-12 px-0">
                            <Link to='produccion-marketing'>
                            <img loading='lazy' className="service-image" src={ProduccionYMarketing} alt="Produccion y Marketing" />
                            <div className="service-content d-flex flex-column justify-content-end px-5 py-4 gap-2">
                                <h3>MARKETING Y PRODUCCIÓN</h3>
                                <button className='mb-4'>LEER MAS &gt;&gt;</button>
                            </div>
                            </Link>
                        </div>
                        <div className="service col-md-6 col-xs-12 px-0">
                            <Link to='servicios-medicos'>
                            <img loading='lazy' className="service-image" src={ServiciosMedicos} alt="Servicios Medicos" />
                            <div className="service-content d-flex flex-column justify-content-end px-5 py-4 gap-2">
                                <h3>SERVICIOS MÉDICOS</h3>
                                <button className='mb-4'>LEER MAS &gt;&gt;</button>
                            </div>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="convenios-container d-flex flex-column align-items-center mb-5 pb-5">
                    <h1 className='fs-2 fw-semibold poppins pb-4'>PARTNERS</h1>
                    <div className="convenios container-fluid border d-flex flex-column justify-content-center position-relative">
                        <div className="carousel-container">
                            {
                                [...Array(3)].map((_, i) => {
                                    return (
                                        <div key={i} className="carousel-slide-right">
                                            {logos[0].map((logo, index) => {
                                                return (
                                                    <div key={index} className="carousel-element">
                                                        <img loading='lazy' src={logo.imagen} alt={logo.nombre} />
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                    <div className="convenios container-fluid border d-flex flex-column justify-content-center position-relative">
                    <div className="carousel-container">
                            {
                                [...Array(3)].map((_, i) => {
                                    return (
                                        <div key={i} className="carousel-slide-right-slow">
                                            {logos[1].map((logo, index) => {
                                                return (
                                                    <div key={index} className="carousel-element">
                                                        <img loading='lazy' src={logo.imagen} alt={logo.nombre} className={logo.clase} />
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                    <div className="py-3"></div>
                </div>
                <RegularNavbar />
            </main>
        </>
    )
}

export default window.innerWidth >= 1000 ? transicionEscalera(Servicios) : transicionBottomTop(Servicios);
