import React, { useEffect } from 'react'
import PortalContext from './PortalContext'; // Import PortalContext as default
import { cursos } from './EducativoContext';
import { createContext } from "react";
import { useNavigate } from 'react-router-dom';
export const context = createContext()
const { Provider } = context

const ContextProvider = ({children}) => {

    const { lastArticles, isLogged, setIsLogged, convertTittleToUrl } = PortalContext()

    const contextValue = {
        lastArticles,
        cursos,
        isLogged,
        setIsLogged,
        convertTittleToUrl
    }

    return (
        <Provider value={contextValue}>
            {children}
        </Provider>
    )
}

export default ContextProvider