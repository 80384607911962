import React, { useContext, useEffect, useState } from 'react'
import FooterPortal from '../Portal/FooterPortal';
import Axios from "axios";
import { context } from '../Context/Context';
import { ToastContainer, toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom';
import transicionEscalera from '../Transiciones/transicionEscalera';
import transicionBottomTop from '../Transiciones/transicionBottomTop';
import NavbarEducativa from '../Navbars/NavbarEducativa';
import { Helmet } from 'react-helmet';

const EducativoLogin = () => {

    const { isLogged,setIsLogged,logIn } = useContext(context)
    const [user,setUser] = useState("")
    const [password,setPassword] = useState("")
    const navigate = useNavigate()
    const clearInputs = () => {setUser("");setPassword("")}
    const verifyLogin = () => {
        setIsLogged(true)
        Axios.post("https://srv452353.hstgr.cloud/login",{
            user: user,
            password: password
        }).then((result)=>{
            console.log(result)
            if(result.data!=""){
                setIsLogged(true)
                sessionStorage.setItem("Login", true);
                toast.success("Inicio de sesión exitoso")
                clearInputs()
                navigate('/educativo/listado-de-formularios')
            }else{
                toast.error("Los datos ingresados son inválidos")
                clearInputs()
            }
        }).catch(()=>{
            console.log("Error")
            clearInputs()
        })
    }
    
    return (
            <main className='main-login-container section-big container-fluid'>
                <Helmet>
                    <meta name="robots" content="noindex" />
                </Helmet>
                <NavbarEducativa />
                <ToastContainer />
                <div className="login-container container-xxl d-flex justify-content-center align-items-center flex-column">
                    <div className="login-form-container col-5 p-5 rounded-4 box-shadow">
                        <div className='login-form pt-4' onSubmit={(e)=>{verifyLogin(e)}}>
                            <h1 className='text-center poppins uppercase fw-bold border-bottom mb-5 pb-3'>Login</h1>
                            <div className="mb-3">
                                <label htmlFor="usuario" className="poppins form-label">Usuario</label>
                                <input type="text" className="form-control bg-light" value={user} id="usuario" name="usuario" onChange={(e)=>{
                                    const newValue = e.target.value.replace(/[\'\"#;=]/g, '');
                                    setUser(newValue)}} 
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="contraseña" className="poppins form-label">Contraseña</label>
                                <input type="password" className="form-control bg-light" value={password} id="contraseña" name="contraseña" onChange={(e)=>{
                                    const newValue = e.target.value.replace(/[\'\"#;=]/g, '');
                                    setPassword(newValue)}} 
                                    />
                            </div>
                            <button className="btn btn-dark w-100 py-3 mt-3 poppins uppercase" onClick={()=>{verifyLogin()}}>Iniciar sesión</button>
                        </div>
                    </div>
                </div>
                <FooterPortal />
            </main>
    )
}

export default window.innerWidth >= 1000 ? transicionEscalera(EducativoLogin) : transicionBottomTop(EducativoLogin);