import React, { useEffect, useRef } from 'react'
import DADIC from '../../multimedia/imagenes/fundacion/staff-academico/Dadic.png'
import PIETRANTONIO from '../../multimedia/imagenes/fundacion/staff-academico/Pietrantonio.png'
import FARINELLI from '../../multimedia/imagenes/fundacion/staff-ejecutivo/Farinelli.png'
import CRESCENTI from '../../multimedia/imagenes/fundacion/staff-academico/Crescenti.png'
import PASTOR from '../../multimedia/imagenes/fundacion/staff-academico/Pastor.png'
import ALET from '../../multimedia/imagenes/fundacion/staff-academico/Alet.jpg'
import Imagen from '../../multimedia/imagenes/produccion-marketing/charlas-corporativas.jpg'
import RegularNavbar from '../../Navbars/RegularNavbar'
import SideNav from '../../Navbars/SideNav'
import transicionBottomTop from '../../Transiciones/transicionBottomTop'
import { Link } from 'react-router-dom'

const CharlasCorporativas = () => {
    const isMountedRef = useRef(false);
    useEffect(()=>{
        if (!isMountedRef.current) {
            window.scroll(0, 0);
            isMountedRef.current = true;
        }
    },[])

    const staff = [
        {
            nombre: 'Dr. Alberto Felix Crescenti',
            imagen: CRESCENTI,
            especialidad: ''
        },
        {
            nombre: 'Dr. Francisco Dadic',
            imagen: DADIC,
            especialidad: 'Especialista en Toxicología Médica'
        },
        {
            nombre: 'Dr. Carlos A. Di Pietrantonio',
            imagen: PIETRANTONIO,
            especialidad: 'Médico epidemiólogo'
        },
        {
            nombre: 'Dr. Raúl F. Pastor',
            imagen: PASTOR,
            especialidad: 'Médico especialista en Calidad de Vida'
        },
        {
            nombre: 'Lic. Aldana Farinelli',
            imagen: FARINELLI,
            especialidad: 'Especialista en Marketing médico'
        },
        {
            nombre: 'Dr. Matias Alet',
            imagen: ALET,
            especialidad: 'Director de Neurología'
        }
    ];

    return (
        <main className='charlas-corporativas-container pym-detalle' id='main-element'>
            <SideNav />
            <div className="servicio-main-image container-fluid px-0">
                <img className='w-100' src={Imagen} alt="Charlas Corporativas" />
            </div>
            <div className="container-xxl">
                <div className='mh-500 d-flex flex-column align-items-center py-5'>
                    <h1 className='poppins text-center fs-7 pb-4'>Charlas corporativas</h1>
                    <p className='poppins text-center fs-5 col-11 col-sm-9'>¿Buscás nuevas formas de motivar a tus empleados? Potenciá su desarrollo profesional, fortalece el conocimiento y la conciencia sobre la importancia de la salud.</p>
                    <p className='poppins text-center fs-5 col-11 col-sm-9'>Podrás sumar a tu organización charlas de la mano de destacados profesionales en diversos campos de la salud pública comprometidos en compartir su amplia experiencia y conocimientos con su empresa.</p>
                    <p className='poppins text-center fs-5 col-11 col-sm-9'>Nuestras charlas ofrecerán ideas prácticas y aplicables a su entorno empresarial. Desde la importancia de la prevención y el bienestar en el lugar de trabajo hasta el impacto positivo de las políticas sanitarias en la productividad.</p>
                    <p className='poppins text-center fs-5 col-11 col-sm-9'>Además, nuestras charlas se adaptan a las necesidades específicas de su organización: Desde una sesión breve como parte de un evento o una serie de conferencias más completa.</p>
                </div>
                <div className='container-xxl charlas-corporativas-staff d-flex gap-5 flex-wrap justify-content-center pb-5 mb-5'>
                    <h3 className='poppins w-100 text-center'>Nuestro equipo</h3>
                    {
                        staff.map((miembro, index) => (
                            <div key={index} className="charlas-corporativas-member col-lg-3 col-md-5 col-9 border position-relative">
                                <img loading='lazy' src={miembro.imagen} className='object-fit-cover w-100 h-100 position-absolute top-0 start-0' alt={miembro.nombre} />
                                <div className="overlay">
                                    <h4 className='text-light fw-bold'>{miembro.nombre}</h4>
                                    {miembro.especialidad && <p className='text-white text-shadow fw-medium px-3 fs-6 pt-2'>{miembro.especialidad}</p>}
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className="w-100 d-flex justify-content-center mb-5">
                    <button className='py-3 position-relative text-white text-shadow d-flex align-items-center justify-content-center mb-5'>
                        <Link className='d-flex justify-content-center' to="/servicios/contacto/">
                            CONTACTANOS
                            <svg width="28" height="28" viewBox="0 0 24 24" strokeWidth="3.5" stroke="#FFFFFF" fill="none">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <path d="M9 6l6 6l-6 6" />
                            </svg>
                        </Link>
                    </button>
                </div>
            </div>
            
            <RegularNavbar />
        </main>
    )
}

export default transicionBottomTop(CharlasCorporativas)