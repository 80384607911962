import React from 'react'

const TemarioNutricionDiabetes = () => {
    return (
        <div className='temario-container border w-100 p-5'>
            <span>Módulo 1. Fisiología y fisiopatología de DBT 2</span>
            <br /><br />
            <ul>
                <span>Objetivos específicos: comprender la fisiopatología de diabetes tipo 2</span>
                <br /><br />
                <li className='fw-light'>Bioquímica y metabolismo. Vías metabólicas. Hormonas.</li>
                <li className='fw-light'>Nutrientes presentes en los alimentos: Carbohidratos, proteínas, grasas, vitaminas y minerales. Clasificación. Funciones. Alimentos fuente. Requerimientos y recomendaciones.</li>
                <li className='fw-light'>Etiopatogenia de la diabetes tipo 2</li>
                <li className='fw-light'>Mecanismos de desarrollo de complicaciones: daño hiperglucémico</li>
            </ul>
            <br />
            <span>Modulo 2. Nutrición antiinflamatoria en el paciente con DBT tipo 2.</span>
            <br /><br />
            <ul>
                <span>Objetivos específicos: brindar al profesional los conocimientos sobre la prevención de la inflamación metabólica, aplicando la nutrición como estrategia complementaria al tratamiento del paciente.</span>
                <br /><br />
                <li className='fw-light'>Nutrición e inflamación metabólica.</li>
                <li className='fw-light'>Estrés oxidativo. Glicación proteica</li>
                <li className='fw-light'>Fitoquímicos en la nutrición antiinflamatoria</li>
            </ul>
            <br />
            <span>Módulo 3. Tratamiento farmacológico de la DM tipo 2 y su implicancia nutricional.</span>
            <br /><br />
            <ul>
                <span>Objetivos específicos: establecer los fármacos a utilizar en diabetes tipo 2</span>
                <br /><br />
                <li className='fw-light'>Agentes con acción periférica: Inhibidores de Alfa-Glucosidasa, Metformina y Tiazolidindionas, Inhibidores de SGLT2</li>
                <li className='fw-light'>Agentes insulino-secretagogos: Sulfonilureas, Meglitinidas, Incretinas (Agonistas del Receptor de GLP-1 e inhibidoras de DPP-4)</li>
                <li className='fw-light'>Insulinas e insulinoterapia. Tipos de insulina. Estrategias de aplicación: instrumentos, sistemas, planes y automanejo de la insulina. Dificultades de la insulinoterapia</li>
                <li className='fw-light'>Patologías concomitantes a la diabetes tipo 2 y su tratamiento.</li>
            </ul>
            <br />
            <span>Módulo 4. Planes de alimentación en el paciente con DM2</span>
            <br /><br />
            <ul>
                <span>Objetivos específicos: brindar al profesional las herramientas necesarias para realizar una correcta indicación del plan nutricional</span>
                <br /><br />
                <li className='fw-light'>Plan de alimentación personalizado. Porciones. Índice glucémico. Hidratos y fibra.</li>
                <li className='fw-light'>Paciente insulinizado y no insulinizado</li>
                <li className='fw-light'>Tipo de dietas: Dieta vegetariana, Dieta vegana, Dieta cetogénica</li>
            </ul>
            <br />
            <span>Módulo 5. Actividad física en el paciente con DBT tipo 2. Sarcopenia y prevención</span>
            <br /><br />
            <ul>
                <span>Objetivos específicos: establecer las pautas para la indicación de la actividad física en el paciente con diabetes tipo 2</span>
                <br /><br />
                <li className='fw-light'>Fisiología del ejercicio. Adaptación metabólica</li>
                <li className='fw-light'>Indicación de Macronutrientes y Micronutrientes en el paciente con DBT 2</li>
                <li className='fw-light'>Composición corporal: masa grasa vs masa muscular </li>
                <li className='fw-light'>Hidratación: Regulación y necesidades.</li>
                <li className='fw-light'>Sarcopenia. Prevención y tratamiento en el paciente con DBT tipo 2</li>
            </ul>
            <br />
            <span>Módulo 6. Complicaciones de la DM tipo 2</span>
            <br /><br />
            <ul>
                <span>Objetivos específicos: analizar las complicaciones de la patología</span>
                <br /><br />
                <li className='fw-light'>Emergencias en el paciente con DBT 2. Cetoacidosis y coma diabético. Hipoglucemia. Coma. Acidosis láctica</li>
                <li className='fw-light'>Complicaciones a largo plazo. Prevención. Tratamiento</li>
            </ul>
            <br />
            <span>Módulo 7. El paciente con diabetes tipo 2 en internación</span>
            <br /><br />
            <ul>
                <span>Objetivos específicos: comprender, analizar y establecer las pautas nutricionales a seguir en el paciente en internación con diabetes tipo 2</span>
                <br /><br />
                <li className='fw-light'>Introducción </li>
                <li className='fw-light'>Mecanismos involucrados en el desarrollo de hiperglucemia </li>
                <li className='fw-light'>Alteraciones asociadas con la hiperglucemia aguda </li>
                <li className='fw-light'>Actitud frente a la hiperglucemia en la internación. Monitoreo glucémico para el paciente internado</li>
            </ul>
            <br />
            <span>Módulo 8. Nutrición para la prevención del envejecimiento</span>
            <br /><br />
            <ul>
                <span>Objetivos específicos: Definir envejecimiento y establecer la correcta terapéutica nutricional en cada paciente. Brindar herramientas terapéuticas adecuadas a futuro</span>
                <br /><br />
                <li className='fw-light'>Definición de envejecimiento</li>
                <li className='fw-light'>¿Podemos considerar al envejecimiento una enfermedad?</li>
                <li className='fw-light'>Terapéutica nutricional hoy y a futuro en el paciente con DBT tipo 2</li>
            </ul>
        </div>
    )
}
/*
            <br />
            <span></span>
            <br /><br />
            <ul>
                <span></span>
                <br /><br />
                <li className='fw-light'></li>
                <li className='fw-light'></li>
                <li className='fw-light'></li>
            </ul>
*/

export default TemarioNutricionDiabetes