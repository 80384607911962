import React, { useEffect, useRef } from 'react'
import SideNav from '../../Navbars/SideNav'
import RegularNavbar from '../../Navbars/RegularNavbar'
import rcp from '../../multimedia/imagenes/servicios-medicos/rcp-virtual.png'
import cardioProtegidas from '../../multimedia/imagenes/servicios-medicos/DEA/dea.jpg'
import fondoServiciosMedicos from '../../multimedia/imagenes/servicios-medicos/fondo-servicios-medicos.png'
import collageMedicos from '../../multimedia/imagenes/servicios-medicos/collage-medicos.png'
import transicionEscalera from '../../Transiciones/transicionEscalera'
import { Link } from 'react-router-dom'
import transicionBottomTop from '../../Transiciones/transicionBottomTop'

const ServiciosMedicos = () => {
    const isMountedRef = useRef(false)
    useEffect(()=>{
        if (!isMountedRef.current) {
            window.scroll(0, 0);
            isMountedRef.current = true;
        }
    },[])
    return (
        <main className="servicios-medicos-container" id='main-element'>
            <SideNav />
            <div className="section-big position-relative d-flex align-items-center my-0">
                <img className='position-absolute w-100 h-100 z-5 object-fit-cover' src={fondoServiciosMedicos} alt="Servicios Medicos" />
                <div className="container-fluid z-10 d-flex">
                    <div className="col-xl-4 col-lg-8 col-10 py-3">
                        <img src={collageMedicos} className='collageMedicos w-100' alt="collageMedicos" />
                    </div>
                    <div className="col-xl-7 col-11 py-3 d-flex justify-content-center flex-column">
                        <h1 className='text-light fw-bold fs-bigger-title text-center title'>SERVICIOS MEDICOS</h1>
                        <p className='text-center mx-0 px-0 mx-lg-5 px-lg-5 mt-2 mt-lg-5 fs-5'>Nuestra misión es brindar atención médica de calidad y fomentar la prevención de enfermedades para garantizar un entorno más seguro y protegido</p>
                    </div>
                </div>
            </div>
            <div className="section-md mb-5 pb-1 pb-sm-0">
                <div className="container-fluid d-flex p-0 mb-3">
                    <div className="col rcp-container section-md position-relative">
                        <img className='service-image' src={rcp} alt="Capacitación de RCP con realidad virtual" />
                        <div className="overlay">
                            <h2 className='poppins fw-semibold py-4 fs-3 px-0 px-sm-5 mx-5'>Capacitación de RCP con realidad virtual</h2>
                            <p className='fs-5 px-xl-5 px-0 mx-3'>Nuestro innovador programa de capacitación utiliza tecnología de realidad virtual para brindar una experiencia inmersiva y realista, permitiendo a los participantes practicar y perfeccionar sus técnicas de RCP en escenarios simulados. </p>
                            <button><Link to="rcp-virtual">+ INFO</Link></button>
                        </div>
                    </div>
                    <div className="col cardio-container section-md position-relative">
                        <img className='service-image' src={cardioProtegidas} alt="Relevamiento de áreas cardioprotegidas" />
                        <div className="overlay">
                            <h2 className='poppins fw-semibold py-4 fs-3 px-0 px-sm-5 mx-5'>Relevamiento de áreas cardioprotegidas</h2>
                            <p className='fs-5 px-xl-5 px-0 mx-3'>La correcta ubicación de desfibriladores automáticos y la implementación de protocolos de emergencia adecuados pueden marcar la diferencia entre la vida y la muerte en casos de paro cardíaco.</p>
                            <button><Link to="areas-cardioprotegidas">+ INFO</Link></button>
                        </div>
                    </div>
                </div>
            </div>
            <RegularNavbar />
        </main>
    )
}

export default window.innerWidth >= 1000 ? transicionEscalera(ServiciosMedicos) : transicionBottomTop(ServiciosMedicos);
