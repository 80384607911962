import React from 'react'
import transicionEscalera from '../Transiciones/transicionEscalera';
import transicionBottomTop from '../Transiciones/transicionBottomTop';
import logoFisp from '../multimedia/imagenes/FISP_Blanco.png'
import { Link } from 'react-router-dom';

const Cyberweek = () => {
    
    return (
        <>
            <main className='container-fluid' style={{background:'#1A3255'}}>
                <div className='cyberweek-container container-xxl d-flex flex-column justify-content-center align-items-center'>
                    <div className='d-flex w-100 justify-content-center justify-content-sm-end mb-4 mt-3 mt-sm-0'>
                        <img className='cyberweek-logo' src={logoFisp} />
                    </div>
                    <div className="d-flex flex-column align-items-center py-3 text-light">
                        <h1 className='montserrat fw-bold cyberweek-title text-center'>ESPECIAL CYBERWEEK</h1>
                        <h2 className='montserrat cyberweek-subtitle'>Hasta el 30 de Noviembre</h2>
                    </div>
                    <div className="d-flex col-12 col-md-8 flex-sm-row flex-column gap-3 gap-sm-0 justify-content-evenly py-2 py-sm-5">
                        <div className='cuotas m-0 p-0 gap-0 d-flex flex-column align-items-center col me-0 pb-3 me-sm-5 mb-sm-0 text-light'>
                            <p className='montserrat m-0 fs-1 fw-medium' style={{lineHeight:'1'}}>HASTA</p>
                            <p className='montserrat m-0 cuotas-number' style={{lineHeight:'1'}}>12</p>
                            <p className='montserrat m-0 fs-1 d-flex flex-column align-items-center fw-medium' style={{lineHeight:'1'}}>CUOTAS <span className='fw-bold'>sin interés</span></p>
                        </div>
                        <div className='d-flex flex-column justify-content-center align-items-sm-start align-items-center col text-light' style={{lineHeight:'1'}}>
                            <p className='fs-1 fw-medium montserrat m-0'>EN TODOS LOS</p>
                            <span className='fs-1 fw-bold montserrat text-center text-sm-start'>POSGRADOS Y DIPLOMATURAS</span>
                            <p className='fs-1 fw-medium montserrat m-0'>2025</p>
                        </div>
                    </div>
                    <Link to='/educativo/categoria/listado-entero '><button className='rounded-5 px-5 py-2 bg-light text-primary fs-3 my-3 my-sm-0'>INSCRIBITE</button></Link>
                </div>
            </main>
        </>
    )
}

export default window.innerWidth >= 1000 ? transicionEscalera(Cyberweek) : transicionBottomTop(Cyberweek);
