import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import NavbarPortal from '../Navbars/NavbarPortal';
import FooterPortal from './FooterPortal';
import { context } from '../Context/Context';
import SideArticles from './SideArticles'
import transicionEscalera from '../Transiciones/transicionEscalera';
import transicionBottomTop from '../Transiciones/transicionBottomTop';
import Axios from "axios";

const PortalBusquedaV2 = () => {
    const {busqueda} = useParams()
    const isMountedRef = useRef(false);
    const [queryFormat, setQueryFormat] = useState(busqueda)
    const [noticias,setNoticias] = useState([])

    const obtenerNotas = ()=>{
        Axios.get(`https://srv452353.hstgr.cloud/obtenernotas`)
        .then((response) => {
            // console.log("Noticias de",categoria,"recibidas")
            setNoticias(Array.from(response.data))
        })
        .catch((error) => {
            console.log("Error al traer las noticias")
            console.error(error);
        })
    }

    useEffect(()=>{
        if (!isMountedRef.current) {
            window.scroll(0, 0);
            isMountedRef.current = true;
        }
        obtenerNotas()
        function removeAccents(str) {
            return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        }
        function formatString(str) {
            str = str.replace(/%20/g, '-');
            str = removeAccents(str.toLowerCase());
            str = str.replace(/\s+/g, '-');
            str = str.replace(/[^a-z0-9\s\-]/g, '');
            
            return str;
        }
        setQueryFormat(formatString(queryFormat))
    },[busqueda])

    function formatString(str) {
        str = removeAccents(str.toLowerCase());
        str = str.replace(/%20/g, '-');
        str = str.replace(/\s+/g, '-');
        str = str.replace(/[^a-z0-9\s\-]/g, '');
        
        return str;
    }
    function removeAccents(str) {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }

    return (
        <>
            <main className='multimedia-home-container' id='main-element'>
                <NavbarPortal />
                <div className="container-xxl navbar-margin">
                        <div className="news-container d-flex">
                            <div className="big-news-column pe-3 col-md-9 col-12 pb-3 section-big mb-4">
                                <div className="list-article-container d-flex flex-column gap-4">
                                {
                                    noticias.length>0 ? (
                                    noticias
                                        .filter(article => formatString(article.titulo).includes(queryFormat))
                                        .map((article, index) => {
                                            return (
                                                <div className="list-article-item box-shadow py-4" key={index}>
                                                    <div className="item-container d-flex px-4">
                                                        <div className="col pe-lg-3 p-0">
                                                            <div className="w-100 overflow-hidden">
                                                                <Link to={`/portal-de-noticias/${article.url}`}><img src={article.imagen} className='w-100 object-fit-cover' alt={article.titulo} /></Link>
                                                            </div>
                                                        </div>
                                                        <div className="col ps-lg-3 p-0">
                                                            <div className="d-flex flex-column">
                                                                <Link to={`/portal-de-noticias/${article.url}`}><h2 className='fs-5 poppins fw-semibold mb-4'>{article.titulo}</h2></Link>
                                                                <p className='fs-6 fw-light poppins uppercase'>| {article.categoria} |</p>
                                                                <h3 className='fs-6 fw-light poppins mb-4 line-clamp-3'>{article.descripcion}</h3>
                                                                <Link to={`/portal-de-noticias/${article.url}`}><button className='px-3 py-2 poppins fw-semibold rounded-2'>VER NOTA</button></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })
                                        .concat(
                                            noticias.filter(article => formatString(article.titulo).includes(queryFormat)).length === 0 && (
                                                <div className="list-article-item box-shadow py-4" key="no-results">
                                                    <div className="item-container d-flex align-items-center px-4">
                                                        <div className=" pe-lg-3 p-0">
                                                            <svg width="120" height="120" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                                <path d="M4 8v-2a2 2 0 0 1 2 -2h2" />
                                                                <path d="M4 16v2a2 2 0 0 0 2 2h2" />
                                                                <path d="M16 4h2a2 2 0 0 1 2 2v2" />
                                                                <path d="M16 20h2a2 2 0 0 0 2 -2v-2" />
                                                                <path d="M9 10h.01" />
                                                                <path d="M15 10h.01" />
                                                                <path d="M9.5 15.05a3.5 3.5 0 0 1 5 0" />
                                                            </svg>
                                                        </div>
                                                        <div className="ps-lg-3 p-0">
                                                            <div className="d-flex flex-column">
                                                                <h2 className='fs-5 poppins fw-semibold mb-4'>No se encontraron resultados para "{busqueda}"</h2>
                                                                <Link to="/portal-de-noticias"><button className='px-3 py-2 poppins fw-semibold rounded-2'>Volver al portal de noticias</button></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        )
                                        ) : (
                                            <div className="list-article-item box-shadow py-4" key="no-results">
                                                <div className="item-container d-flex align-items-center px-4">
                                                    <div className="ps-lg-3 p-0">
                                                        <div className="d-flex flex-column">
                                                            <h2 className='fs-5 poppins fw-semibold mb-4'>Cargando resultados para "{busqueda}"...</h2>
                                                            <Link to="/portal-de-noticias"><button className='px-3 py-2 poppins fw-semibold rounded-2'>Volver al portal de noticias</button></Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                }
                                </div>
                            </div>
                            <SideArticles />
                        </div>
                </div>
            </main>
            <FooterPortal />
        </>
        )

}

export default window.innerWidth >= 1000 ? transicionEscalera(PortalBusquedaV2) : transicionBottomTop(PortalBusquedaV2); 