import React from 'react'

const TemarioPsiquiatriaClinica = () => {
    return (
        <div className='temario-container border w-100 p-5'>
            <span>Módulo 1: Introducción a las urgencias psiquiátricas</span><br /><br />
            <ul>
                <li className="fw-light">Conceptos básicos y definiciones</li>
                <li className="fw-light">Epidemiología y estadísticas de urgencias psiquiátricas</li>
                <li className="fw-light">Marco legal y ético en urgencias psiquiátricas</li>
                <li className="fw-light">Principios de evaluación y manejo inicial</li>
            </ul>

            <span>Módulo 2: Trastornos psiquiátricos agudos</span><br /><br />
            <ul>
                <li className="fw-light">Psicosis aguda</li>
                <li className="fw-light">Depresión y riesgo suicida</li>
                <li className="fw-light">Trastorno bipolar en fase aguda</li>
                <li className="fw-light">Trastornos de ansiedad y pánico</li>
                <li className="fw-light">Trastornos de estrés postraumático</li>
                <li className="fw-light">Trastornos de la personalidad</li>
                <li className="fw-light">Trastornos del estado de ánimo</li>
                <li className="fw-light">Trastornos psicóticos</li>
            </ul>

            <span>Módulo 3: Intervenciones y tratamientos</span><br /><br />
            <ul>
                <li className="fw-light">Farmacología</li>
                <li className="fw-light">Terapias psicológicas</li>
                <li className="fw-light">Intervenciones de crisis y resolución de conflictos</li>
                <li className="fw-light">Manejo de la agitación y la agresividad</li>
                <li className="fw-light">Tratamiento de la depresión y el riesgo suicida</li>
                <li className="fw-light">Tratamiento del trastorno bipolar en fase aguda</li>
                <li className="fw-light">Tratamiento de los trastornos de ansiedad y pánico</li>
                <li className="fw-light">Tratamiento de los trastornos del estado de ánimo</li>
            </ul>

            <span>Módulo 4: Poblaciones especiales</span><br /><br />
            <ul>
                <li className="fw-light">Urgencias psiquiátricas en niños y adolescentes</li>
                <li className="fw-light">Urgencias psiquiátricas en adultos mayores</li>
                <li className="fw-light">Urgencias psiquiátricas en personas con discapacidad</li>
                <li className="fw-light">Urgencias psiquiátricas en personas con enfermedades médicas crónicas</li>
                <li className="fw-light">Urgencias psiquiátricas en personas con trastornos del desarrollo</li>
                <li className="fw-light">Urgencias psiquiátricas en personas con trastornos de la personalidad</li>
            </ul>

            <span>Módulo 5: Aspectos prácticos y casos clínicos. Aspectos legales</span><br /><br />
            <ul>
                <li className="fw-light">Evaluación y manejo de casos clínicos complejos y sus aspectos legales</li>
                <li className="fw-light">Discusión de casos clínicos y resolución de problemas con y sin implicancia legal</li>
                <li className="fw-light">Análisis de errores y complicaciones</li>
                <li className="fw-light">Coordinación con servicios de emergencia y hospitalización</li>
                <li className="fw-light">Comunicación efectiva con pacientes y familias</li>
                <li className="fw-light">Documentación y registro</li>
                <li className="fw-light">Revisión y evaluación final del programa</li>
            </ul>
        </div>
    )
}

export default TemarioPsiquiatriaClinica
