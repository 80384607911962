import React from 'react'

const TemarioPeelingDermatologos = () => {
    return (
        <div className='temario-container border w-100 p-5'>
            <span>MÓDULO 1</span>
            <br /><br />
            <ul>
                <li className='fw-normal'>Introducción patología dermatológica y tratamientos terapéuticos y estéticos más frecuentes.</li>
                <li className='fw-normal'>Estructura de la piel.</li>
                <li className='fw-normal'>Acné.</li>
                <li className='fw-normal'>Rosácea.</li>
            </ul>
            <span>MÓDULO 2 Workshop práctico online</span>
            <br /><br />
            <ul>
                <li className='fw-normal'>Pellings superficiales medios y profundos. ácidos.</li>
                <li className='fw-normal'>Criopeelings.</li>
                <li className='fw-normal'>Complicaciones.</li>
            </ul>
        </div>
    )
}

export default TemarioPeelingDermatologos