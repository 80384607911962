import React from 'react'
import { Link } from 'react-router-dom'
import gsap from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

const ServicioCard = ({img,titulo,descripcion,path}) => {

    return (
        <div className='col-sm-12 col-md-10 col-lg-5 col-xl-4 px-4 pb-5'>
            <div className='servicio-tecnologico rounded-4 py-5 d-flex flex-column align-items-center'>
                <img className='svg' src={img} alt={titulo} />
                <p className='px-3 text-center fs-5'>{titulo}</p>
                <p className='px-3 text-center fs-6'>{descripcion}</p>
                <button className='rounded-3'><Link to={path}>+ INFO</Link></button>
            </div>
        </div>
    )
}

export default ServicioCard