import React from 'react'

const TemarioTerapiaIntensivaPediatrica = () => {
    return (
        <div className='temario-container border w-100 p-5'>
            <span>UNIDAD 1: “Cuidados de Enfermería en Terapia Intensiva Pediátricas alteraciones Neurológicas"</span>
            <br /><br />
            <ul>
                <span>Temas:</span>
                <br /><br />
                <li className='fw-light'>Valoración del paciente crítico Alteraciones neurológicas</li>
                <li className='fw-light'>Monitoreo del paciente neuro crítico </li>
                <li className='fw-light'>Identificar plan de cuidados del paciente crítico con alteraciones neurológicas</li>
            </ul>   
            <ul>
                <span>Objetivos específicos:</span>
                <br /><br />
                <li className='fw-light'>Comprender el rol de Enfermería en el cuidado y recepción del paciente con trauma de cráneo </li>
                <li className='fw-light'>Identificar el rol de enfermería en la preparación de la unidad para una recepción organizada</li>
                <li className='fw-light'>Planificar cuidados de enfermería según la evaluación del niño ingresado en terapia intensiva con alteración neurológica</li>
                <li className='fw-light'>Valorar la importancia de la participación de la familia en la continuidad del cuidado en la adhesión del tratamiento</li>
            </ul>   
            <ul>
                <span>Contenido:</span>
                <br /><br />
                <li className='fw-light'>Trauma de cráneo, tratamiento y monitoreo </li>
                <li className='fw-light'>Poli trauma, manejo inicial, estabilización y posibles complicaciones </li>
                <li className='fw-light'>Cuidados de enfermería en pacientes neuro críticos </li>
                <li className='fw-light'>cuidados centrados en la familia</li>
            </ul>   
            <span>UNIDAD 2: “Cuidados en terapia intensiva Pediatría Valoración respiratoria”</span>
            <br /><br />
            <ul>
                <span>Temas:</span>
                <br /><br />
                <li className='fw-light'>Alteraciones respiratorias más frecuentes en pacientes críticos </li>
                <li className='fw-light'>Tratamiento soporte respiratorio </li>
                <li className='fw-light'>Planificar cuidados de enfermería en pacientes con tratamiento de soporte respiratorio</li>
            </ul>   
            <ul>
                <span>Objetivos </span>
                <br /><br />
                <li className='fw-light'>Identificar las alteraciones respiratorias del paciente crítico </li>
                <li className='fw-light'>Identificar y modalidad de uso de las terapias respiratorias en paciente crítico </li>
                <li className='fw-light'>Adquirir conocimientos de ventilación mecánica y modalidades.</li>
                <li className='fw-light'>Adquirir competencias en la atención de pacientes con asistencia respiratoria mecánica</li>
            </ul>   
            <ul>
                <span>Contenido</span>
                <br /><br />
                <li className='fw-light'>Valoración respiratoria CRÍA y secuencia de intubación rápida</li>
                <li className='fw-light'>Concepto de CAFO, CPAP y Alto Flujo cuidados de enfermería</li>
                <li className='fw-light'>Concepto de VM y VAFO  </li>
                <li className='fw-light'>Cuidados de enfermería en pacientes con ventilación no invasiva e invasiva</li>
            </ul> 
            <span>UNIDAD 3: “Cuidados en terapia intensiva Pediatría Valoración Cardiovascular”</span>
            <br /><br />
            <ul>
                <span>Temas:</span>
                <br /><br />
                <li className='fw-light'>Valoración hemodinámica, shock clasificación y tipos tratamiento, sepsis</li>
                <li className='fw-light'>Patología cardiovascular cianotizantes</li>
                <li className='fw-light'>Patología cardiovascular no cianosantes</li>
                <li className='fw-light'>Recepción del paciente post cardiovascular inmediato </li>
                <li className='fw-light'>Cuidados   enfermería en el post operatorio cardiovascular</li>
            </ul>   
            <ul>
                <span>Objetivos </span>
                <br /><br />
                <li className='fw-light'>Comprender el rol de Enfermería en el cuidado y recepción del paciente cardiovascular </li>
                <li className='fw-light'>Identificar las patologías cianotizantes más prevalentes y tratamiento</li>
                <li className='fw-light'>Identificar las patologías no cianosantes más prevalentes y tratamiento</li>
                <li className='fw-light'>Lograr realizar una valoración del paciente post quirúrgico cardiovascular y prioridad en la atención </li>
                <li className='fw-light'>identificar complicaciones mediatas y tardías en el postoperatorio cardiovascular</li>
            </ul>   
            <ul>
                <span>Contenido</span>
                <br /><br />
                <li className='fw-light'>Valoración del paciente post quirúrgico inmediato cardiovascular pediátrico.</li>
                <li className='fw-light'>Fisiopatología de las patologías cianotizantes y no cianosantes más prevalente y su tratamiento.</li>
                <li className='fw-light'>Intervenciones de enfermería en la recepción del paciente cardiovascular pediátrico</li>
                <li className='fw-light'>Identificar las complicaciones más frecuentes en post quirúrgico cardiovascular pediátrico.</li>
                <li className='fw-light'>Planificación de cuidados de enfermería para pacientes post quirúrgicos cardiovasculares</li>
            </ul> 
            <span>UNIDAD 4: “Cuidados en terapia intensiva Pediatría Valoración renal”</span>
            <br /><br />
            <ul>
                <span>Temas:</span>
                <br /><br />
                <li className='fw-light'>Valoración del paciente con alteraciones renales</li>
                <li className='fw-light'>Alteraciones renales más prevalentes </li>
                <li className='fw-light'>Tratamientos de sustitución renal </li>
                <li className='fw-light'>Cuidados de enfermería en pacientes con alteraciones renales</li>
            </ul>   
            <ul>
                <span>Objetivos </span>
                <br /><br />
                <li className='fw-light'>Comprender el rol de Enfermería en la recepción del paciente con alteraciones en su función renal. </li>
                <li className='fw-light'>Identificar las patologías más prevalentes renales en niño crítico </li>
                <li className='fw-light'>Identificar complicaciones del paciente con alteraciones renales</li>
            </ul>   
            <ul>
                <span>Contenido</span>
                <br /><br />
                <li className='fw-light'>Fisiopatología y Valoración del paciente con alteración renal </li>
                <li className='fw-light'>IRA Tratamiento, monitoreo, Cuidados de enfermería</li>
                <li className='fw-light'>IRC tratamiento; monitoreo, Cuidados de enfermería</li>
                <li className='fw-light'>Planificación de cuidados de enfermería para pacientes con alteraciones renales</li>
            </ul> 
        </div>
    )
}
/*
            <br />
            <span></span>
            <br /><br />
            <ul>
                <span></span>
                <br /><br />
                <li className='fw-light'></li>
                <li className='fw-light'></li>
                <li className='fw-light'></li>
            </ul>
*/

export default TemarioTerapiaIntensivaPediatrica