import React, { useEffect, useRef } from 'react'
import RegularNavbar from './Navbars/RegularNavbar'
import fondo from './multimedia/imagenes/pagina-error/Fondo.jpg'

import transicionEscalera from './Transiciones/transicionEscalera'
import transicionBottomTop from './Transiciones/transicionBottomTop'


const TerminosCondiciones = () => {
    const isMountedRef = useRef(false);
    useEffect(()=>{
        if (!isMountedRef.current) {
            window.scroll(0, 0);
            isMountedRef.current = true;
        }   
    },[])

    return (
        <main id='main-element'>
            <RegularNavbar />
            <div className="container-fluid terminos-condiciones-container d-flex align-items-center justify-content-center">
                <img className='w-100 h-100' src={fondo} alt="" />
                <div className="container-xxl">
                    <h1 className='poppins fs-title w-100 text-center my-5'>Política de privacidad</h1>
                    <div className='py-sm-3 mb-5 pt-0'>
                        <h3 className='fs-5'>Términos y Condiciones de Uso del Sitio Web</h3>
                        <p className='fs-5'>Los términos y condiciones de acceso y utilización de nuestro Sitio Web se detallan a continuación, el ingreso y su utilización implican la aceptación de los mismos. En consecuencia, Ud. deberá leer detenidamente estos términos y condiciones antes de acceder y/o utilizar cualquier servicio del Sitio Web bajo su entera responsabilidad.</p>
                        <p className='fs-5'>Fundación FISP se reserva el derecho de, en cualquier momento y sin previo aviso, modificar o eliminar estos términos y condiciones, el contenido, estructura, diseño, servicios y condiciones de acceso y/o uso de este Sitio Web, sin necesidad de previo consentimiento de los USUARIOS. Dichos cambios tendrán vigencia a partir del momento en que sean publicados, o desde el momento en que la institución lo indique expresamente sin que ello genere derecho a reclamo o indemnización alguna en favor de los usuarios. En consecuencia, el usuario debe leer atentamente los Términos y Condiciones de Uso cada vez que pretenda utilizar el Sitio Web.</p>
                        <p className='fs-5'>El ingreso y utilización de este Sitio Web se encuentra sujeto a las disposiciones aplicables de la legislación Argentina.</p>
                        <h3 className='fs-5'>Uso del sitio web</h3>
                        <p className='fs-5'>El Usuario se compromete a hacer uso de la información, contenidos y servicios a los que acceda desde este Sitio Web sin contravenir la legislación aplicable, la buena fe y el orden público, y las presentes Condiciones Generales de Uso. En consecuencia queda prohibido todo uso de este Sitio Web con fines ilícitos o que perjudiquen o impidan, puedan dañar y/o sobrecargar, de cualquier forma (incluida la introducción o difusión de “virus informáticos”), la utilización y normal funcionamiento del Sitio Web o directa o indirectamente atenten contra el mismo o contra cualquier derecho de un tercero.</p>
                        <h3 className='fs-5'>Limitaciones de responsabilidad</h3>
                        <p className='fs-5'>El usuario será el único responsable de la veracidad y la actualización de la información y los datos que facilite a FISP, garantizando en todo momento la veracidad y exactitud de los mismos.</p>
                        <p className='fs-5'>FISP no asume ningún deber o compromiso de verificar la información recibida de sus usuarios.</p>
                        <p className='fs-5'>El acceso al Sitio Web y cualquier uso de la información contenida en el mismo es exclusiva responsabilidad de quien los realiza. El usuario es el responsable de tomar las precauciones necesarias para comprobar la no existencia de virus u otro tipo de programas que tengan una naturaleza dañina. Fundación FISP no se responsabiliza por las posibles consecuencias, daños o perjuicios que puedan derivarse por dicho acceso o uso de información. Fundación FISP no se responsabiliza por daños ocasionados por virus, programas maliciosos o lesivos en los contenidos y/o cualquier otro agente que pueda llegar a infectar o afectar de cualquier modo el o los sistemas de computación utilizados por el usuario. Asimismo Fundación FISP no se responsabiliza por la falta de disponibilidad, continuidad, acceso, mantenimiento y efectivo funcionamiento del Sitio Web y/o de sus servicios y/o la actualización, exactitud, exhaustividad, pertinencia, actualidad y fiabilidad de sus contenidos, así como a errores u omisiones de los que pudieran u otros a los que se pueda acceder por medio del mismo, cualquiera sea la causa, las dificultades o problemas técnicos o de otra naturaleza en los que originen estos hechos; de igual manera no se responsabiliza por la recepción, obtención almacenamiento, difusión y/o transmisión, por parte de los usuarios de los contenidos del Sitio Web. La enumeración precedente posee carácter enunciativo y en ningún caso exclusivo ni excluyente.</p>
                        <h3 className='fs-5'>Derechos de propiedad intelectual</h3>
                        <p className='fs-5'>Este Sitio Web se rige por las leyes argentinas y está protegido por la legislación nacional e internacional sobre propiedad intelectual e industrial. Todos los derechos de propiedad intelectual e industrial sobre este Sitio Web están legalmente reservados.</p>
                        <p className='fs-5'>Los derechos de propiedad intelectual y cualquier otro derecho sobre el material contenido, denominaciones, marcas, logos y diseños gráficos de los productos y servicios que aparecen en este Sitio Web, pertenecen a Fundación FISP o a terceras personas que la han autorizado a su publicación y/o utilización y no podrán ser utilizados por persona distinta de su titular sin la previa y expresa autorización de Fundación FISP.</p>
                        <p className='fs-5'>El acceso o la utilización del mismo, no constituirá, en ningún caso, el otorgamiento, salvo que se indique expresamente lo contrario, de ninguna licencia de uso o derecho sobre cualquier activo cuya titularidad o propiedad corresponda a Fundacion FISP. Queda prohibida, salvo en aquellos casos en los que se haya obtenido previamente la autorización expresa de Fundacion FISP, la reproducción, copia, distribución, transformación, puesta a disposición del público, supresión, manipulación de los sistemas de seguridad instalados en la misma, y cualquier otra actividad que se pueda realizar con el contenido, la información y las marcas que aparecen en este Sitio Web, así como con su código fuente, imágenes, diseño, selección y forma de presentación de los materiales incluidos en la misma, cualquiera que fuera su finalidad y el medio utilizado para ello.</p>
                        <h3 className='fs-5'>Enlaces a otras páginas web</h3>
                        <p className='fs-5'>Este Sitio Web puede contener enlaces o links a otros sitios y páginas web que son gestionadas por terceros sobre los que Fundación FISP no ejerce ningún tipo de control. FISP no gestiona ni avala estos sitios por lo que no nos responsabilizamos de los contenidos de los Sitios Web Enlazados o de los Enlaces contenidos en los mismos. No nos hacemos responsables de los contenidos e informaciones incluidos en otros sitios web, así como de las condiciones de uso publicadas en los mismos. No podrá interpretarse que las mismas sean una prolongación de nuestro Sitio Web o que compartamos las opiniones expresadas en ellas o que tengamos algún interés o colaboración en los servicios proporcionados o comercializados en las mismas.</p>
                        <p className='fs-5'>FISP suprimirá, a la mayor brevedad posible, cualquier enlace a Sitios Web cuyo contenido sea contrario a la ley, a la dignidad personal y que pudiera causar daño, perjuicio o inducir a error a los Usuarios.</p>
                        <h3 className='fs-5'>Protección datos personales</h3>
                        <p className='fs-5'>Fundación FISP se encuentra comprometida con la protección de la privacidad y confidencialidad de la información personal de los usuarios de nuestro sitio web. En virtud de ello es que realizará su mejor esfuerzo y el que considere necesario para que los mismos se encuentren protegidos, entendiendo que el uso o visita al mismo se efectuará sin ninguna intención maliciosa o dolo.</p>
                        <p className='fs-5'>Ingresando su información personal mediante cualquier canal dentro del Sitio, presta su consentimiento para que dicha información sea almacenada directamente en una BASE DE DATOS de propiedad de la Fundación FISP.</p>
                        <p className='fs-5'>El Usuario garantiza que los Datos Personales facilitados a La Fundación FISP son veraces y se hace responsable de comunicar a ésta cualquier modificación en los mismos. Se deja expresamente aclarado que ciertos Servicios prestados por éste sitio u otros sitios vinculados pueden contener Condiciones Particulares con previsiones específicas en materia de protección de Datos Personales Fundación FISP puede utilizar la información recibida para enviar al usuario información sobre la Institución. Los usuarios podrán optar, si así lo desean, por no recibir envíos en el futuro, solicitándolo expresamente por carta o por mail a <a href="mailto:info@fisp.org.ar">info@fisp.org.ar</a>.</p>
                        <p className='fs-5'>La presente Política de Privacidad se aplica exclusivamente a la información ofrecida y recibida por los usuarios del Sitio.</p>
                        <p className='fs-5'>Fundación FISP no se responsabiliza por el actuar de los sitios web a los cuales se puede acceder por medio del Sitio, por lo que recomendamos la lectura de la política de privacidad de cada uno ellos.</p>
                        <p className='fs-5'>Todas las comunicaciones privadas entre Fundación FISP y los titulares de los datos/usuarios serán consideradas como confidenciales. Además, también tendrá la condición de confidencial la información de cualquier tipo que intercambien ambas partes. Recomendamos al titular de los datos que no facilite a tercero alguno su identificación de titular de los datos, contraseña o números de referencia que, en su caso, le sean proporcionados por Fundación FISP.</p>
                        <p className='fs-5'>El Titular de los Datos Personales tiene la facultad de ejercer el derecho de acceso a los mismos en forma gratuita, acreditando debidamente su identidad a tal efecto, mediante carta documento dirigida Fundación FISP a su domicilio y/o mediante requerimiento por escrito efectuado al correo electrónico <a href="mailto:fundacion_info@fisp.org.ar">fundacion_info@fisp.org.ar</a>, a intervalos no inferiores a seis meses salvo que se acredite un interés legítimo al efecto conforme lo establecido en el artículo 14, inciso 3 de la Ley Nº 25.326.</p>
                        <p className='fs-5'>La Dirección Nacional de Protección de Datos Personales, Órgano de Control de la Ley Nº 25.326, tiene la atribución de atender las denuncias y reclamos que se interpongan con relación al incumplimiento de las normas sobre protección de datos personales. Para contactar a la Dirección Nacional de Protección de Datos Personales: Dirección: Av. Pte. Gral. Julio A. Roca 710, piso 3 – Ciudad Autónoma de Buenos Aires, Teléfono: (54-11) 3988-3968, Correo electrónico: <a href="mailto:datospersonales@aaip.gob.ar">datospersonales@aaip.gob.ar</a>.</p>
                        <h3 className='fs-5'>Uso de cookies</h3>
                        <p className='fs-5'>El sitio web utiliza una tecnología de identificación basada en “cookies”, la que resulta necesaria para utilizar los servicios y contenidos que se brindan. Las “cookies” son pequeños ficheros de texto que son enviados vía Internet a su navegador y que quedan almacenados en el disco duro de su computadora, lo que permite reconocer las máquinas que acceden al sitio web. En ningún caso esos elementos servirán para identificar a una persona determinada, ya que su uso será meramente técnico; sin embargo, son útiles para ofrecer un mejor servicio a los USUARIOS y permitir la realización de contenidos avanzados.</p>
                        <h3 className='fs-5'>Legislación aplicable y jurisdicción</h3>
                        <p className='fs-5'>Los Términos y Condiciones de Uso se rigen por las leyes de la República Argentina. En caso de surgir cualquier controversia respecto de la interpretación o cumplimiento de los presentes, la Fundación FISP y el Usuario se someten a los tribunales nacionales ordinarios con sede en la Ciudad Autónoma de Buenos Aires. En caso que Ud. tenga preguntas, comentarios y/o sugerencias respecto de este Sitio Web, lo invitamos a contactarse con nosotros: CERRITO 1050 • PISO 9 CABA, BUENOS AIRES • C1010 • <a href="mailto:info@fisp.org.ar">info@fisp.org.ar</a> • 011-5252-2597.</p>
                        <div className="py-5"></div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default window.innerWidth >= 1000 ? transicionEscalera(TerminosCondiciones) : transicionBottomTop(TerminosCondiciones);
