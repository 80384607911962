import React, { useEffect, useRef, useState } from 'react';
import ImagenMain from '../../multimedia/imagenes/produccion-marketing/consultoria-y-asesoria.jpg'
import RegularNavbar from '../../Navbars/RegularNavbar';
import SideNav from '../../Navbars/SideNav';
import transicionBottomTop from '../../Transiciones/transicionBottomTop';
import { Link } from 'react-router-dom';

const ConsultoriaAsesoria = () => {
    const isMountedRef = useRef(false);

    const iconoServicios = ()=>{
        return (
            <svg className='icono' width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M4 4m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" />
                <path d="M4 14m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" />
                <path d="M14 14m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" />
                <path d="M14 7l6 0" />
                <path d="M17 4l0 6" />
            </svg>
        )
    }
    const iconoAnalisis = ()=>{
        return (
            <svg className='icono' width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M4 19l16 0" />
                <path d="M4 15l4 -6l4 2l4 -5l4 4" />
            </svg>
        )
    }
    const iconoMercado = ()=>{
        return (
            <svg className='icono' width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M3 21l18 0" />
                <path d="M3 7v1a3 3 0 0 0 6 0v-1m0 1a3 3 0 0 0 6 0v-1m0 1a3 3 0 0 0 6 0v-1h-18l2 -4h14l2 4" />
                <path d="M5 21l0 -10.15" />
                <path d="M19 21l0 -10.15" />
                <path d="M9 21v-4a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v4" />
            </svg>
        )
    }
    const iconoEstrategias = ()=>{
        return (
            <svg className='icono' width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z" />
                <path d="M12 4v2.5a.5 .5 0 0 1 -.5 .5a1.5 1.5 0 0 0 0 3a.5 .5 0 0 1 .5 .5v1.5" />
                <path d="M12 12v1.5a.5 .5 0 0 0 .5 .5a1.5 1.5 0 0 1 0 3a.5 .5 0 0 0 -.5 .5v2.5" />
                <path d="M20 12h-2.5a.5 .5 0 0 1 -.5 -.5a1.5 1.5 0 0 0 -3 0a.5 .5 0 0 1 -.5 .5h-1.5" />
                <path d="M12 12h-1.5a.5 .5 0 0 0 -.5 .5a1.5 1.5 0 0 1 -3 0a.5 .5 0 0 0 -.5 -.5h-2.5" />
            </svg>
        )
    }
    const iconoSegmentación = ()=>{
        return (
            <svg className='icono' width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M12 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
                <path d="M12 7a5 5 0 1 0 5 5" />
                <path d="M13 3.055a9 9 0 1 0 7.941 7.945" />
                <path d="M15 6v3h3l3 -3h-3v-3z" />
                <path d="M15 9l-3 3" />
            </svg>
        )
    }
    const iconoPlanificación = ()=>{
        return (
            <svg className='icono' width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M13 5h8" />
                <path d="M13 9h5" />
                <path d="M13 15h8" />
                <path d="M13 19h5" />
                <path d="M3 4m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" />
                <path d="M3 14m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" />
            </svg>
        )
    }
    const iconoSEO = ()=>{
        return (
            <svg className='icono' width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M4 10a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
                <path d="M6 4v4" />
                <path d="M6 12v8" />
                <path d="M12 14a2 2 0 0 0 -1.042 3.707" />
                <path d="M12 4v10" />
                <path d="M16 7a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
                <path d="M18 4v1" />
                <path d="M18 9v2" />
                <path d="M18 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                <path d="M20.2 20.2l1.8 1.8" />
            </svg>
        )
    }
    const iconoRedes = ()=>{
        return(
            <svg className='icono' width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M12 5m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                <path d="M5 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                <path d="M19 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                <path d="M12 14m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                <path d="M12 7l0 4" />
                <path d="M6.7 17.8l2.8 -2" />
                <path d="M17.3 17.8l-2.8 -2" />
            </svg>
        )
    }
    const iconoPublicidad = ()=>{
        return(
            <svg className='icono' width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M19 6m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                <path d="M19 18m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                <path d="M5 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                <path d="M7 12h3l3.5 6h3.5" />
                <path d="M17 6h-3.5l-3.5 6" />
            </svg>
        )
    }
    const iconoEmail = ()=>{
        return(
            <svg className='icono' width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z" />
                <path d="M3 7l9 6l9 -6" />
            </svg>
        )
    }
    const iconoDatos = ()=>{
        return (
            <svg className='icono' width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M20 11a8.1 8.1 0 0 0 -6.986 -6.918a8.095 8.095 0 0 0 -8.019 3.918" />
                <path d="M4 13a8.1 8.1 0 0 0 15 3" />
                <path d="M19 16m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
                <path d="M5 8m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
                <path d="M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
            </svg>
        )
    }
    const iconoDiseño = ()=>{
        return(
            <svg className='icono' width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M6 21l15 -15l-3 -3l-15 15l3 3" />
                <path d="M15 6l3 3" />
                <path d="M9 3a2 2 0 0 0 2 2a2 2 0 0 0 -2 2a2 2 0 0 0 -2 -2a2 2 0 0 0 2 -2" />
                <path d="M19 13a2 2 0 0 0 2 2a2 2 0 0 0 -2 2a2 2 0 0 0 -2 -2a2 2 0 0 0 2 -2" />
            </svg>
        )
    }
    const iconoVentas = ()=>{
        return (
            <svg className='icono' width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M4 7l.867 12.143a2 2 0 0 0 2 1.857h10.276a2 2 0 0 0 2 -1.857l.867 -12.143h-16z" />
                <path d="M8.5 7c0 -1.653 1.5 -4 3.5 -4s3.5 2.347 3.5 4" />
                <path d="M9.5 17c.413 .462 1 1 2.5 1s2.5 -.897 2.5 -2s-1 -1.5 -2.5 -2s-2 -1.47 -2 -2c0 -1.104 1 -2 2 -2s1.5 0 2.5 1" />
            </svg>
        )
    }
    const iconoUX = ()=>{
        return(
            <svg className='icono' width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                <path d="M7 10v2a2 2 0 1 0 4 0v-2" />
                <path d="M14 10l3 4" />
                <path d="M14 14l3 -4" />
            </svg>
        )
    }
    const iconoCapacitacion = ()=>{
        return(
            <svg className='icono' width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M22 9l-10 -4l-10 4l10 4l10 -4v6" />
                <path d="M6 10.6v5.4a6 3 0 0 0 12 0v-5.4" />
            </svg>
        )
    }
    const iconoCompetencia = ()=>{
        return(
            <svg className='icono' width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M12 9m-6 0a6 6 0 1 0 12 0a6 6 0 1 0 -12 0" />
                <path d="M12 15l3.4 5.89l1.598 -3.233l3.598 .232l-3.4 -5.889" />
                <path d="M6.802 12l-3.4 5.89l3.598 -.233l1.598 3.232l3.4 -5.889" />
            </svg>  
        )
    }
    const iconoRelaciones = ()=>{
        return(
            <svg className='icono' width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M9.183 6.117a6 6 0 1 0 4.511 3.986" />
                <path d="M14.813 17.883a6 6 0 1 0 -4.496 -3.954" />
            </svg>
        )
    }
    const iconoMonitoreo = ()=>{
        return(
            <svg className='icono' width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M3 4m0 1a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v10a1 1 0 0 1 -1 1h-16a1 1 0 0 1 -1 -1z" />
                <path d="M7 20h10" />
                <path d="M9 16v4" />
                <path d="M15 16v4" />
                <path d="M9 12v-4" />
                <path d="M12 12v-1" />
                <path d="M15 12v-2" />
                <path d="M12 12v-1" />
            </svg>
        )
    }
    const [serviceContent,setServiceContent] = useState({
        titulo: "Nuestros servicios",
        descripcion: "A través de asesoría especializada, impulsamos estrategias y éxitos en marketing empresarial.",
        icono: iconoServicios()
    })
    const [serviceDescriptionElement,setServiceDescription] = useState()
    const items = [
        [
            {
                titulo: "Análisis y Diagnóstico",
                descripcion: "Evaluar la situación actual de marketing de la empresa, incluyendo la revisión de estrategias anteriores, resultados y recursos disponibles.",
                icono: iconoAnalisis()
            },{
                titulo: "Investigación de Mercado",
                descripcion: "Realizar estudios para comprender el mercado, la competencia, las tendencias y las necesidades de la audiencia.",
                icono: iconoMercado()
            },{
                titulo: "Desarrollo de Estrategias",
                descripcion: "Crear planes estratégicos de marketing que aborden objetivos específicos y se alineen con la visión de la empresa.",
                icono: iconoEstrategias()
            },{
                titulo: "Segmentación y Posicionamiento",
                descripcion: "Identificar segmentos de mercado clave y desarrollar estrategias de posicionamiento para destacar la oferta de la empresa.",
                icono: iconoSegmentación()
            }
        ],[
            {
                titulo: "SEO y Optimización de Contenido",
                descripcion: "Realizar análisis de palabras clave, optimizar el contenido y mejorar la visibilidad en los motores de búsqueda.",
                icono: iconoSEO()
            },{
                titulo: "Gestión de Redes Sociales",
                descripcion: "Planificar y programar contenido para las redes sociales, responder a los comentarios y medir la efectividad de las interacciones.",
                icono: iconoRedes()
            },{
                titulo: "Publicidad en Línea (SEM y PPC)",
                descripcion: "Diseñar y ejecutar campañas de anuncios en línea, gestionar presupuestos y realizar un seguimiento de los resultados.",
                icono: iconoPublicidad()
            },{
                titulo: "Email Marketing",
                descripcion: "Crear estrategias de email marketing, desarrollar contenido de campañas y segmentar listas de suscriptores.",
                icono: iconoEmail()
            }
        ],[
            {
                titulo: "Monitoreo de Tendencias",
                descripcion: "Mantenerse al tanto de las últimas tendencias y novedades en marketing para adaptar las estrategias según sea necesario.",
                icono: iconoMonitoreo()
            },{
                titulo: "Análisis de Datos y Métricas",
                descripcion: "Monitorear y analizar métricas clave, como tasas de conversión, tráfico de sitio web y participación en redes sociales.",
                icono: iconoDatos()
            },{
                titulo: "Diseño de Materiales Promocionales",
                descripcion: "Colaborar en el diseño de materiales visuales, como gráficos para redes sociales, banners y anuncios.",
                icono: iconoDiseño()
            },{
                titulo: "Planificación de Contenido",
                descripcion: "Crear estrategias de contenido que incluyan temas, formatos y calendarios para mantener a la audiencia comprometida.",
                icono: iconoPlanificación()
            }
        ]
    ]
    const changeContent = (item)=>{
        setServiceContent(item)
        serviceDescriptionElement.scrollIntoView()
    }

    useEffect(() => {
        if (!isMountedRef.current) {
            window.scroll(0, 0);
            isMountedRef.current = true;
            const serviceDescription = document.querySelector('.servicio-description')
            setServiceDescription(serviceDescription)
        }
    }, []);

    return (
        <>
            <main className='pym-detalle' id="main-element">
                <SideNav />
                <div className="servicio-main-image container-fluid px-0">
                    <img className='w-100' src={ImagenMain} alt="Servicios de Consultoria y Asesoria" id="rcp"/>
                </div>
                <div className="container-servicio-content pt-0 my-5 px-2 px-sm-5">
                    <div className="container-xxl">
                        <div>
                            <h1 className='poppins'>Servicios de consultoría y asesoría </h1>
                        </div>
                        <p className='parrafo-servicio fs-5 pb-2 mt-5'>La consultoría en marketing involucra una variedad de tareas diseñadas para ayudar a las empresas a desarrollar estrategias efectivas, implementar acciones y lograr sus objetivos de marketing.</p>
                        <p className='parrafo-servicio fs-5 pb-2'>Estas tareas pueden variar según las necesidades específicas de cada cliente y la naturaleza del proyecto. Aquí hay algunas tareas comunes que los consultores de marketing pueden llevar a cabo:</p>
                    </div>
                    <div className="container-fluid m-0 p-0">
                        <div className="row gap-5 mx-0 pb-5 justify-content-center py-5 section-big position-relative">
                            <div className="col-10 col-lg-4 servicio-description fh-500 p-3 p-sm-5 p-md-3 p-xl-5 bg-light box-shadow-medium mt-5 rounded-4">
                                {serviceContent.icono}
                                <h2 className='text-center poppins fs-3'>{serviceContent.titulo}</h2>
                                <p className='text-center fs-5'>{serviceContent.descripcion}</p>
                                <Link to="/servicios/contacto/">
                                    <button className='py-3 my-0 text-white text-shadow d-flex align-items-center justify-content-center rounded-4'>
                                        CONTACTANOS
                                        <svg width="28" height="28" viewBox="0 0 24 24" strokeWidth="3.5" stroke="#FFFFFF" fill="none">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <path d="M9 6l6 6l-6 6" />
                                        </svg>
                                    </button>
                                </Link>
                            </div>
                            <div className="col-lg-6 col-10 row gap-3 gap-sm-4">
                                <div className="col-12 col-sm service-card-items p-0 d-flex flex-column gap-4">
                                    {
                                        items[0].map((item,index)=>{
                                            return (
                                                <div key={index} className="item-card text-white fs-5 p-2 rounded-4 box-shadow-medium" onMouseOver={()=>{changeContent(item)}}>
                                                    {item.icono}
                                                    {item.titulo}
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div className="col-12 col-sm service-card-items p-0 d-flex flex-column gap-4">
                                    {
                                        items[1].map((item,index)=>{
                                            return (
                                                <div key={index} className="item-card text-white fs-5 p-2 rounded-4 box-shadow-medium" onMouseOver={()=>{changeContent(item)}}>
                                                    {item.icono}
                                                    {item.titulo}
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div className="col-12 col-sm service-card-items p-0 d-flex flex-column gap-4">
                                    {
                                        items[2].map((item,index)=>{
                                            return (
                                                <div key={index} className="item-card text-white fs-5 p-2 rounded-4 box-shadow-medium" onMouseOver={()=>{changeContent(item)}}>
                                                    {item.icono}
                                                    {item.titulo}
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <RegularNavbar />
            </main>
        </>
    )
}

export default transicionBottomTop(ConsultoriaAsesoria);