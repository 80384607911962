import React, { useEffect, useRef, useState } from 'react';
import ImagenMain from '../../multimedia/imagenes/produccion-marketing/email-whatsapp/email-wpp.jpg'
import RegularNavbar from '../../Navbars/RegularNavbar';
import SideNav from '../../Navbars/SideNav';
import { Link } from 'react-router-dom';
import transicionBottomTop from '../../Transiciones/transicionBottomTop';
import backstage from '../../multimedia/imagenes/produccion-marketing/mundo-digital/backstage.jpg'
import calendario from '../../multimedia/imagenes/produccion-marketing/mundo-digital/calendario.avif'
import contenido from '../../multimedia/imagenes/produccion-marketing/mundo-digital/contenido.avif'
import enlaces from '../../multimedia/imagenes/produccion-marketing/mundo-digital/enlaces.jpeg'
import FAQ from '../../multimedia/imagenes/produccion-marketing/mundo-digital/FAQ.png'
import historias from '../../multimedia/imagenes/produccion-marketing/mundo-digital/historias.jpg'
import imagenes from '../../multimedia/imagenes/produccion-marketing/mundo-digital/imagenes.jpg'
import infografia from '../../multimedia/imagenes/produccion-marketing/mundo-digital/infografia.jpg'
import listas from '../../multimedia/imagenes/produccion-marketing/mundo-digital/listas.jpg'
import ofertas from '../../multimedia/imagenes/produccion-marketing/mundo-digital/ofertas.avif'
import publicaciones from '../../multimedia/imagenes/produccion-marketing/mundo-digital/publicaciones.webp'
import reseñas from '../../multimedia/imagenes/produccion-marketing/mundo-digital/reseñas.png'
import sorteos from '../../multimedia/imagenes/produccion-marketing/mundo-digital/sorteos.webp'
import testimonios from '../../multimedia/imagenes/produccion-marketing/mundo-digital/testimonios.png'
import tutoriales from '../../multimedia/imagenes/produccion-marketing/mundo-digital/tutoriales.avif'
import videos from '../../multimedia/imagenes/produccion-marketing/mundo-digital/videos.jpg'
import inspiracion from '../../multimedia/imagenes/produccion-marketing/mundo-digital/inspiracion.webp'



const ContenidoMundoDigital = () => {
    const isMountedRef = useRef(false);

    useEffect(() => {
        if (!isMountedRef.current) {
            window.scroll(0, 0);
            isMountedRef.current = true;
        }
    }, []);

    return (
        <>
            <main className='pym-detalle' id="main-element">
                <SideNav />
                <div className="servicio-main-image container-fluid px-0">
                    <img className='w-100 box-shadow' src={ImagenMain} alt="Servicios de consultoría y asesoría" />
                </div>
                <div className="container-servicio-content pt-0 pb-0 my-5">
                    <div className="container-xxl pb-5">
                        <div>
                            <h1 className='poppins'>Generación de contenido para el mundo digital</h1>
                        </div>
                        <p className='parrafo-servicio fs-5 pb-2 mt-5'>La consultoría en marketing involucra una variedad de tareas diseñadas para ayudar a las empresas a desarrollar estrategias efectivas, implementar acciones y lograr sus objetivos de marketing. Estas tareas pueden variar según las necesidades específicas de cada cliente y la naturaleza del proyecto. Aquí hay algunas tareas comunes que los consultores de marketing pueden llevar a cabo:</p>
                        <div className="d-flex gap-5 justify-content-center flex-wrap">
                            <div className="col-md-5 col-12 rounded-4 box-shadow pym-bg-1">
                                <img className='w-100' src={publicaciones} alt="Publicaciones de Texto" />
                                <div className="p-4">
                                    <h3 className="fw-bold fs-5 pb-2">Publicaciones de Texto:</h3>
                                    <p className='parrafo-servicio'>Actualizaciones de estado, preguntas, encuestas y reflexiones breves.</p>
                                </div>
                            </div>
                            <div className="col-md-5 col-12 rounded-4 box-shadow pym-bg-2">
                                <img className='w-100' src={imagenes} alt="Imágenes y Gráficos" />
                                <div className="p-4">
                                    <h3 className="fw-bold fs-5 pb-2">Imágenes y Gráficos:</h3>
                                    <p className='parrafo-servicio'>Fotos de productos, infografías, citas inspiradoras, memes relevantes, gráficos informativos, etc.</p>
                                </div>
                            </div>
                            <div className="col-md-5 col-12 rounded-4 box-shadow pym-bg-1">
                                <img className='w-100' src={videos} alt="Videos" />
                                <div className="p-4">
                                    <h3 className="fw-bold fs-5 pb-2">Videos:</h3>
                                    <p className='parrafo-servicio'>Videos cortos, como clips promocionales, tutoriales, entrevistas, detrás de escena y videos en vivo (live streaming).</p>
                                </div>
                            </div>
                            <div className="col-md-5 col-12 rounded-4 box-shadow pym-bg-2">
                                <img className='w-100' src={reseñas} alt="Contenido Generado por Usuarios (UGC)" />
                                <div className="p-4">
                                    <h3 className="fw-bold fs-5 pb-2">Contenido Generado por Usuarios (UGC):</h3>
                                    <p className='parrafo-servicio'>Compartir fotos, reseñas y testimonios de tus clientes satisfechos.</p>
                                </div>
                            </div>
                            <div className="col-md-5 col-12 rounded-4 box-shadow pym-bg-1">
                                <img className='w-100' src={historias} alt="Historias" />
                                <div className="p-4">
                                    <h3 className="fw-bold fs-5 pb-2">Historias:</h3>
                                    <p className='parrafo-servicio'>Historias efímeras que desaparecen después de un tiempo, disponibles en plataformas como Instagram, Facebook y Snapchat.</p>
                                </div>
                            </div>
                            <div className="col-md-5 col-12 rounded-4 box-shadow pym-bg-2">
                                <img className='w-100' src={enlaces} alt="Enlaces a Contenido Externo" />
                                <div className="p-4">
                                    <h3 className="fw-bold fs-5 pb-2">Enlaces a Contenido Externo:</h3>
                                    <p className='parrafo-servicio'>Compartir enlaces a artículos, blogs, videos y otros recursos relevantes de terceros.</p>
                                </div>
                            </div>
                            <div className="col-md-5 col-12 rounded-4 box-shadow pym-bg-1">
                                <img className='w-100' src={listas} alt="Listas y Guías" />
                                <div className="p-4">
                                    <h3 className="fw-bold fs-5 pb-2">Listas y Guías:</h3>
                                    <p className='parrafo-servicio'>Publicaciones que presentan información en forma de listas, consejos o guías prácticas.</p>
                                </div>
                            </div>
                            <div className="col-md-5 col-12 rounded-4 box-shadow pym-bg-2">
                                <img className='w-100' src={tutoriales} alt="Tutoriales y Educación" />
                                <div className="p-4">
                                    <h3 className="fw-bold fs-5 pb-2">Tutoriales y Educación:</h3>
                                    <p className='parrafo-servicio'>Videos o publicaciones que enseñan a los seguidores a hacer algo, como usar tus productos o realizar una actividad específica.</p>
                                </div>
                            </div>
                            <div className="col-md-5 col-12 rounded-4 box-shadow pym-bg-1">
                                <img className='w-100' src={backstage} alt="Detrás de Escena" />
                                <div className="p-4">
                                    <h3 className="fw-bold fs-5 pb-2">Detrás de Escena:</h3>
                                    <p className='parrafo-servicio'>Mostrar cómo funciona tu negocio, el proceso de fabricación o cómo tu equipo trabaja para dar vida a tus productos o servicios.</p>
                                </div>
                            </div>
                            <div className="col-md-5 col-12 rounded-4 box-shadow pym-bg-2">
                                <img className='w-100' src={sorteos} alt="Concursos y Sorteos" />
                                <div className="p-4">
                                    <h3 className="fw-bold fs-5 pb-2">Concursos y Sorteos:</h3>
                                    <p className='parrafo-servicio'>Crear interacción invitando a tus seguidores a participar en un concurso o sorteo, con premios relacionados con tu marca.</p>
                                </div>
                            </div>
                            <div className="col-md-5 col-12 rounded-4 box-shadow pym-bg-1">
                                <img className='w-100' src={testimonios} alt="Testimonios y Casos de Éxito" />
                                <div className="p-4">
                                    <h3 className="fw-bold fs-5 pb-2">Testimonios y Casos de Éxito:</h3>
                                    <p className='parrafo-servicio'>Compartir historias de clientes satisfechos y cómo tu producto o servicio les ha beneficiado.</p>
                                </div>
                            </div>
                            <div className="col-md-5 col-12 rounded-4 box-shadow pym-bg-2">
                                <img className='w-100' src={calendario} alt="Fechas Importantes y Eventos" />
                                <div className="p-4">
                                    <h3 className="fw-bold fs-5 pb-2">Fechas Importantes y Eventos:</h3>
                                    <p className='parrafo-servicio'>Reconocer días festivos, celebraciones o eventos relevantes en tu industria.</p>
                                </div>
                            </div>
                            <div className="col-md-5 col-12 rounded-4 box-shadow pym-bg-1">
                                <img className='w-100' src={FAQ} alt="Preguntas Frecuentes (FAQs)" />
                                <div className="p-4">
                                    <h3 className="fw-bold fs-5 pb-2">Preguntas Frecuentes (FAQs):</h3>
                                    <p className='parrafo-servicio'>Responder a preguntas frecuentes o inquietudes de tus seguidores.</p>
                                </div>
                            </div>
                            <div className="col-md-5 col-12 rounded-4 box-shadow pym-bg-2">
                                <img className='w-100' src={contenido} alt="Contenido Divertido y Entretenido" />
                                <div className="p-4">
                                    <h3 className="fw-bold fs-5 pb-2">Contenido Divertido y Entretenido:</h3>
                                    <p className='parrafo-servicio'>Publicaciones humorísticas, juegos, acertijos o contenido entretenido relacionado con tu nicho.</p>
                                </div>
                            </div>
                            <div className="col-md-5 col-12 rounded-4 box-shadow pym-bg-1">
                                <img className='w-100' src={infografia} alt="Infografías" />
                                <div className="p-4">
                                    <h3 className="fw-bold fs-5 pb-2">Infografías:</h3>
                                    <p className='parrafo-servicio'>Gráficos visuales que presentan información compleja de manera fácil de entender.</p>
                                </div>
                            </div>
                            <div className="col-md-5 col-12 rounded-4 box-shadow pym-bg-2">
                                <img className='w-100' src={ofertas} alt="Anuncios y Ofertas Especiales" />
                                <div className="p-4">
                                    <h3 className="fw-bold fs-5 pb-2">Anuncios y Ofertas Especiales:</h3>
                                    <p className='parrafo-servicio'>Promocionar descuentos, ofertas y promociones especiales para tus seguidores.</p>
                                </div>
                            </div>
                            <div className="col-md-5 col-12 rounded-4 box-shadow pym-bg-1">
                                <img className='w-100' src={inspiracion} alt="Inspiración y Motivación" />
                                <div className="p-4">
                                    <h3 className="fw-bold fs-5 pb-2">Inspiración y Motivación:</h3>
                                    <p className='parrafo-servicio'>Compartir contenido que inspire y motive a tus seguidores, como citas inspiradoras y logros personales.</p>
                                </div>
                            </div>
                        </div>
                        <button className='py-3 mt-5 text-white text-shadow d-flex align-items-center justify-content-center rounded-4'>
                            CONTACTANOS
                            <svg width="28" height="28" viewBox="0 0 24 24" strokeWidth="3.5" stroke="#FFFFFF" fill="none">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <path d="M9 6l6 6l-6 6" />
                            </svg>
                        </button>
                        <div     className="py-5">

                        </div>
                        
                    </div>
                    
                </div>
                <RegularNavbar />
            </main>
        </>
    )
}

export default transicionBottomTop(ContenidoMundoDigital);