import React from 'react';
import { motion } from 'framer-motion';
import Logo from '../multimedia/imagenes/LOGO.png'

const transicionBottomTop = (ComponenteOriginal) => {
    const TransicionComponent = () => {
        const mobileScreen = ()=>{
            return (
                <>
                    <ComponenteOriginal />
                    <motion.div 
                        className='slide-in'
                        initial={{scaleY:0,pointerEvents:'all'}}
                        animate={{scaleY:0,pointerEvents:'all'}}
                        exit={{scaleY:1,pointerEvents:'all'}}
                        transition={{ duration:1.2, ease: [0.22, 1, 0.36, 1]}}
                    >
                        <img src={Logo} alt="Logo FISP" />
                    </motion.div>
                    <motion.div
                        className='slide-out'
                        initial={{ scaleY: 1, pointerEvents:'none'}}
                        animate={{ scaleY: 0, pointerEvents:'none'}}
                        exit={{ scaleY: 0, pointerEvents:'none'}}
                        transition={{ duration: 1.2, ease: [0.22, 1, 0.36, 1] }}
                    >
                        <img src={Logo} alt="Logo FISP" />
                    </motion.div>
                </>
            );
        }
        return mobileScreen();
    };
    return TransicionComponent;
};

export default transicionBottomTop;