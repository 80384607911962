import React from 'react'

const TemarioIAMedica = () => {
    return (
        <div className='temario-container border w-100 p-5'>
            <span>Módulo 1: Historia de la Inteligencia Artificial y estado actual de la tecnología</span>
            <ul>
                <li className="fw-light">Historia de la IA</li>
                <li className="fw-light">Estado actual de la IA y sus alcances actuales</li>
                <li className="fw-light">Problemáticas actuales de la salud e IA como probable solución</li>
            </ul>
            
            <span>Módulo 2: Fundamentos básicos de la IA</span>
            <ul>
                <li className="fw-light">Introducción a la IA y sus fundamentos</li>
            </ul>

            <span>Módulo 3: Datos e IA</span>
            <ul>
                <li className="fw-light">Importancia de los datos en IA</li>
                <li className="fw-light">Tipos de datos</li>
                <li className="fw-light">Bases de datos</li>
            </ul>

            <span>Módulo 4: Machine Learning and Deep Learning</span>
            <ul>
                <li className="fw-light">Definición y fundamentos del Machine Learning</li>
                <li className="fw-light">Definición y fundamentos del Deep Learning</li>
            </ul>

            <span>Módulo 5: NLP (Procesamiento Natural de Datos) y Redes Neuronales</span>
            <ul>
                <li className="fw-light">Definición y fundamentos de NLP</li>
                <li className="fw-light">Redes Neuronales: definiciones y ejemplos</li>
            </ul>

            <span>Módulo 6: Inteligencia Artificial en Diagnóstico Médico y Medicina por Imágenes</span>
            <ul>
                <li className="fw-light">Inteligencia Artificial en diagnóstico médico</li>
                <li className="fw-light">IA en medicina por imágenes y Machine Vision</li>
            </ul>

            <span>Módulo 7: Inteligencia Artificial en Disciplinas Clínico-Quirúrgicas</span>
            <ul>
                <li className="fw-light">IA en medicina clínica</li>
                <li className="fw-light">IA en emergencias</li>
                <li className="fw-light">IA en cirugía</li>
                <li className="fw-light">IA en oncología</li>
            </ul>

            <span>Módulo 8: IA en Investigación</span>
            <ul>
                <li className="fw-light">IA en investigación científica</li>
                <li className="fw-light">IA en desarrollo de drogas y ciencias básicas</li>
            </ul>

            <span>Módulo 9: Elaboración de Proyectos en IA</span>
            <ul>
                <li className="fw-light">Requisitos</li>
                <li className="fw-light">Estructuración de equipo</li>
                <li className="fw-light">Pasos a seguir</li>
            </ul>
        </div>
    )
}

export default TemarioIAMedica
