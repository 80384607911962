import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../multimedia/imagenes/logo-iniciales-oscuro.png'

const NavbarEducativa = () => {
    return (
        <nav className='navbar-educativa'>
            <div className='container-xxl h-100'>
                <ul className='d-flex align-items-center position-relative h-100 gap-5 px-0 mx-0 py-0 my-0'>
                    <div className="logo-container d-flex align-items-center gap-5">
                        <Link className='h-100 d-flex align-items-center' to="/">
                            <img src={Logo} alt="logo fisp" />
                        </Link>
                    </div>
                    <input type="checkbox" id="hamburger_checkbox" />
                    <label htmlFor="hamburger_checkbox" className="hamburger_button">
                        <span></span>
                        <span></span>
                        <span></span>
                    </label>
                    <div className='d-flex items'>
                        <li><Link to="/educativo/lista-posgrados/">Posgrados</Link></li>
                        <li className='temp'><Link to="/educativo/lista-diplomaturas/">Diplomaturas</Link></li>
                        {/* <li><Link to="/educativo/lista-cursos/">Cursos asincrónicos</Link></li> */}
                        <li className='rounded-5 ps-xl-5 ps-0 ms-xl-5 ms-0'><a className='rounded-5' href="https://inscripciones.fisp.org.ar/my-account/">Campus</a></li>
                    </div>
                </ul>
            </div>
        </nav>
    )
}

export default NavbarEducativa