import React from 'react'

const TemarioMedicinaPrecision = () => {
    return (
        <div className='temario-container border w-100 p-5'>
            <span>MÓDULO 1 - INTRODUCCIÓN A LA MEDICINA DE PRECISIÓN</span><br /><br />
            <ul>
                <li className="fw-light">El genoma humano</li>
                <li className="fw-light">El descubrimiento del ADN</li>
                <li className="fw-light">El año 2001</li>
                <li className="fw-light">El proyecto de genoma humano</li>
                <li className="fw-light">Herencia y tipos de herencia</li>
                <li className="fw-light">Enfoques Autonómicos. Situación actual</li>
                <li className="fw-light">La medicina de precisión</li>
                <li className="fw-light">Influencia de la genética y los factores ambientales en las enfermedades complejas</li>
                <li className="fw-light">La necesidad de precisión. El concepto de interacción</li>
                <li className="fw-light">El nuevo paradigma de las Especialidades de Precisión</li>
            </ul>

            <span>MÓDULO 2 - LAS ÓMICAS</span><br /><br />
            <ul>
                <li className="fw-light">Biología molecular</li>
                <li className="fw-light">Genómica y Metagenómica</li>
                <li className="fw-light">Epigenómica y Transcriptómica</li>
                <li className="fw-light">Proteómica</li>
                <li className="fw-light">Metabolómica</li>
            </ul>

            <span>MÓDULO 3 – GENOMA HUMANO: Técnicas de Laboratorio</span><br /><br />
            <ul>
                <li className="fw-light">El laboratorio de biología molecular</li>
                <ul>
                    <li className="fw-light">Instrucciones básicas</li>
                    <li className="fw-light">Material básico</li>
                </ul>
                <li className="fw-light">Extracción de ADN</li>
                <ul>
                    <li className="fw-light">De saliva</li>
                    <li className="fw-light">De sangre</li>
                    <li className="fw-light">De otros tejidos</li>
                </ul>
                <li className="fw-light">Real-time PCR</li>
                <ul>
                    <li className="fw-light">Introducción - historia del método</li>
                    <li className="fw-light">Protocolos básicos usados</li>
                    <li className="fw-light">Equipos más usados</li>
                </ul>
                <li className="fw-light">Secuenciación</li>
                <ul>
                    <li className="fw-light">Introducción - historia del método</li>
                    <li className="fw-light">Protocolos básicos usados</li>
                    <li className="fw-light">Equipos más usados</li>
                </ul>
                <li className="fw-light">High-throughput</li>
                <ul>
                    <li className="fw-light">Introducción - historia del método</li>
                    <li className="fw-light">Ejemplos de estudios en humanos</li>
                </ul>
                <li className="fw-light">Expresión génica - Genómica - Transcriptómica</li>
            </ul>

            <span>MÓDULO 4 - CATEGORIZACIÓN DE VARIANTES GÉNICAS</span><br /><br />
            <ul>
                <li className="fw-light">La Bioinformática en la búsqueda de la causa de la Enfermedad</li>
                <li className="fw-light">Análisis de Datos Genómicos</li>
                <li className="fw-light">Criterios de Patogenicidad</li>
                <li className="fw-light">Inteligencia artificial aplicada</li>
            </ul>

            <span>MÓDULO 5 - INTERPRETACIÓN DE RESULTADOS</span><br /><br />
            <ul>
                <li className="fw-light">El ciclo del Diagnóstico Genético: del paciente al informe y del informe al paciente</li>
                <li className="fw-light">Interpretación de los Resultados del Laboratorio</li>
                <li className="fw-light">Elaboración de Informes para los Profesionales Clínicos</li>
                <li className="fw-light">Interpretación de Informes Genéticos</li>
                <li className="fw-light">Comunicación de Resultados a los pacientes</li>
            </ul>

            <span>MÓDULO 6 - FARMACOGENÓMICA Y FARMACOGENÉTICA</span><br /><br />
            <ul>
                <li className="fw-light">La determinación de la respuesta al tratamiento</li>
                <li className="fw-light">Metabolizadores, Transportadores y Receptores</li>
                <li className="fw-light">Desde las Variantes Génicas a los Efectos Adversos</li>
                <li className="fw-light">Análisis Farmacogenético</li>
                <li className="fw-light">Aplicando la Farmacogenética en la Clínica</li>
            </ul>

            <span>MÓDULO 7 - MEDICINA PERSONALIZADA DE PRECISIÓN HACIA LAS NUEVAS TERAPIAS</span><br /><br />
            <ul>
                <li className="fw-light">Nuevas estrategias en el desarrollo de Terapias Avanzadas</li>
                <li className="fw-light">Terapia Celular y Terapia Tisular</li>
                <li className="fw-light">Terapia Génica</li>
                <li className="fw-light">Las Nuevas Terapias en las Especialidades Clínicas</li>
                <li className="fw-light">Nanomedicina</li>
            </ul>

            <span>MÓDULO 8 - NUTRIGENÉTICA</span><br /><br />
            <ul>
                <li className="fw-light">Control circadiano de la ingesta</li>
                <li className="fw-light">El eje cerebro-intestino</li>
                <li className="fw-light">Bases moleculares y neurológicas de la conexión cerebro-intestino</li>
                <li className="fw-light">La cronobiología y la nutrición</li>
                <ul>
                    <li className="fw-light">El reloj central</li>
                    <li className="fw-light">Los relojes periféricos</li>
                    <li className="fw-light">Las hormonas del ritmo circadiano</li>
                    <li className="fw-light">El control de la ingesta (leptina y grelina)</li>
                </ul>
                <li className="fw-light">Enfermedades crónicas no transmisibles</li>
                <li className="fw-light">El concepto del exposoma</li>
                <li className="fw-light">El concepto de la flexibilidad metabólica</li>
            </ul>

            <span>MÓDULO 9 - NUTRIGENÓMICA</span><br /><br />
            <ul>
                <li className="fw-light">Diferencias y similitudes con la nutrigenética</li>
                <li className="fw-light">Componentes bioactivos de la dieta sobre la expresión génica</li>
                <li className="fw-light">El efecto de micro y macronutrientes sobre la expresión génica</li>
                <li className="fw-light">El efecto de patrones dietéticos sobre la expresión génica:</li>
                <ul>
                    <li className="fw-light">El ejemplo de la dieta Mediterránea</li>
                </ul>
                <li className="fw-light">Elementos claves de la nutrición de precisión:</li>
                <ul>
                    <li className="fw-light">microbiota colónica</li>
                    <li className="fw-light">nutrigenómica aplicada</li>
                    <li className="fw-light">cronobiología</li>
                </ul>
                <li className="fw-light">Micronutrientes:</li>
                <ul>
                    <li className="fw-light">Vitaminas: liposolubles e hidrosolubles</li>
                    <li className="fw-light">Minerales</li>
                </ul>
                <li className="fw-light">Metales pesados y tóxicos ambientales. Incidencia en la microbiota intestinal, la salud y las enfermedades crónicas.</li>
            </ul>

            <span>MÓDULO 10 - GENÉTICA DE LAS ENFERMEDADES HUMANAS</span><br /><br />
            <ul>
                <li className="fw-light">Genética de las enfermedades cardiometabólicas</li>
                <li className="fw-light">Genética de las cardiopatías</li>
                <li className="fw-light">Genética de la discapacidad intelectual</li>
                <li className="fw-light">Enfermedades genéticas en neonatología y pediatría</li>
                <li className="fw-light">Genética de las enfermedades neuromusculares</li>
                <li className="fw-light">Genética de las enfermedades neurosensoriales</li>
                <li className="fw-light">Genética de las enfermedades complejas</li>
                <li className="fw-light">Genética en psiquiatría</li>
            </ul>

            <span>MÓDULO 11 - ONCOLOGÍA Y PATOLOGÍA DE PRECISIÓN</span><br /><br />
            <ul>
                <li className="fw-light">Introducción a la Oncología y Patología de Precisión</li>
                <li className="fw-light">Cáncer de Mama</li>
                <li className="fw-light">Cáncer Ginecológico</li>
                <li className="fw-light">Cáncer de Pulmón, Cabeza y Cuello</li>
                <li className="fw-light">Cáncer y Sistema Digestivo</li>
                <li className="fw-light">Cáncer en Urología</li>
                <li className="fw-light">Sarcomas y Cáncer en Dermatología</li>
                <li className="fw-light">Neoplasias Hematológicas</li>
            </ul>

            <span>MÓDULO 12 - EL DIAGNÓSTICO GENÉTICO EN LAS ENFERMEDADES PEDIÁTRICAS</span><br /><br />
            <ul>
                <li className="fw-light">Trastornos del Neurodesarrollo y Déficit Intelectual</li>
                <li className="fw-light">Patología Prenatal, Defectos Congénitos</li>
                <li className="fw-light">Genética Clínica y Neonatología</li>
                <li className="fw-light">Cromosomopatías</li>
                <li className="fw-light">Síndrome de Hipercrecimiento</li>
                <li className="fw-light">Enfermedades Endocrinológicas</li>
                <li className="fw-light">Enfermedades Metabólicas: EIM y Enfermedades de Depósito</li>
            </ul>

            <span>MÓDULO 13 - ASPECTOS ÉTICOS SOCIALES Y LEGALES DE LA GENÉTICA EN MEDICINA</span><br /><br />
            <ul>
                <li className="fw-light">Aspectos éticos y legales del diagnóstico genético</li>
                <li className="fw-light">Aspectos legales de la investigación biomédica</li>
            </ul>

        </div>
    )
}

export default TemarioMedicinaPrecision
