import { useParams } from 'react-router-dom';
import AreasCardioprotegidas from './AreasCardioprotegidas';
import RcpVirtual from './RcpVirtual';
import PaginaError from '../../PaginaError';

const ServicioMedicoRedirect = () => {
    const { service } = useParams();
    let serviceComponent;
    switch (service) {
        case 'areas-cardioprotegidas':
            serviceComponent = <AreasCardioprotegidas />
            break;
        case 'rcp-virtual':
            serviceComponent = <RcpVirtual />
            break;
        default:
            serviceComponent = <PaginaError />
            break;
    }
    return serviceComponent
};

export default ServicioMedicoRedirect;