import React, { useEffect, useRef } from 'react';
import ImagenMain from '../../multimedia/imagenes/produccion-marketing/estrategias-redes-sociales.jpg'
import RegularNavbar from '../../Navbars/RegularNavbar';
import SideNav from '../../Navbars/SideNav';
import transicionBottomTop from '../../Transiciones/transicionBottomTop';
import { Link } from 'react-router-dom';

const EstrategiaRedesSociales = () => {
    const isMountedRef = useRef(false);

    const iconoAlcance = ()=>{
        return (
            <svg width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
                <path d="M11.143 17.961c-3.221 -.295 -5.936 -2.281 -8.143 -5.961c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6c-.222 .37 -.449 .722 -.68 1.057" />
                <path d="M15 19l2 2l4 -4" />
            </svg>
            )
    }
    const iconoAudiencia = ()=>{
        return (
            <svg width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M16.5 10c3.038 0 5.5 2.015 5.5 4.5c0 1.397 -.778 2.645 -2 3.47l0 2.03l-1.964 -1.178a6.649 6.649 0 0 1 -1.536 .178c-3.038 0 -5.5 -2.015 -5.5 -4.5s2.462 -4.5 5.5 -4.5z" />
                <path d="M11.197 15.698c-.69 .196 -1.43 .302 -2.197 .302a8.008 8.008 0 0 1 -2.612 -.432l-2.388 1.432v-2.801c-1.237 -1.082 -2 -2.564 -2 -4.199c0 -3.314 3.134 -6 7 -6c3.782 0 6.863 2.57 7 5.785l0 .233" />
                <path d="M10 8h.01" />
                <path d="M7 8h.01" />
                <path d="M15 14h.01" />
                <path d="M18 14h.01" />
            </svg>
        )
    }
    const iconoSegmentacion = ()=>{
        return (
            <svg width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M12 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
                <path d="M12 7a5 5 0 1 0 5 5" />
                <path d="M13 3.055a9 9 0 1 0 7.941 7.945" />
                <path d="M15 6v3h3l3 -3h-3v-3z" />
                <path d="M15 9l-3 3" />
            </svg>
        )
    }
    const iconoMedicion = ()=>{
        return (
            <svg width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M3 4m0 1a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v10a1 1 0 0 1 -1 1h-16a1 1 0 0 1 -1 -1z" />
                <path d="M7 20h10" />
                <path d="M9 16v4" />
                <path d="M15 16v4" />
                <path d="M9 12v-4" />
                <path d="M12 12v-1" />
                <path d="M15 12v-2" />
                <path d="M12 12v-1" />
            </svg>
        )
    }
    const iconoCostos = ()=>{
        return (
            <svg width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M17.1 8.648a.568 .568 0 0 1 -.761 .011a5.682 5.682 0 0 0 -3.659 -1.34c-1.102 0 -2.205 .363 -2.205 1.374c0 1.023 1.182 1.364 2.546 1.875c2.386 .796 4.363 1.796 4.363 4.137c0 2.545 -1.977 4.295 -5.204 4.488l-.295 1.364a.557 .557 0 0 1 -.546 .443h-2.034l-.102 -.011a.568 .568 0 0 1 -.432 -.67l.318 -1.444a7.432 7.432 0 0 1 -3.273 -1.784v-.011a.545 .545 0 0 1 0 -.773l1.137 -1.102c.214 -.2 .547 -.2 .761 0a5.495 5.495 0 0 0 3.852 1.5c1.478 0 2.466 -.625 2.466 -1.614c0 -.989 -1 -1.25 -2.886 -1.954c-2 -.716 -3.898 -1.728 -3.898 -4.091c0 -2.75 2.284 -4.091 4.989 -4.216l.284 -1.398a.545 .545 0 0 1 .545 -.432h2.023l.114 .012a.544 .544 0 0 1 .42 .647l-.307 1.557a8.528 8.528 0 0 1 2.818 1.58l.023 .022c.216 .228 .216 .569 0 .773l-1.057 1.057z" />
            </svg>
        )
    }
    const iconoVentas = ()=>{
        return (
            <svg width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M4 7l.867 12.143a2 2 0 0 0 2 1.857h10.276a2 2 0 0 0 2 -1.857l.867 -12.143h-16z" />
                <path d="M8.5 7c0 -1.653 1.5 -4 3.5 -4s3.5 2.347 3.5 4" />
                <path d="M9.5 17c.413 .462 1 1 2.5 1s2.5 -.897 2.5 -2s-1 -1.5 -2.5 -2s-2 -1.47 -2 -2c0 -1.104 1 -2 2 -2s1.5 0 2.5 1" />
            </svg>
        )
    }
    const iconoFlexibilidad = ()=>{
        return (
            <svg width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M3 6a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
                <path d="M21 11v-3a2 2 0 0 0 -2 -2h-6l3 3m0 -6l-3 3" />
                <path d="M3 13v3a2 2 0 0 0 2 2h6l-3 -3m0 6l3 -3" />
                <path d="M15 18a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
            </svg>
        )
    }
    const iconoReputacion = ()=>{
        return (
            <svg width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M12 9m-6 0a6 6 0 1 0 12 0a6 6 0 1 0 -12 0" />
                <path d="M12 15l3.4 5.89l1.598 -3.233l3.598 .232l-3.4 -5.889" />
                <path d="M6.802 12l-3.4 5.89l3.598 -.233l1.598 3.232l3.4 -5.889" />
            </svg>
        )
    }
    const iconoViralidad = ()=>{
        return (
            <svg width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M16 4l4 0l0 4" />
                <path d="M14 10l6 -6" />
                <path d="M8 20l-4 0l0 -4" />
                <path d="M4 20l6 -6" />
                <path d="M16 20l4 0l0 -4" />
                <path d="M14 14l6 6" />
                <path d="M8 4l-4 0l0 4" />
                <path d="M4 4l6 6" />
            </svg>
        )
    }
    const iconoMercado = ()=>{
        return (
            <svg width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M3 21l18 0" />
                <path d="M3 7v1a3 3 0 0 0 6 0v-1m0 1a3 3 0 0 0 6 0v-1m0 1a3 3 0 0 0 6 0v-1h-18l2 -4h14l2 4" />
                <path d="M5 21l0 -10.15" />
                <path d="M19 21l0 -10.15" />
                <path d="M9 21v-4a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v4" />
            </svg>
        )
    }
    const items = [
        {
            titulo: "Alcance y visibilidad",
            descripcion: "Las redes sociales permiten llegar a un público masivo a nivel global. Puedes conectarte con personas que podrían estar interesadas en tus productos, servicios o contenido, lo que aumenta la visibilidad de tu marca.",
            icono: iconoAlcance()
        },{
            titulo: "Interacción con la audiencia",
            descripcion: "Las redes sociales ofrecen una plataforma para interactuar directamente con tu audiencia. Puedes responder a preguntas, comentarios y mensajes, lo que crea una conexión más cercana y fomenta la lealtad de los seguidores.",
            icono: iconoAudiencia()
        },{
            titulo: "Segmentación precisa",
            descripcion: "Las plataformas de redes sociales ofrecen opciones de segmentación avanzadas, lo que te permite dirigir tus campañas hacia audiencias específicas según características demográficas, intereses y comportamientos.",
            icono: iconoSegmentacion()
        },{
            titulo: "Medición y análisis",
            descripcion: "Las herramientas de análisis de redes sociales te permiten rastrear el rendimiento de tus campañas en tiempo real. Puedes medir métricas como el alcance, la participación, los clics y las conversiones, lo que te ayuda a evaluar la efectividad de tus estrategias.",
            icono: iconoMedicion()
        },{
            titulo: "Costos más bajos",
            descripcion: "En comparación con métodos tradicionales de publicidad y marketing, las redes sociales suelen ser más económicas. Puedes alcanzar una audiencia considerable con presupuestos relativamente bajos.",
            icono: iconoCostos()
        },{
            titulo: "Flexibilidad y adaptabilidad",
            descripcion: "Puedes ajustar tus estrategias y campañas en función de los resultados y la retroalimentación en tiempo real. Esto te permite adaptarte rápidamente a las tendencias cambiantes y a las necesidades de tu audiencia.",
            icono: iconoFlexibilidad()
        },{
            titulo: "Generación de leads y ventas",
            descripcion: "Las redes sociales pueden ser una fuente efectiva para generar leads y aumentar las ventas. Al dirigirte a audiencias específicas y proporcionar contenido relevante, puedes influir en la decisión de compra de los consumidores.",
            icono: iconoVentas()
        },{
            titulo: "Construcción de marca y reputación",
            descripcion: "Las redes sociales permiten establecer y fortalecer la identidad de tu marca. Puedes compartir contenido que refleje los valores y la personalidad de tu empresa, lo que ayuda a construir una imagen positiva en la mente de los consumidores.",
            icono: iconoReputacion()
        },{
            titulo: "Viralidad y alcance orgánico",
            descripcion: "Cuando el contenido se comparte y se involucra ampliamente, puede volverse viral, lo que significa que se propaga rápidamente en línea. Esto puede aumentar significativamente el alcance de tus mensajes sin costos adicionales.",
            icono: iconoViralidad()
        },{
            titulo: "Investigación de mercado",
            descripcion: "Las redes sociales también pueden servir como herramienta de investigación de mercado. Puedes obtener información valiosa sobre las preferencias de los consumidores, sus opiniones y sus necesidades a través de las interacciones en línea.",
            icono: iconoMercado()
        },
        ]

    useEffect(() => {
        if (!isMountedRef.current) {
            window.scroll(0, 0);
            isMountedRef.current = true;
        }
        const progressBar = document.getElementById('progress-bar');
        const updateProgressBar = () => {
            const totalHeight = document.documentElement.scrollHeight - window.innerHeight;
            const progress = (window.scrollY / totalHeight) * 100;
            progressBar.style.width = progress + "%";
        };
        window.addEventListener("scroll", updateProgressBar);
        return () => {
            window.removeEventListener("scroll", updateProgressBar);
        };
    }, []);

    return (
        <>
            <main className='pym-detalle' id="main-element">
                <SideNav />
                <div className="servicio-main-image container-fluid px-0">
                    <img className='w-100' src={ImagenMain} alt="Estrategias y campañas en redes sociales"/>
                </div>
                <div className="container-servicio-content pt-0 pb-5 my-5 px-2 px-sm-5">
                    <div className="container-xxl">
                        <div className="sticky-title z-20" id='sticky-title'>
                            <h1 className='poppins'>Estrategias y campañas en redes sociales</h1>
                            <div id="progress-bar"></div>
                        </div>
                        <p className='parrafo-servicio fs-5 pb-2 mt-5'>Desarrollar estrategias de contenido que involucren la creación y distribución de contenido valioso para atraer, informar y retener a la audiencia.</p>
                        <p className='parrafo-servicio fs-5 pb-2'>Las estrategias y campañas en redes sociales ofrecen una serie de beneficios significativos para individuos, empresas y organizaciones que buscan promover su presencia en línea, interactuar con su audiencia y lograr objetivos específicos. Algunos de los beneficios más destacados incluyen:</p>
                        <div className="row gap-5 pb-5">
                            {items.map((item,index)=>{
                                return (
                                    <div className='row gap-2 gap-sm-4 gap-md-5 servicio' key={index} onClick={(e)=>{e.target.scrollIntoView({block:'center'})}}>
                                        <div className="col-8 py-1 position-relative rounded-4">
                                            <h2 className='fs-5 fw-semibold poppins dropdown-title'>{item.titulo}</h2>
                                            <div className='item-dropdown'>
                                                <p className='parrafo-servicio'>{item.descripcion}</p>
                                            </div>
                                        </div>
                                        <div className="col-3 py-1 d-flex align-items-center justify-content-center rounded-4">
                                            {item.icono}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <Link to='/servicios/contacto/'>
                            <button className='py-3 position-relative text-white text-shadow d-flex align-items-center justify-content-center mb-5 rounded-4'>
                                CONTACTANOS
                                <svg width="28" height="28" viewBox="0 0 24 24" strokeWidth="3.5" stroke="#FFFFFF" fill="none">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M9 6l6 6l-6 6" />
                                </svg>
                            </button>
                        </Link>
                    </div>
                </div>
                <RegularNavbar />
            </main>
        </>
    )
}

export default transicionBottomTop(EstrategiaRedesSociales);