import React, { useEffect, useRef } from 'react';
import Imagen from '../../multimedia/imagenes/servicios-medicos/DEA/dea.jpg'
import DEA_1 from '../../multimedia/imagenes/servicios-medicos/DEA/dea2.jpg'
import DEA_2 from '../../multimedia/imagenes/servicios-medicos/DEA/dea3.jpg'
import RegularNavbar from '../../Navbars/RegularNavbar';
import SideNav from '../../Navbars/SideNav';
import transicionBottomTop from '../../Transiciones/transicionBottomTop';
import { Link } from 'react-router-dom';

const AreasCardioprotegidas = () => {
    const isMountedRef = useRef(false);

    useEffect(() => {
        if (!isMountedRef.current) {
            window.scroll(0, 0);
            isMountedRef.current = true;
        }
        const progressBar = document.getElementById('progress-bar');
        const updateProgressBar = () => {
            const totalHeight = document.documentElement.scrollHeight - window.innerHeight;
            const progress = (window.scrollY / totalHeight) * 100;
            progressBar.style.width = progress + "%";
        };
        window.addEventListener("scroll", updateProgressBar);
        return () => {
            window.removeEventListener("scroll", updateProgressBar);
        };
    }, []);

    return (
        <>
            <main className='servicio-medico-detalle' id="main-element">
                <SideNav />
                <div className="servicio-main-image container-fluid px-0">
                    <img className='w-100' src={Imagen} alt="Areas Cardioprotegidas" />
                </div>
                <div className="container-servicio-content pt-0 pb-5 my-sm-5 my-0 px-2 px-sm-5">
                    <div className="container-xxl">
                        <div className="sticky-title" id='sticky-title'>
                            <h1 className='poppins'>Áreas Cardioprotegidas</h1>
                            <div id="progress-bar"></div>
                        </div>
                        <p className='parrafo-servicio fs-5 pb-2 mt-5'>Nos complace presentarle nuestro servicio de relevamiento y certificación de áreas cardioprotegidas para edificios comerciales. En un mundo donde la seguridad y el bienestar de las personas son prioridades primordiales, brindamos una solución integral que garantiza la protección de los ocupantes de su edificio en situaciones de emergencia cardíaca.</p>
                        <p className='parrafo-servicio fs-5 pb-2'>En primer lugar, nuestro equipo de expertos altamente capacitados realizará un exhaustivo relevamiento de todas las áreas de su edificio, identificando aquellas que podrían ser propensas a casos de paro cardíaco. Utilizando tecnología de vanguardia y un enfoque basado en las mejores prácticas internacionales, evaluamos no solo los espacios interiores, sino también las áreas exteriores, tales como estacionamientos y accesos principales.</p>
                        <p className='parrafo-servicio fs-5 pb-2'>Una vez completado el relevamiento, se procederá a la certificación de las áreas cardioprotegidas en su edificio. Esto incluye la instalación de desfibriladores externos automáticos (DEA) estratégicamente ubicados, junto con los correspondientes elementos y señalización específica. Nuestros especialistas se encargarán de asegurar que cada disposición se ajuste a las normativas y regulaciones vigentes, cumpliendo con los estándares de seguirdad más exigentes.</p>
                        <div className='d-flex bi-image-section gap-5 my-5'>
                            <div className='col col-lg-6 col-xl bg-dark'>
                                <img className='object-fit-cover w-100 h-100' src={DEA_1} alt="Desfibrilador DEA" />
                            </div>
                            <div className='col col-lg-6 col-xl bg-dark'>
                                <img className='object-fit-cover w-100 h-100' src={DEA_2} alt="Desfibrilador DEA" />
                            </div>
                        </div>
                        <p className='parrafo-servicio fs-5 pb-2'>Además, brindamos capacitación integral en primeros auxilios y uso de DEA para su personal, con el objetivo de maximizar las posibilidades de éxito en caso de emergencia cardíaca. Nuestros instructores altamente calificados se adaptarán a las necesidades y horarios de su organización, proporcionando una formación práctica y teórica de calidad.</p>
                        <p className='parrafo-servicio fs-5 pb-2'>Una de las ventajas de contar con nuestro servicio es la tranquilidad que se obtiene al saber que su edificio cuenta con medidas de protección adecuadas en caso de emergencia. Además, al ser reconocidos como un edificio cardioprotegido, su organización se beneficia de una imagen corporativa responsable y cuidadosa con la seguridad de sus visitantes y empleados.</p>
                        <p className='parrafo-servicio fs-5 pb-4'>En resumen, nuestro servicio de relevamiento y certificación de áreas cardioprotegidas para edificios comerciales es una inversión estratégica en la seguridad y el bienestar de las personas. Mediante la implementación de medidas de prevención de paro cardíaco y la capacitación de su personal, su organización demuestra su compromiso con la responsabilidad social corporativa y se posiciona como una institución que prioriza la vida y el cuidado de sus ocupantes.</p>
                        <p className='parrafo-servicio fs-5 pb-4'>Contáctenos hoy mismo para obtener más información sobre cómo podemos ayudarle a convertir su edificio comercial en un espacio cardioprotegido y seguro.</p>
                        <Link to='/servicios/contacto/'>
                            <button className='py-3 position-relative text-white text-shadow d-flex align-items-center justify-content-center rounded-4 mb-5'>
                                CONTACTANOS
                                <svg width="28" height="28" viewBox="0 0 24 24" strokeWidth="3.5" stroke="#FFFFFF" fill="none">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M9 6l6 6l-6 6" />
                                </svg>
                            </button>
                        </Link>
                    </div>
                </div>
                <RegularNavbar />
            </main>
        </>
    )
}

export default transicionBottomTop(AreasCardioprotegidas);