import React, { useEffect, useState } from 'react'
import FooterEducativo from './FooterEducativo'
import NavbarEducativa from '../Navbars/NavbarEducativa'
import Axios from "axios";
import * as XLSX from 'xlsx';
import { format } from 'date-fns';
import { Helmet } from 'react-helmet';

const ListadoFormularios = () => {

    const [formularios,setFormularios] = useState([])
    const [filtro,setFiltro] = useState("")

    const obtenerFormularios = ()=>{
        Axios.get("https://srv452353.hstgr.cloud/obtenerformularios/")
        .then((response) => {
            console.log("Formularios traidos con éxito")
            setFormularios(Array.from(response.data))
        })
        .catch((error) => {
            console.log("Error al traer los formularios")
            console.log(error);
        })
    }
    const descargarExcel = ()=>{
        const filteredData = filtro === "todo" ? formularios : formularios.filter(formulario => formulario.capacitacion === filtro);

        const worksheet = XLSX.utils.json_to_sheet(filteredData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Formularios");

        XLSX.writeFile(workbook, "formularios.xlsx");
    }
    const toggleLeido = (formulario,leido)=>{
        Axios.post("https://srv452353.hstgr.cloud/actualizarformulario",{
            id: formulario.id,
            leido: leido
        }).then((result)=>{
            console.log("Formulario actualizado con éxito")
            obtenerFormularios()
        }).catch((e)=>{
            console.log("Error al actualizar formulario")
            console.log(e)
        })
    }

    useEffect(()=>{obtenerFormularios()},[])

    return (
        <main className='main-formularios-container'>
            <Helmet>
                <meta name="robots" content="noindex" />
            </Helmet>
            <NavbarEducativa />
            <div className="container-fluid section-big mt-5">
                <div className="py-4"></div>
                <div className="data-container p-5 box-shadow border mt-5">
                    <div className='p-4 border'>
                        <label htmlFor="filtro" className='p-2 mb-2 poppins uppercase'>Filtrar por capacitacion:</label>
                        <select className='w-100 p-2' name="" id="filtro" onChange={(e)=>{setFiltro(e.target.value)}}>
                            <option value="" selected disabled></option>
                            <option value="todo">Ver todo</option>
                            {[...new Set(formularios.map(formulario => formulario.capacitacion))].map(capacitacion => (
                                <option key={capacitacion} value={capacitacion}>{capacitacion}</option>
                            ))}
                        </select>
                    </div>
                    <div className="d-flex gap-5 mt-3 border p-4">
                        <p className='d-flex gap-3 align-items-center m-0'>Cantidad de interesados:  <span className='border py-2 px-3'>{filtro === "todo" ? formularios.length : formularios.filter(formulario => formulario.capacitacion === filtro).length}</span></p>
                        <button className='btn btn-dark' onClick={()=>{descargarExcel()}}>DESCARGAR EXCEL</button>
                    </div>
                    <div className="d-flex">
                        <div className="col-12">
                            <div className="col-12 d-flex">
                                <div className="border col-2 d-flex p-2 uppercase">capacitacion</div>
                                <div className="border col-2 d-flex p-2 uppercase">nombre</div>
                                <div className="border col-3 d-flex p-2 uppercase">email</div>
                                <div className="border col-1 d-flex p-2 uppercase">codigo area</div>
                                <div className="border col-2 d-flex p-2 uppercase">telefono</div>
                                <div className="border col-1 d-flex p-2 uppercase">fecha</div>
                                <div className="border col-1 d-flex p-2 uppercase">acciones</div>
                            </div>
                            {filtro === "todo" ?
                                formularios.map(formulario => (
                                    <div className={`col-12 d-flex ${formulario.leido ? 'leido' : 'no-leido'}`}>
                                        <div className="border col-2 d-flex align-items-center p-2">{formulario.capacitacion}</div>
                                        <div className="border col-2 d-flex align-items-center p-2">{formulario.nombre}</div>
                                        <div className="border col-3 d-flex align-items-center p-2">{formulario.email}</div>
                                        <div className="border col-1 d-flex align-items-center p-2">{formulario.codigo_area}</div>
                                        <div className="border col-2 d-flex align-items-center p-2">{formulario.telefono}</div>
                                        <div className="border col-1 d-flex align-items-center p-2">{formulario.fecha.split("T")[0]}</div>
                                        <div className="border col-1 d-flex justify-content-center align-items-center gap-3">
                                            <svg onClick={()=>{toggleLeido(formulario, true)}} className='action-svg' width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <title>Marcar como leido</title>
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                <path d="M11 19h-6a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v6" />
                                                <path d="M3 7l9 6l9 -6" />
                                                <path d="M15 19l2 2l4 -4" />
                                            </svg>
                                            <svg onClick={()=>{toggleLeido(formulario, false)}} className='action-svg' width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <title>Marcar como no leido</title>
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                <path d="M15 19h-10a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v5.5" />
                                                <path d="M3 7l9 6l9 -6" />
                                                <path d="M19 16v3" />
                                                <path d="M19 22v.01" />
                                            </svg>
                                            {/* <svg className='action-svg' width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <title>Descartar</title>
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                <path d="M9 5h10a2 2 0 0 1 2 2v10m-2 2h-14a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2" />
                                                <path d="M3 7l9 6l.565 -.377m2.435 -1.623l6 -4" />
                                                <path d="M3 3l18 18" />
                                            </svg> */}
                                        </div>
                                    </div>
                                )) :
                                formularios
                                    .filter(formulario => formulario.capacitacion === filtro).map(formulario => (
                                        <div className={`col-12 d-flex ${formulario.leido ? 'leido' : 'no-leido'}`}>
                                            <div className="border col-2 d-flex align-items-center p-2">{formulario.capacitacion}</div>
                                            <div className="border col-2 d-flex align-items-center p-2">{formulario.nombre}</div>
                                            <div className="border col-3 d-flex align-items-center p-2">{formulario.email}</div>
                                            <div className="border col-1 d-flex align-items-center p-2">{formulario.codigo_area}</div>
                                            <div className="border col-2 d-flex align-items-center p-2">{formulario.telefono}</div>
                                            <div className="border col-1 d-flex align-items-center p-2">{formulario.fecha.split("T")[0]}</div>
                                            <div className="border col-1 d-flex justify-content-center align-items-center gap-3">
                                                <svg onClick={()=>{toggleLeido(formulario, true)}} className='action-svg' width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <title>Marcar como leido</title>
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                    <path d="M11 19h-6a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v6" />
                                                    <path d="M3 7l9 6l9 -6" />
                                                    <path d="M15 19l2 2l4 -4" />
                                                </svg>
                                                <svg onClick={()=>{toggleLeido(formulario, false)}} className='action-svg' width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <title>Marcar como no leido</title>
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                    <path d="M15 19h-10a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v5.5" />
                                                    <path d="M3 7l9 6l9 -6" />
                                                    <path d="M19 16v3" />
                                                    <path d="M19 22v.01" />
                                                </svg>
                                                {/* <svg className='action-svg' width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <title>Descartar</title>
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                    <path d="M9 5h10a2 2 0 0 1 2 2v10m-2 2h-14a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2" />
                                                    <path d="M3 7l9 6l.565 -.377m2.435 -1.623l6 -4" />
                                                    <path d="M3 3l18 18" />
                                                </svg> */}
                                            </div>
                                        </div>
                                    ))
                            }
                        </div>
                    </div>
                </div>
            </div>
            <FooterEducativo />
        </main>
    )
}

export default ListadoFormularios