import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Line from './Line'
import RegularNavbar from '../../Navbars/RegularNavbar'
import SideNav from '../../Navbars/SideNav'
import FirmaDigital from './iconos/firma-digital.svg'
import PlataformaSalud from './iconos/historia-clinica.svg'
import Consultoria from './iconos/consultoria.svg'
import TecObrasSocialesEmpresas from './iconos/implementacion-tecnologias.svg'
import Telemedicina from './iconos/telemedicina.svg'
import videoServiciosTecnologicos from '../../multimedia/videos/servicios-tecnologicos/fondo-tecnologico.mp4'
import transicionEscalera from '../../Transiciones/transicionEscalera'
import ServicioCard from './ServicioCard'
import gsap from 'gsap'
import transicionBottomTop from '../../Transiciones/transicionBottomTop'

const ServiciosTecnologicos = () => {
    const isMountedRef = useRef(false);
    const servicios = [
        {
            imagen: TecObrasSocialesEmpresas,
            titulo: "Implementación de tecnologías",
            descripcion: "Soluciones digitales para mejorar atención médica en gobiernos.",
            path:"/servicios/servicios-tecnologicos/implementacion-de-tecnologias"
        },{
            imagen: PlataformaSalud,
            titulo: "Plataforma de salud para organizaciones",
            descripcion: "Plataformas digitales que integran y almacenan datos médicos de pacientes, centralizando su información sanitaria.",
            path:"/servicios/servicios-tecnologicos/plataforma-de-salud-para-organizaciones"
        },{
            imagen: Telemedicina,
            titulo: "Sistemas de Telemedicina de Urgencias",
            descripcion: "Soluciones digitales para atención médica remota y urgente en telemedicina.",
            path:"/servicios/servicios-tecnologicos/sistemas-de-telemedicina-de-urgencias"
        },{
            imagen: FirmaDigital,
            titulo: "Gestion de consultorio con firma digital",
            descripcion: "Firma electrónica segura y verificable para documentos y comunicaciones en línea.",
            path:"/servicios/servicios-tecnologicos/gestion-de-consultoria-con-firma-digital/"
        },{
            imagen: Consultoria,
            titulo: "Consultoría en Tecnologías de la Salud para Empresas",
            descripcion: "Asesoría experta para obras sociales, sanatorios, empresas y prestadores del sistema sanitario.",
            path:"/servicios/servicios-tecnologicos/consultoria-de-tecnologia-en-salud-para-empresas"
        }
    ]

    useEffect(()=>{
        if (!isMountedRef.current) {
            window.scroll(0, 0);
            isMountedRef.current = true;
        }
    },[])

    useEffect(()=>{
        const lineas = Array.from(document.querySelectorAll('.line'))
        const efectoMitad = Array.from(document.querySelectorAll('.half'))
        const tarjetas = Array.from(document.querySelectorAll('.servicio-tecnologico'))
        tarjetas.map((tarjeta,index)=>{
            gsap.fromTo(
                tarjeta,
                    { y: 200, opacity: 0 },
                    { y: 0, opacity: 1, duration: 0.2, scrub: true, scrollTrigger: { trigger: tarjeta, start: "top 80%", end: "bottom 20%", toggleActions: "restart none none reverse", repeatRefresh: true, repeat: 1 }} 
            )
        })
        lineas.map((line,index)=>{
            gsap.fromTo(
                line,
                { height: 0, opacity: 0 }, // Initial position
                { height: 130 , opacity: 1, duration: 0.2, scrub: true, scrollTrigger: { trigger: line, start: "top 80%", end: "bottom 20%", toggleActions: "restart none none reverse", repeatRefresh: true, repeat: 1 },
                delay: 0.15 * index } 
            );
        })
        efectoMitad.map((half,index)=>{
            gsap.fromTo(
                half,
                { y: 300 },
                { y: 0, duration: 2, scrub: true, delay: 0.2 * index }
            )
        })
    },[isMountedRef])
    
    return (
        <main className='container-fluid servicios-tecnologicos-section' id='main-element'>
            <SideNav />
            <video className='background-effect' autoPlay muted loop playsInline>
                <source src={videoServiciosTecnologicos} type='video/mp4' />
            </video>
            <div className="d-flex align-items-center servicios-tecnologicos-introduction overflow-hidden mb-5">
                <div className="backlines">
                    <Line texto={servicios[0].titulo} href={servicios[0].path}></Line>
                    <Line texto={servicios[1].titulo} href={servicios[1].path}></Line>
                    <Line texto={servicios[2].titulo} href={servicios[2].path}></Line>
                    <Line texto={servicios[3].titulo} href={servicios[3].path}></Line>
                    <Line texto={servicios[4].titulo} href={servicios[4].path}></Line>
                </div>
                <div className="container-xxl pe-none">
                    <div className="d-flex">
                        <div className='servicios-tecnologicos-content col-xl-6 col-lg-12 pe-auto'>
                            <h1><span className='poppins half'>SERVICIOS</span></h1>
                            <h2><span className='poppins half'>TECNOLÓGICOS</span></h2>
                            <p className='fs-5 ps-1 pe-5 pb-4 me-4'>
                                <span className='half'>En el ámbito de la salud, la tecnología juega un papel fundamental en la mejora de la atención médica y la optimización de los procesos clínicos.</span>
                            </p>
                            <div className='buttons'>
                                <span className='d-flex gap-3 half'>
                                    <button className='d-flex justify-content-center align-items-center pe-auto'>
                                        <a href="#servicios">
                                            Nuestros servicios
                                            <svg width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke='#000000' fill="none">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                <path d="M12 5l0 14" />
                                                <path d="M18 13l-6 6" />
                                                <path d="M6 13l6 6" />
                                            </svg>
                                        </a>
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-big servicios-tecnologicos-container position-relative mb-5">
                <div className="container-xxl d-flex flex-column align-items-center">
                    <div className="py-3 col-md-8 px-3 servicios-header d-flex flex-column align-items-center mt-5">
                        <p className='text-center fw-normal fs-5'  id='servicios'>NUESTROS SERVICIOS</p>
                        <h2 className='fs-1'>Ofrecemos tecnología aplicada a la salud</h2>
                    </div>
                    <div className="container-fluid d-flex flex-wrap justify-content-center mb-5">
                        {servicios.map((servicio, index) => (
                            <ServicioCard
                            key={index} 
                            img={servicio.imagen}
                            titulo={servicio.titulo}
                            descripcion={servicio.descripcion}
                            path={servicio.path}
                            />
                        ))}
                    </div>
                </div>
            </div>
            <RegularNavbar />
        </main>
    )
}

export default window.innerWidth >= 1000 ? transicionEscalera(ServiciosTecnologicos) : transicionBottomTop(ServiciosTecnologicos);
