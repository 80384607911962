import React, { useEffect, useRef } from 'react';
import Imagen from '../../multimedia/imagenes/servicios-tecnologicos/firma-digital.jpg'
import RegularNavbar from '../../Navbars/RegularNavbar';
import SideNav from '../../Navbars/SideNav';
import transicionBottomTop from '../../Transiciones/transicionBottomTop';
import { Link } from 'react-router-dom';
import IMG_BD from '../../multimedia/imagenes/servicios-tecnologicos/gestion-consultoria-firma/bd-segura.jpg';
import IMG_ModuloCitas from '../../multimedia/imagenes/servicios-tecnologicos/gestion-consultoria-firma/modulo-citas.jpg';
import IMG_RegistroHistoriaClinica from '../../multimedia/imagenes/servicios-tecnologicos/gestion-consultoria-firma/registro-historia-clinica.png';
import IMG_FirmaSegura from '../../multimedia/imagenes/servicios-tecnologicos/gestion-consultoria-firma/firma-segura.jpg';
import IMG_RecetasElectronicas from '../../multimedia/imagenes/servicios-tecnologicos/gestion-consultoria-firma/receta-electronica.jpg';
import IMG_ModuloFacturacion from '../../multimedia/imagenes/servicios-tecnologicos/gestion-consultoria-firma/modulo-facturacion.jpg';
import IMG_SeguridadDatos from '../../multimedia/imagenes/servicios-tecnologicos/gestion-consultoria-firma/seguridad-datos.jpeg';
import IMG_PortalPacientes from '../../multimedia/imagenes/servicios-tecnologicos/gestion-consultoria-firma/portal-medico.jpg';
import IMG_RegistroPacientes from '../../multimedia/imagenes/servicios-tecnologicos/gestion-consultoria-firma/registro-pacientes.jpg';
import IMG_ProgramacionCitas from '../../multimedia/imagenes/servicios-tecnologicos/gestion-consultoria-firma/programacion-citas.png';
import IMG_ConsultaMedica from '../../multimedia/imagenes/servicios-tecnologicos/gestion-consultoria-firma/consulta-medica.jpg';
import IMG_FirmaDigital from '../../multimedia/imagenes/servicios-tecnologicos/gestion-consultoria-firma/firma-digital.jpeg';
import IMG_FacturacionPago from '../../multimedia/imagenes/servicios-tecnologicos/gestion-consultoria-firma/factura-pago.png';
import IMG_AccesoRemoto from '../../multimedia/imagenes/servicios-tecnologicos/gestion-consultoria-firma/acceso-remoto.jpg';

const ServiciosOfrecidos = [{
    servicio: "Base de Datos Segura",
    descripcion: "El sistema debe contar con una base de datos segura y cifrada para almacenar toda la información de los pacientes, incluidos datos médicos, registros de visitas, diagnósticos y tratamientos.",
    imagen: IMG_SeguridadDatos,
},{
    servicio: "Registro de Historia Clínica Electrónica",
    descripcion: "Cada paciente tiene una historia clínica electrónica que incluye información personal, antecedentes médicos, alergias, tratamientos anteriores y registros de visitas anteriores.",
    imagen: IMG_RegistroHistoriaClinica,
},{
    servicio: "Firma Digital Segura",
    descripcion: "El sistema incorpora una función de firma digital segura que permite a los médicos y pacientes firmar documentos electrónicamente. Esto es esencial para consentimientos informados, recetas electrónicas y otros documentos médicos.",
    imagen: IMG_FirmaSegura,
},{
    servicio: "Gestión de Recetas Electrónicas",
    descripcion: "Los médicos pueden prescribir medicamentos electrónicamente, lo que facilita la comunicación con farmacias y reduce los errores de prescripción.",
    imagen: IMG_RecetasElectronicas,
},{
    servicio: "Portal del Paciente",
    descripcion: "Los pacientes tienen acceso a un portal en línea donde pueden ver su historial médico, programar citas, recibir recordatorios y firmar documentos electrónicamente.",
    imagen: IMG_PortalPacientes,
},{
    servicio: "Registro de Pacientes",
    descripcion: "Cuando un nuevo paciente se registra, se crea una historia clínica electrónica. Los datos del paciente se ingresan de forma segura en la base de datos.",
    imagen: IMG_RegistroPacientes,
},{
    servicio: "Programación de Citas",
    descripcion: "Tanto el personal del consultorio como los pacientes pueden programar citas a través del sistema. Se envían recordatorios automáticos a los pacientes antes de las citas.",
    imagen: IMG_ProgramacionCitas,
},{
    servicio: "Consulta Médica",
    descripcion: "Durante la consulta, el médico accede a la historia clínica electrónica del paciente, realiza diagnósticos y prescribe tratamientos o medicamentos. Los documentos importantes, como los consentimientos informados, se firman electrónicamente.",
    imagen: IMG_ConsultaMedica,
},{
    servicio: "Facturación y Pago",
    descripcion: "Las consultas y procedimientos se facturan electrónicamente, y los pacientes pueden realizar pagos en línea a través del portal.",
    imagen: IMG_ModuloFacturacion,
},{
    servicio: "Acceso Remoto",
    descripcion: "El sistema permite el acceso remoto para médicos y pacientes, lo que facilita las consultas telemedicinales y la gestión de la atención a distancia.",
    imagen: IMG_AccesoRemoto,
}]

const FirmaDigital = () => {
    const isMountedRef = useRef(false);

    useEffect(() => {
        if (!isMountedRef.current) {
            window.scroll(0, 0);
            isMountedRef.current = true;
        }
        const progressBar = document.getElementById('progress-bar');
        const updateProgressBar = () => {
            const totalHeight = document.documentElement.scrollHeight - window.innerHeight;
            const progress = (window.scrollY / totalHeight) * 100;
            progressBar.style.width = progress + "%";
        };
        window.addEventListener("scroll", updateProgressBar);
        return () => {
            window.removeEventListener("scroll", updateProgressBar);
        };
    }, []);

    return (
        <>
            <main className='servicio-tecnologico-detalle' id="main-element">
                <SideNav />
                <div className="servicio-main-image container-fluid px-0">
                    <img id='consultoria-mobile' className='w-100' src={Imagen} alt="Sistema de Gestión de Consultorio con Firma Digital" />
                </div>
                <div className="container-servicio-content pt-0 pb-5 my-sm-5 mt-0 px-2 px-sm-5">
                    <div className="container-xxl">
                        <div className="sticky-title" id='sticky-title'>
                            <h1 className='poppins'>Sistema de Gestión de Consultorio con Firma Digital</h1>
                            <div id="progress-bar"></div>
                        </div>
                        <p className='parrafo-servicio fs-5 pb-2 mt-4'><span>Componentes claves:</span></p>
                        <div className="d-flex flex-column gap-4 mb-5">
                            {
                                ServiciosOfrecidos.map((servicio,index)=>{
                                    return(
                                        <div className="d-flex box-shadow border componente-clave" key={index}>
                                            <div className="bg-dark col-md-4 col-12">
                                                <img className='w-100 h-100 object-fit-cover ratio-2x1 border' src={servicio.imagen} alt={servicio.servicio} />
                                            </div>
                                            <div className="col-md-8 col-12 ps-md-4 px-4">
                                                <p className='parrafo-servicio my-4 fs-5 d-flex flex-column gap-1 justify-content-center'>
                                                    <span className='pb-2 me-4 border-bottom'>{servicio.servicio}</span>
                                                    <p className='fw-normal pt-2 mb-0'>{servicio.descripcion}</p>
                                                </p>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <p className='parrafo-servicio fs-5 pb-2 text-center'><span>Ventajas del Sistema</span></p>
                        <div className='parrafo-servicio fs-5 p-3 px-sm-5 py-sm-4 mb-5 rounded-4 bg-light box-shadow-medium'>
                                <ul className='px-md-5 px-0 mx-md-5 mx-0 mb-0'>
                                    <li className='fw-medium px-2 py-2'>Mayor eficiencia en la gestión del consultorio.</li>
                                    <li className='fw-medium px-2 py-2'>Reducción de errores médicos.</li>
                                    <li className='fw-medium px-2 py-2'>Firma digital segura para documentos médicos.</li>
                                    <li className='fw-medium px-2 py-2'>Facilita la atención médica a distancia.</li>
                                    <li className='fw-medium px-2 py-2'>Cumplimiento de regulaciones de seguridad de datos.</li>
                                </ul>
                        </div>
                        <p className='parrafo-servicio fs-5 pb-2'>Un sistema de gestión de consultorio médico con firma digital es esencial en la era digital para mejorar la atención al paciente, optimizar la administración y garantizar la integridad de los datos médicos. Su implementación puede llevar a una atención más eficiente y segura.</p>
                        <Link to='/servicios/contacto/'>
                            <button className='py-3 position-relative text-white text-shadow d-flex align-items-center justify-content-center mb-5 rounded-4'>
                                CONSULTA TU PRESUPUESTO
                                <svg width="28" height="28" viewBox="0 0 24 24" strokeWidth="3.5" stroke="#FFFFFF" fill="none">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M9 6l6 6l-6 6" />
                                </svg>
                            </button>
                        </Link>                        
                    </div>
                </div>
                <RegularNavbar />
            </main>
        </>
    )
}

export default transicionBottomTop(FirmaDigital);