import React, { useEffect, useRef, useState } from 'react';
import ImagenMain from '../../multimedia/imagenes/produccion-marketing/armado-web/armado-web.jpg'
import RegularNavbar from '../../Navbars/RegularNavbar';
import SideNav from '../../Navbars/SideNav';
import { Link } from 'react-router-dom';
import transicionBottomTop from '../../Transiciones/transicionBottomTop';
import IMG_Planificacion from '../../multimedia/imagenes/produccion-marketing/armado-web/planificacion.jpg'
import IMG_Plataforma from '../../multimedia/imagenes/produccion-marketing/armado-web/eleccion-plataforma.jpeg'
import IMG_contenido from '../../multimedia/imagenes/produccion-marketing/armado-web/creacion-contenido.jpg'
import IMG_desarrollo_personalizacion from '../../multimedia/imagenes/produccion-marketing/armado-web/desarrollo-personalizacion.png'
import IMG_diseño_estructura from '../../multimedia/imagenes/produccion-marketing/armado-web/diseño-estructura.jpeg'
import IMG_lanzamiento from '../../multimedia/imagenes/produccion-marketing/armado-web/lanzamiento.jpg'
import IMG_monitoreo from '../../multimedia/imagenes/produccion-marketing/armado-web/monitoreo.jpg'
import IMG_promocion from '../../multimedia/imagenes/produccion-marketing/armado-web/promocion.jpg'
import IMG_seo from '../../multimedia/imagenes/produccion-marketing/armado-web/SEO.png'
import IMG_testing from '../../multimedia/imagenes/produccion-marketing/armado-web/testing.jpeg'

const ArmadoPaginasWeb = () => {
    const isMountedRef = useRef(false);

    useEffect(() => {
        if (!isMountedRef.current) {
            window.scroll(0, 0);
            isMountedRef.current = true;
        }
    }, []);

    return (
        <>
            <main className='pym-detalle' id="main-element">
                <SideNav />
                <div className="servicio-main-image container-fluid px-0">
                    <img className='w-100' src={ImagenMain} alt="Armado de páginas web" />
                </div>
                <div className="container-servicio-content pt-0 pb-0 my-5">
                    <div className="container-xxl pb-5">
                        <div>
                            <h1 className='poppins'>Armado de páginas web</h1>
                        </div>
                        <p className='parrafo-servicio fs-5 pb-2 mt-5'>El proceso de armado de una página web se adaptará a las necesidades de cada empresa, llevando a cabo una ardua planificación:</p>
                        <div className="d-flex gap-5 justify-content-center flex-wrap">
                            <div className="col-md-5 col-12 rounded-4 box-shadow pym-bg-2">
                                <img className='w-100' src={IMG_Planificacion} alt="Planificación y Definición de Objetivos" />
                                <div className="p-4">
                                    <h3 className="fw-bold fs-5 pb-2">Planificación y Definición de Objetivos:</h3>
                                    <ul>
                                        <li className='parrafo-servicio fs-5'>Define el propósito de tu sitio web (por ejemplo, promocionar un negocio, compartir información personal, vender productos, etc.).</li>
                                        <li className='parrafo-servicio fs-5'>Identifica tu audiencia objetivo y sus necesidades.</li>
                                        <li className='parrafo-servicio fs-5'>Elige un nombre de dominio relevante y fácil de recordar para tu sitio web.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-5 col-12 rounded-4 box-shadow pym-bg-1">
                                <img className='w-100' src={IMG_Plataforma} alt="Elección de Plataforma y Alojamiento" />
                                <div className="p-4">
                                    <h3 className="fw-bold fs-5 pb-2">Elección de Plataforma y Alojamiento:</h3>
                                    <ul>
                                        <li className='parrafo-servicio fs-5'>Elige una plataforma de creación de sitios web, como WordPress, Wix, Squarespace, o HTML/CSS si tienes habilidades de codificación.</li>
                                        <li className='parrafo-servicio fs-5'>Selecciona un proveedor de alojamiento web para almacenar los archivos de tu sitio. Puede ser un servicio independiente o integrado con la plataforma que elijas.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-5 col-12 rounded-4 box-shadow pym-bg-2">
                                <img className='w-100' src={IMG_diseño_estructura} alt="Diseño y Estructura" />
                                <div className="p-4">
                                <h3 className="fw-bold fs-5 pb-2">Diseño y Estructura:</h3>
                                <ul>
                                    <li className='parrafo-servicio fs-5'>Diseña un esquema básico para tu sitio, incluyendo las páginas que necesitas (inicio, acerca de, servicios, contacto, etc.).</li>
                                    <li className='parrafo-servicio fs-5'>Elige una plantilla o tema que se ajuste a la estética y funcionalidad que deseas para tu sitio web.</li>
                                </ul>
                                </div>
                            </div>
                            <div className="col-md-5 col-12 rounded-4 box-shadow pym-bg-1">
                                <img className='w-100' src={IMG_contenido} alt="Creación de Contenido" />
                                <div className="p-4">
                                <h3 className="fw-bold fs-5 pb-2">Creación de Contenido:</h3>
                                <ul>
                                    <li className='parrafo-servicio fs-5'>Crea contenido de calidad para cada página, incluyendo texto, imágenes, videos y otros elementos multimedia.</li>
                                    <li className='parrafo-servicio fs-5'>Optimiza tus imágenes para web para asegurarte de que tu sitio cargue rápidamente.</li>
                                </ul>
                                </div>
                            </div>
                            <div className="col-md-5 col-12 rounded-4 box-shadow pym-bg-2">
                                <img className='w-100' src={IMG_desarrollo_personalizacion} alt="Desarrollo y Personalización" />
                                <div className="p-4">
                                <h3 className="fw-bold fs-5 pb-2">Desarrollo y Personalización:</h3>
                                <ul>
                                    <li className='parrafo-servicio fs-5'>Si estás utilizando una plataforma como WordPress, personaliza la apariencia y funcionalidad utilizando complementos y widgets.</li>
                                    <li className='parrafo-servicio fs-5'>Si estás codificando desde cero, escribe el código HTML, CSS y posiblemente JavaScript necesario para construir tu sitio.</li>
                                </ul>
                                </div>
                            </div>
                            <div className="col-md-5 col-12 rounded-4 box-shadow pym-bg-1">
                                <img className='w-100' src={IMG_seo} alt="Optimización para SEO" />
                                <div className="p-4">
                                <h3 className="fw-bold fs-5 pb-2">Optimización para SEO:</h3>
                                <ul>
                                    <li className='parrafo-servicio fs-5'>Optimiza tu contenido para motores de búsqueda (SEO) incluyendo palabras clave relevantes, títulos y meta descripciones.</li>
                                    <li className='parrafo-servicio fs-5'>Configura las URL de manera amigable y estructura tus etiquetas de encabezado adecuadamente.</li>
                                </ul>
                                </div>
                            </div>
                            <div className="col-md-5 col-12 rounded-4 box-shadow pym-bg-2">
                                <img className='w-100' src={IMG_testing} alt="Pruebas" />
                                <div className="p-4">
                                <h3 className="fw-bold fs-5 pb-2">Pruebas:</h3>
                                <ul>
                                    <li className='parrafo-servicio fs-5'>Prueba tu sitio web en diferentes navegadores y dispositivos para asegurarte de que se vea y funcione bien en todos ellos.</li>
                                    <li className='parrafo-servicio fs-5'>Verifica que todos los enlaces y formularios funcionen correctamente.</li>
                                </ul>
                                </div>
                            </div>
                            <div className="col-md-5 col-12 rounded-4 box-shadow pym-bg-1">
                                <img className='w-100' src={IMG_lanzamiento} alt="Lanzamiento" />
                                <div className="p-4">
                                <h3 className="fw-bold fs-5 pb-2">Lanzamiento:</h3>
                                <ul>
                                    <li className='parrafo-servicio fs-5'>Una vez que estés satisfecho con el diseño y el contenido, es hora de lanzar tu sitio web.</li>
                                    <li className='parrafo-servicio fs-5'>Haz que esté en línea y accesible para el público.</li>
                                </ul>
                                </div>
                            </div>
                            <div className="col-md-5 col-12 rounded-4 box-shadow pym-bg-2">
                                <img className='w-100' src={IMG_promocion} alt="Promoción y Mantenimiento" />
                                <div className="p-4">
                                <h3 className="fw-bold fs-5 pb-2">Promoción y Mantenimiento:</h3>
                                <ul>
                                    <li className='parrafo-servicio fs-5'>Promociona tu sitio web a través de redes sociales, marketing por correo electrónico u otras estrategias de marketing digital.</li>
                                    <li className='parrafo-servicio fs-5'>Mantén tu sitio actualizado con contenido fresco y relevante. Realiza copias de seguridad regulares y asegúrate de que los complementos o temas estén actualizados.</li>
                                </ul>
                                </div>
                            </div>
                            <div className="col-md-5 col-12 rounded-4 box-shadow pym-bg-1">
                                <img className='w-100' src={IMG_monitoreo} alt="Monitoreo y Mejora" />
                                <div className="p-4">
                                <h3 className="fw-bold fs-5 pb-2">Monitoreo y Mejora:</h3>
                                <ul>
                                    <li className='parrafo-servicio fs-5'>Utiliza herramientas de análisis web para rastrear el tráfico y el comportamiento de los usuarios en tu sitio. Ajusta tu estrategia según los resultados.</li>
                                    <li className='parrafo-servicio fs-5'>Escucha los comentarios de los usuarios y realiza mejoras continuas en el diseño y la funcionalidad de tu sitio.</li>
                                </ul>
                                </div>
                            </div>
                        </div>
                        <button className='py-3 mt-5 text-white text-shadow d-flex align-items-center justify-content-center rounded-4'>
                            CONTACTANOS
                            <svg width="28" height="28" viewBox="0 0 24 24" strokeWidth="3.5" stroke="#FFFFFF" fill="none">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <path d="M9 6l6 6l-6 6" />
                            </svg>
                        </button>
                        <div className="py-5"></div>
                    </div>
                </div>
                <RegularNavbar />
            </main>
        </>
    )
}

export default transicionBottomTop(ArmadoPaginasWeb);