import React from 'react'

const TemarioEnfermeriaOncologica = () => {
    return (
        <div className='temario-container border w-100 p-5'>
        <span>“Introducción a la Enfermería Oncológica”</span>
        <br /><br />
        <span>BLOQUE PACIENTE ADULTO: </span>
        <br /><br />
        <ol>
            <li className='fw-light'>Presentación del curso. Patologías oncológicas de mayor incidencia en la Argentina</li>
            <li className='fw-light'>Ciclo Celular normal, célula cancerosa, carcinogénesis.</li>
            <li className='fw-light'>Enfermería Oncológica y perfil del profesional. Evolución de la Enfermería Oncológica. </li>
            <li className='fw-light'>Abordaje de Enfermería al paciente oncológico desde atención primaria.</li>
            <li className='fw-light'>Incidencia del cáncer en la Argentina. Tumores con mayor prevalencia. Programas nacionales de prevención del cáncer y el rol de enfermería en la creación de políticas públicas.</li>
            <li className='fw-light'>Métodos de diagnósticos, estadios y clasificación de tumores. Rol de Enfermería en el acompañamiento y atención del paciente de reciente diagnóstico.</li>
            <li className='fw-light'>Introducción a los cuidados paliativos y su rol desde el inicio del tratamiento.</li>
            <li className='fw-light'>Rol de la enfermera navegadora en oncología. Modelos de atención centrada en la persona.</li>
        </ol>
        <span>BLOQUE PACIENTE PEDIÁTRICO: </span>
        <br /><br />
        <ol>
            <li className='fw-light'>Patologías oncológicas pediátricas de mayor incidencia en la Argentina,</li>
            <li className='fw-light'>Enfermería Oncológica Pediátrica y perfil del profesional.</li>
            <li className='fw-light'>Comunicación efectiva al paciente y su familia. Rol de Enfermería en el cuidado del niño y familia.</li>
            <li className='fw-light'>Métodos de diagnósticos, estadios y clasificación de tumores en pediatría. Rol de Enfermería en el acompañamiento y atención del paciente de reciente diagnóstico.</li>
            <li className='fw-light'>Introducción a los cuidados paliativos en pediatría y su rol desde el inicio del tratamiento.</li>
            <li className='fw-light'></li>
        </ol>
        <span>“Pilares de tratamientos e intervenciones de Enfermería”</span>
        <br /><br />
        <ol>
            <li className='fw-light'>Comprender los diferentes tratamientos oncológicos utilizados en pacientes adultos y pediátricos, como la cirugía, la radioterapia y el cobalto, y reconocer los cuidados de enfermería específicos asociados a cada uno de ellos.</li>
            <li className='fw-light'>Familiarizarse con los conceptos básicos de la quimioterapia, incluyendo su clasificación, los síntomas inmediatos y tardíos, y comprender los cuidados de enfermería necesarios para minimizar sus efectos adversos y promover la recuperación del paciente.</li>
            <li className='fw-light'>Conocer los principios de la bioterapia o inmunoterapia utilizada en el tratamiento del cáncer y comprender los cuidados de enfermería requeridos para garantizar una administración segura y eficaz.</li>
            <li className='fw-light'>Aprender las técnicas y protocolos para la administración segura de quimioterapia en sus diferentes vías de administración, y adquirir habilidades en la gestión del cuidado de pacientes en tratamiento.</li>
            <li className='fw-light'>Comprender la importancia de la adherencia al tratamiento oncológico y adquirir conocimientos para ayudar a los pacientes a cumplir con sus protocolos de tratamiento, brindando educación y apoyo adecuados.</li>
            <li className='fw-light'>Planificar protocolos de cuidados, teniendo en cuenta las necesidades individuales de los pacientes y los cuidados de enfermería necesarios para asegurar su bienestar.</li>
            <li className='fw-light'>Introducirse en los fundamentos de la oncohematología, tanto en adultos como en pediatría, y comprender los pilares de los tratamientos utilizados en estos casos, así como los cuidados de enfermería específicos que se requieren.</li>
            <li className='fw-light'>Reconocer el papel de Enfermería en la bioseguridad y en el manejo adecuado de citostáticos, garantizando la seguridad tanto del paciente como del personal de salud.</li>
            <li className='fw-light'>Valorar la importancia de la participación de la familia en la continuidad del cuidado en el caso de pacientes pediátricos, y adquirir habilidades para fomentar una colaboración efectiva y una atención integral.</li>
        </ol>
        <span>BLOQUE PACIENTE ADULTO: </span>
        <br /><br />
        <ol>
            <li className='fw-light'>Tratamientos oncológicos: Cirugía, Radioterapia y Cobalto. Cuidados de Enfermería.</li>
            <li className='fw-light'>Quimioterapia, clasificación, síntomas inmediatos y mediatos. Cuidados de Enfermería.</li>
            <li className='fw-light'>Bioterapia o inmunoterapia. Cuidados de Enfermería.</li>
            <li className='fw-light'>Administración segura de quimioterapia en sus diferentes vías de administración. Gestión del cuidado.</li>
            <li className='fw-light'>Adherencia al tratamiento.</li>
            <li className='fw-light'>Planificación de protocolos de tratamientos. Cuidados de Enfermería.</li>
            <li className='fw-light'>Introducción a la oncohematología. Pilares de tratamientos. Cuidados de Enfermería.</li>
            <li className='fw-light'>Rol de Enfermería en la Bioseguridad en el manejo de citostáticos.</li>
        </ol>
        <span>BLOQUE PACIENTE PEDIÁTRICO: </span>
        <br /><br />
        <ol>
            <li className='fw-light'>Tratamientos oncológicos en Pediatría: Cirugía y Radioterapia. Cuidados de Enfermería.</li>
            <li className='fw-light'>Quimioterapia, clasificación, síntomas inmediatos y mediatos. Cuidados de Enfermería.</li>
            <li className='fw-light'>Bioterapia o inmunoterapia en Pediatría. Cuidados de Enfermería.</li>
            <li className='fw-light'>Administración segura de quimioterapia en sus diferentes vías de administración. Gestión del cuidado. Participación de la familia en la continuidad del cuidado.</li>
            <li className='fw-light'>Planificación de protocolos de tratamientos. Cuidados de Enfermería.</li>
            <li className='fw-light'>Introducción a la oncohematología pediátrica. Pilares de tratamientos. Cuidados de Enfermería.</li>
            <li className='fw-light'>Rol de Enfermería en la Bioseguridad en el manejo de citostáticos.</li>
        </ol>
        <span>“Gestión en los servicios de Oncología y los diferentes roles de Enfermería”</span>
        <br /><br />
        <ol>
            <li className='fw-light'>PACIENTE ADULTO:</li>
            <li className='fw-light'>Gestión de Hospital de día Oncológico. Rol de Enfermería.</li>
            <li className='fw-light'>El rol de Enfermería en la Gestión de Servicios Oncológicos.</li>
            <li className='fw-light'>Consultorio de Enfermería. Educación al paciente y su familia para el manejo domiciliario.</li>
            <li className='fw-light'>Seguridad del paciente oncológico.</li>
            <li className='fw-light'>Calidad de atención en Oncología.</li>
            <li className='fw-light'>Investigación en Oncología.</li>
            <li className='fw-light'>La importancia de la nutrición en el paciente oncológico. Rol de Enfermería.</li>
            <li className='fw-light'>Comités bioéticos y aspectos bioéticos de relevancia para la Enfermería Oncológica.</li>
            <li className='fw-light'>Aspectos jurídicos legales de la Enfermería oncológica.</li>
            <li className='fw-light'>Liderazgo para Enfermería Oncológica.</li>
            <li className='fw-light'>Autocuidado para Enfermería Oncológica.</li>
        </ol>
        <span>PACIENTE PEDIÁTRICO: </span>
        <br /><br />
        <ol>
            <li className='fw-light'>Hospital de día Oncológico Pediátrico. Rol de Enfermería.</li>
            <li className='fw-light'>El rol de Enfermería en la Gestión de Servicios Oncológicos.</li>
            <li className='fw-light'>Consultorio de Enfermería. Educación al niño y su familia para el manejo domiciliario.</li>
            <li className='fw-light'>Seguridad del paciente Oncológico Pediátrico.</li>
            <li className='fw-light'>Gestión de Calidad en la atención del paciente pediátrico oncológico.</li>
            <li className='fw-light'>Investigación en Oncología Pediátrica.</li>
            <li className='fw-light'>Nutrición en el paciente oncológico pediátrico. Rol de la familia y de Enfermería.</li>
        </ol>
        </div>
    )
}

export default TemarioEnfermeriaOncologica