import React, { useEffect,useRef, useState } from 'react'
import videoMarketing from '../../multimedia/videos/produccionmarketing/videoproduccion.mp4'
import SideNav from '../../Navbars/SideNav'
import RegularNavbar from '../../Navbars/RegularNavbar'
import gsap from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'
import transicionEscalera from '../../Transiciones/transicionEscalera'
import { Link } from 'react-router-dom'
import contenidosMedida from '../../multimedia/imagenes/produccion-marketing/produccion-audiovisual/contenidos-medida.jpg'
import entrevistasEmpresas from '../../multimedia/imagenes/produccion-marketing/produccion-audiovisual/entrevistas-empresas.jpg'
import conduccionEventos from '../../multimedia/imagenes/produccion-marketing/produccion-audiovisual/conduccion-eventos.jpg'
import cobertura from '../../multimedia/imagenes/produccion-marketing/produccion-audiovisual/cobertura.jpg'
import grabacionEdicion from '../../multimedia/imagenes/produccion-marketing/produccion-audiovisual/grabacion-edicion.jpg'
import videosInstitucionales from '../../multimedia/imagenes/produccion-marketing/produccion-audiovisual/videos-institucionales.jpg'
import presentacionProductos from '../../multimedia/imagenes/produccion-marketing/produccion-audiovisual/presentacion-productos.jpg'
import podcast from '../../multimedia/imagenes/produccion-marketing/produccion-audiovisual/podcast.jpg'
import transicionBottomTop from '../../Transiciones/transicionBottomTop'
gsap.registerPlugin(ScrollTrigger)

const Produccion = () => {
    const isMountedRef = useRef(false);
    
    const getIconoProduccion =()=>{
        return(
            <svg width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#FFFFFF" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M15 10l4.553 -2.276a1 1 0 0 1 1.447 .894v6.764a1 1 0 0 1 -1.447 .894l-4.553 -2.276v-4z" />
                <path d="M3 6m0 2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2z" />
            </svg>
        )
    }
    const getIconoEntrevista =()=>{
        return(
            <svg width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#FFFFFF" fill="none">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M12 20m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                <path d="M10 20h-6" />
                <path d="M14 20h6" />
                <path d="M12 15l-2 -2h-3a1 1 0 0 1 -1 -1v-8a1 1 0 0 1 1 -1h10a1 1 0 0 1 1 1v8a1 1 0 0 1 -1 1h-3l-2 2z" />
                <path d="M9 6h6" />
                <path d="M9 9h3" />
            </svg>
        )
    }
    const getIconoCobertura =()=>{
        return(
            <svg width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#FFFFFF" fill="none">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                <path d="M11.5 21h-5.5a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v6" />
                                                <path d="M16 3v4" />
                                                <path d="M8 3v4" />
                                                <path d="M4 11h16" />
                                                <path d="M15 19l2 2l4 -4" />
                                            </svg>
        )
    }
    const getIconoGrabacion =()=>{
        return(
            <svg width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#FFFFFF" fill="none">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                <path d="M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z" />
                                                <path d="M8 4l0 16" />
                                                <path d="M16 4l0 16" />
                                                <path d="M4 8l4 0" />
                                                <path d="M4 16l4 0" />
                                                <path d="M4 12l16 0" />
                                                <path d="M16 8l4 0" />
                                                <path d="M16 16l4 0" />
                                            </svg>
        )
    }
    const getIconoPresentacion =()=>{
        return(
            <svg width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#FFFFFF" fill="none">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                <path d="M9 12v-4" />
                                                <path d="M15 12v-2" />
                                                <path d="M12 12v-1" />
                                                <path d="M3 4h18" />
                                                <path d="M4 4v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-10" />
                                                <path d="M12 16v4" />
                                                <path d="M9 20h6" />
                                            </svg>
        )
    }
    const getIconoConduccion =()=>{
        return(
            <svg width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#FFFFFF">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M18 8a3 3 0 0 1 0 6" />
                <path d="M10 8v11a1 1 0 0 1 -1 1h-1a1 1 0 0 1 -1 -1v-5" />
                <path d="M12 8h0l4.524 -3.77a.9 .9 0 0 1 1.476 .692v12.156a.9 .9 0 0 1 -1.476 .692l-4.524 -3.77h-8a1 1 0 0 1 -1 -1v-4a1 1 0 0 1 1 -1h8" />
            </svg>
        )
    }
    const getIconoVideos =()=>{
        return(
            <svg width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#FFFFFF">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                <path d="M3 5m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z" />
                                                <path d="M14 9v6h1a2 2 0 0 0 2 -2v-2a2 2 0 0 0 -2 -2h-1z" />
                                                <path d="M7 15v-6" />
                                                <path d="M10 15v-6" />
                                                <path d="M7 12h3" />
                                            </svg>
        )
    }
    const getIconoPodcast =()=>{
        return(
            <svg width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#FFFFFF" fill="none">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                <path d="M4 13m0 2a2 2 0 0 1 2 -2h1a2 2 0 0 1 2 2v3a2 2 0 0 1 -2 2h-1a2 2 0 0 1 -2 -2z" />
                                                <path d="M15 13m0 2a2 2 0 0 1 2 -2h1a2 2 0 0 1 2 2v3a2 2 0 0 1 -2 2h-1a2 2 0 0 1 -2 -2z" />
                                                <path d="M4 15v-3a8 8 0 0 1 16 0v3" />
                                            </svg>
        )
    }
    const serviciosProduccion = [
        [
            {
                titulo: "Producción de contenidos a medida.",
                imagen: contenidosMedida,
                icono: getIconoProduccion(),
                descripcion: "Transformamos tus ideas en experiencias visuales de calidad. Desde concepto hasta entrega, creamos soluciones personalizadas que destacan tu mensaje y cautivan a tu audiencia. Descubrí cómo podemos dar vida a tus proyectos con creatividad y calidad excepcionales."
            },{
                titulo: "Entrevistas a empresas.",
                imagen: entrevistasEmpresas,
                icono: getIconoEntrevista(),
                descripcion: "Potenciamos tu presencia corporativa con entrevistas estratégicas que impulsan tu marca. De la mano de periodistas especializados buscaremos destacar los valores y logros de tu empresa. Posiciónate como líder en tu industria, compartiendo historias, productos, servicios y lanzamientos. Eleva tu visibilidad y deja una impresión duradera en tus clientes y seguidores."
            },{
                titulo: "Cobertura de eventos y congresos.",
                imagen: cobertura,
                icono: getIconoCobertura(),
                descripcion: "Ofrecemos una cobertura audiovisual especializada, registramos momentos clave y destacamos ponencia. Aumentá la visibilidad de tu evento mostrando la excelencia de tu congreso y atrayendo a profesionales de la salud de todo el mundo. Deja que la calidad de nuestras producciones eleve la reputación de tu evento médico. Confía en nosotros para transformar tu congreso en una experiencia memorable."
            },{
                titulo: "Grabación y edición de contenido médico.",
                imagen: grabacionEdicion,
                icono: getIconoGrabacion(),
                descripcion: "Nuestro equipo especializado asegura una presentación de calidad de tu contenido médico, proporcionando una herramienta efectiva para la educación y comunicación en el campo de la salud. Resaltá la excelencia de tu trabajo, transformando el complejo mundo médico en contenido accesible y cautivador. Eleva tu visibilidad en el mundo digital con nuestras soluciones de grabación y edición."
            }
        ],[
            {
                titulo: "Presentación de productos médicos.",
                imagen: presentacionProductos,
                icono: getIconoPresentacion(),
                descripcion: "Impulsá el lanzamiento de tus productos médicos. Desde la planificación hasta la ejecución, nos encargamos de cada detalle para garantizar un evento impactante. Creamos experiencias envolventes que resaltan las características únicas de tus productos, conectando a tu audiencia con innovaciones de vanguardia en el ámbito médico. Potenciá el impacto de tus lanzamientos con servicios profesionales."
            },{
                titulo: "Conducción de eventos.",
                imagen: conduccionEventos,
                icono: getIconoConduccion(),
                descripcion: "Elevá la calidad y el dinamismo de tus encuentros profesionales. Nuestro staff de conductoras especializadas aportan un toque experto y profesional, guiando tu evento con fluidez y precisión. Desde congresos hasta presentaciones, nos encargamos de mantener la atención de la audiencia y facilitar una experiencia memorable."
            },{
                titulo: "Videos institucionales.",
                imagen: videosInstitucionales,
                icono: getIconoVideos(),
                descripcion: "Capturamos la singularidad de tu historia, valores y logros, creando contenido visualmente impactante. Desde la concepción hasta la postproducción, nuestro equipo se dedica a transmitir tu mensaje con impacto y profesionalismo. Con una narrativa cautivadora e imágenes envolventes, impulsamos tu visibilidad destacando tu excelencia y atrayendo la atención de tu audiencia de manera efectiva."
            },{
                titulo: "Podcast.",
                imagen: podcast,
                icono: getIconoPodcast(),
                descripcion: "Ofrecemos una experiencia integral para que compartas tu contenido de manera profesional y atractiva. Nuestro equipo se encarga de la grabación y edición asegurando la máxima calidad en cada episodio. Convertí tu visión en sonidos envolventes que resuenen con tu audiencia."
            }
        ]
    ]

    const [slideCardContent,setSlideCardContent] = useState({
        imagen: contenidosMedida,
        descripcion: "Potenciá tu presencia visual con nuestros servicios de producción audiovisual de alta calidad. Capturamos tu visión y la transformamos en experiencias audiovisuales impactantes. Desde videos promocionales, podcast, hasta producciones corporativas, nuestro equipo creativo se encarga de cada detalle. Elevá tu marca con contenido audiovisual único y cautivador."
    })

    useEffect(()=>{
        if (!isMountedRef.current) {
            window.scroll(0, 0);
            isMountedRef.current = true;
        }
    },[])
    useEffect(()=>{
        const serviciosMarketing = Array.from(document.querySelectorAll('.marketing-service'))
        const marketingContent = document.querySelector('.marketing-section-content')
        const produccionContent = document.querySelector('.produccion-audiovisual-container');        

        serviciosMarketing.map(servicio=>{
            gsap.fromTo(
                servicio,
                { y: 200, opacity: 0 },
                { y: 0, opacity: 1, duration: 0.2, scrollTrigger: { trigger: servicio, start: "top 100%", end: "bottom -500%", toggleActions: "restart none none reverse", repeatRefresh: true, repeat: 1 } } 
            );
        })
        gsap.fromTo(
            marketingContent,
            { y: 300, opacity: 0 },
            { y: 0, opacity: 1, duration: 0.4, scrollTrigger: { trigger: marketingContent, start: "top 80%", end: "bottom 20%", toggleActions: "restart none none reverse", repeatRefresh: true, repeat: 1 } } 
        );
        gsap.fromTo(
            produccionContent,
            { y: 300, opacity: 0 },
            { y: 0, opacity: 1, duration: 0.5, scrollTrigger: { trigger: produccionContent, start: "top 80%", end: "bottom 20%", toggleActions: "restart none none reverse", repeatRefresh: true, repeat: 1 } } 
        );
    },[isMountedRef])

    const changeContent = (e) => {
        const slideCard = document.getElementById('slide-card');
        const cardContent = document.getElementById('service-content');

        if(window.innerWidth < 600){cardContent.scrollIntoView()}
        gsap.to(slideCard, {
            y: "-100%",
            opacity: 0,
            duration: 1,
            onComplete: () => {
                setSlideCardContent(e);
                gsap.fromTo(
                    slideCard,
                    {
                        y: "100%",
                        opacity: 0,
                        display: "block"
                    },
                    {
                        y: "0%",
                        opacity: 1,
                        duration: 1,
                        onComplete: () => {}
                    }
                );
            }
        });
        gsap.to(cardContent, {
            // y: "-100%",
            opacity: 0,
            duration: 1,
            onComplete: () => {
                setSlideCardContent(e);
                gsap.fromTo(
                    cardContent,
                    {
                        // y: "100%",
                        opacity: 0,
                        display: "block"
                    },
                    {
                        // y: "0%",
                        opacity: 1,
                        duration: 1,
                        onComplete: () => {}
                    }
                );
            }
        });
    };
    

    return (
        <>
            <main className='pym-container' id="main-element">
                <SideNav />
                <div className="video-container d-flex align-items-center">
                    <video autoPlay muted loop playsInline>
                        <source src={videoMarketing} type='video/mp4' />
                    </video>
                    <div className="container-xxl">
                        <div>
                            <h1 className='poppins fs-bigger-title'>MARKETING  &</h1>
                            <h1 className='poppins fs-bigger-title'>PRODUCCIÓN</h1>
                        </div>
                        <div>
                            <button className='d-flex justify-content-center align-items-center'>
                                <a href="#servicios">
                                    Nuestros servicios
                                    <svg width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke='#FFFFFF' fill="none">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                        <path d="M12 5l0 14" />
                                        <path d="M18 13l-6 6" />
                                        <path d="M6 13l6 6" />
                                    </svg> 
                                </a>
                            </button>
                        </div>
                    </div>
                </div>
                <section className='section-big marketing-section overflow-hidden container-xxl d-flex align-items-center' id='servicios'>
                    <div className="d-flex gap-5 h-100 w-100">
                        <div className="marketing-section-content py-md-5 pt-5 pb-0 col d-flex flex-column justify-content-center">
                            <h1 className="fs-bigger-title my-0">Marketing</h1>
                            <h1 className="fs-bigger-title my-0">Estratégico</h1>
                            <p className='fs-4 fw-medium ps-lg-0 p-0 p-lg-3'>En el sector de la salud, los servicios de marketing digital son cruciales para lograr mayor visibilidad, llegar a pacientes y promover servicios médicos de manera efectiva. A través de este servicio se mejora la accesibilidad a la atención médica y fortalece la relación médico-paciente.</p>
                            <div>
                                <button className='d-flex justify-content-center align-items-center'>
                                    <Link to="/servicios/contacto/">
                                        Contactanos
                                        <svg width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke='#000000' fill="none">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <path d="M17 7l-10 10" />
                                            <path d="M8 7l9 0l0 9" />
                                        </svg>   
                                    </Link>
                                </button>
                            </div>
                        </div>
                        <div className="col h-100 d-flex marketing-services gap-xl-5 gap-4">
                            <div className="col-12 col-sm col-md col-lg-5 d-flex flex-column justify-content-center">
                                <Link to='estrategias-redes-sociales'>
                                    <div className="marketing-service w-100 d-flex align-items-center">
                                        <h2 className='fs-4 px-4 w-100 poppins fw-normal mb-0'>Estrategias y campañas en redes sociales</h2>
                                        <svg width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke='#FFFFFF' fill="none">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <path d="M17 7l-10 10" />
                                            <path d="M8 7l9 0l0 9" />
                                        </svg>   
                                    </div>
                                </Link>
                                <Link to='email-whatsapp-marketing'>
                                    <div className="marketing-service w-100 d-flex align-items-center">
                                        <h2 className='fs-4 px-4 w-100 poppins fw-normal mb-0'>E-Mail y WhatsApp Marketing</h2>
                                        <svg width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke='#FFFFFF' fill="none">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <path d="M17 7l-10 10" />
                                            <path d="M8 7l9 0l0 9" />
                                        </svg>   
                                    </div>
                                </Link>
                                <Link to='publicidad-en-linea'>
                                    <div className="marketing-service w-100 d-flex align-items-center">
                                        <h2 className='fs-4 px-4 w-100 poppins fw-normal mb-0'>Publicidad en línea</h2>
                                        <svg width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke='#FFFFFF' fill="none">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <path d="M17 7l-10 10" />
                                            <path d="M8 7l9 0l0 9" />
                                        </svg>   
                                    </div>
                                </Link>
                            </div>
                            <div className="col-12 col-sm col-md col-lg-5 d-flex flex-column justify-content-center">
                                <Link to='armado-de-paginas-web'>
                                    <div className="marketing-service w-100 d-flex align-items-center">
                                        <h2 className='fs-4 px-4 w-100 poppins fw-normal mb-0'>Armado de páginas web</h2>
                                        <svg width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke='#FFFFFF' fill="none">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <path d="M17 7l-10 10" />
                                            <path d="M8 7l9 0l0 9" />
                                        </svg>   
                                    </div>
                                </Link>
                                <Link to='contenido-mundo-digital'>
                                    <div className="marketing-service w-100 d-flex align-items-center">
                                        <h2 className='fs-4 px-4 w-100 poppins fw-normal mb-0'>Generación de contenido para el mundo digital</h2>
                                        <svg width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke='#FFFFFF' fill="none">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <path d="M17 7l-10 10" />
                                            <path d="M8 7l9 0l0 9" />
                                        </svg>   
                                    </div>
                                </Link>
                                <Link to='charlas-corporativas'>
                                    <div className="marketing-service w-100 d-flex align-items-center">
                                        <h2 className='fs-4 px-4 w-100 poppins fw-normal mb-0'>Charlas corporativas</h2>
                                        <svg width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke='#FFFFFF' fill="none">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <path d="M17 7l-10 10" />
                                            <path d="M8 7l9 0l0 9" />
                                        </svg>   
                                    </div>
                                </Link>
                                <Link to='consultoria-y-asesoria'>
                                    <div className="marketing-service w-100 d-flex align-items-center">
                                        <h2 className='fs-4 px-4 w-100 poppins fw-normal mb-0'>Servicios de consultoría y asesoría</h2>
                                        <svg width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke='#FFFFFF' fill="none">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <path d="M17 7l-10 10" />
                                            <path d="M8 7l9 0l0 9" />
                                        </svg>   
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section-big produccion-section overflow-hidden container-fluid d-flex align-items-center mb-5 position-relative">
                    <div className="overlay w-100 h-100">
                        <div className="h-100 w-100"></div>
                    </div>
                    <div className="position-absolute w-100 h-100" id='slide-card'>
                        <img className='w-100 h-100 object-fit-cover' src={slideCardContent.imagen} alt={slideCardContent.titulo} />
                    </div>
                    <div className="container-xxl d-flex position-relative z-5 produccion-audiovisual-container align-items-center mb-5 mb-sm-0">
                        <div className='col-xl-8 col-lg-10 col-12 pe-xl-5 p-0'>
                            <h2 className='fw-bold fs-big-title py-5 py-sm-0'>PRODUCCIÓN AUDIOVISUAL</h2>
                            <h2 className='fw-bold fs-title'>INNOVADORA y PROFESIONAL</h2>
                            <h3 className='fw-bold text-shadow fs-3 my-4'>Ofrecemos servicios de calidad para garantizar su éxito.</h3>
                            <div className="py-1 bg-dark w-50 mb-4"></div>
                            <h3 className='fw-bold text-shadow fs-3 my-4'>¿Qué estás buscando?</h3>
                            <div className="d-flex gap-2 produccion-services">
                                <div className="col-12 col-sm d-flex flex-column gap-1">
                                    {
                                        serviciosProduccion[0].map((servicio,index)=>{
                                            return (
                                                    <div key={index} className="produccion-service d-flex align-items-center" onMouseEnter={()=>changeContent(servicio)}>
                                                        <p className='px-4 my-0 py-0 w-100 d-flex align-items-center justify-content-between'>
                                                            {servicio.titulo}
                                                            {servicio.icono}
                                                        </p>
                                                    </div>
                                            )
                                        })
                                    }
                                </div>
                                <div className="col-12 col-sm d-flex flex-column gap-1">
                                    {
                                        serviciosProduccion[1].map((servicio,index)=>{
                                            return (
                                                    <div key={index} className="produccion-service d-flex align-items-center" onMouseEnter={()=>changeContent(servicio)}>
                                                        <p className='px-4 my-0 py-0 w-100 d-flex align-items-center justify-content-between'>
                                                            {servicio.titulo}
                                                            {servicio.icono}
                                                        </p>
                                                    </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-10 col-12 produccion-servicio-container">
                            <div className="d-flex justify-content-center align-items-center mt-0 py-xl-5 py-3 w-100 h-100 rounded-4 produccion-servicio-content position-relative">
                                <div className="servicio-content-image-container w-100 h-100">
                                    <img src={slideCardContent.imagen} className="w-100 h-100 object-fit-cover rounded-4" alt={slideCardContent.titulo} />
                                </div>
                                <div className='d-flex flex-column align-items-center justify-content-center' id='service-content'>
                                    <div className='d-flex flex-column gap-1 justify-content-center align-items-center px-5'>
                                        {slideCardContent.icono}
                                        <p className='m-0 p-0 fs-4 text-center text-light uppercase poppins'>{slideCardContent.titulo}</p>
                                    </div>
                                    <p className='text-light p-sm-5 p-2 py-0 mt-4 text-center'>{slideCardContent.descripcion}</p>
                                    <Link to="/servicios/contacto/"><button>CONTACTANOS</button></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <RegularNavbar />
            </main>
        </>
    )
}

export default window.innerWidth >= 1000 ? transicionEscalera(Produccion) : transicionBottomTop(Produccion);
