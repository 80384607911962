import React, { useRef, useEffect } from 'react';
import emailjs from 'emailjs-com';
import RegularNavbar from '../Navbars/RegularNavbar';
import Aos from 'aos';
import transicionEscalera from '../Transiciones/transicionEscalera';
import transicionBottomTop from '../Transiciones/transicionBottomTop';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contacto = () => {
    const isMountedRef = useRef(false);
    useEffect(() => {
        if (!isMountedRef.current) {
            window.scroll(0, 0);
            isMountedRef.current = true;
        }
        Aos.init();
    }, []);

    // Function to handle form submission
    function sendEmail(e) {
        e.preventDefault();

        emailjs.sendForm('FISP_FormData', 'template_fisp_educativo', e.target, 'g0e2Wv5--pRAWU3Jm')
            .then((result) => {
                console.log(result.text);
                toast.success('El correo fue enviado con éxito!');
            }, (error) => {
                console.log(error.text);
                toast.error('No se pudo enviar el correo.');
            });

        e.target.reset();
    }

    return (
        <>
            <main className='container-fluid px-0' id='main-element'>
                <ToastContainer />
                <div className="contact-main-image w-100 position-relative">
                    <div className="overlay d-flex d-flex flex-column justify-content-end">
                        <div className="container-xxl">
                            <h1 className='poppins text-light text-shadow fs-0 fw-semibold ps-2 ps-sm-5 fs-title'>Contacto</h1>
                            <p className='text-light fw-light text-shadow-2 fs-5 ps-2 ps-sm-5'>Diseñemos juntos tu proyecto de bienestar.</p>
                        </div>
                    </div>
                </div>
                <div className="container-xxl">
                    <div className="row pb-5 mb-5">
                        <div className="col-lg-5 ps-lg-5 pt-5 pb-2 py-lg-5 px-3 px-sm-5">
                            <div className="contact-content d-flex flex-column">
                                <p className='fw-normal'>Somos el multimedio de salud digital más especializado del país. Combinamos educación, salud y tecnología.</p>
                                <p className='fw-normal'>Si te interesan nuestros cursos, capacitaciones o requerís nuestros servicios, completá el formulario de contacto y nos comunicaremos a la brevedad.</p>
                                <p className='fw-normal'>Si prefieres un contacto directo, escribinos:</p>
                                <p className='d-flex gap-3 fw-medium'>
                                    <svg width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z" />
                                        <path d="M3 7l9 6l9 -6" />
                                    </svg>
                                    info@fisp.org.ar
                                </p>
                                <p className='d-flex gap-3 fw-medium'>
                                    <svg width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
                                    </svg>
                                    +54 9 11 5252-2597
                                </p>
                                <p className='fw-normal'>Tu salud y bienestar son nuestra prioridad, esperamos poder trabajar contigo.</p>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-0 py-sm-3 py-0"></div>
                        <div className="col-lg-6 col-md-12 py-3 px-3 px-sm-5 px-lg-3">
                            <form className='contact-form pt-4' onSubmit={(e)=>{
                                sendEmail(e)
                                console.log(e.value)
                            }}>
                                <div className="row mb-3">
                                    <div className="col">
                                        <label htmlFor="nombre" className="form-label">Nombre</label>
                                        <input type="text" className="form-control bg-light" id="nombre" name="nombre" />
                                    </div>
                                    <div className="col">
                                        <label htmlFor="apellido" className="form-label">Apellido</label>
                                        <input type="text" className="form-control bg-light" id="apellido" name="apellido" />
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="empresa" className="form-label">Empresa</label>
                                    <input type="text" className="form-control bg-light" id="empresa" name="empresa" />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">Email</label>
                                    <input type="email" className="form-control bg-light" id="email" name="email" />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="telefono" className="form-label">Teléfono</label>
                                    <input type="tel" className="form-control bg-light" id="telefono" name="telefono" />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="ciudad" className="form-label">Ciudad</label>
                                    <input type="text" className="form-control bg-light" id="ciudad" name="ciudad" />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="mensaje" className="form-label">Mensaje</label>
                                    <textarea className="form-control bg-light" id="mensaje" name="mensaje" rows="4"></textarea>
                                </div>
                                <button type="submit" className="btn btn-success">Enviar mensaje</button>
                            </form>
                        </div>
                    </div>
                </div>
                <RegularNavbar />
            </main>
        </>
    );
};

export default window.innerWidth >= 1000 ? transicionEscalera(Contacto) : transicionBottomTop(Contacto);
