import React from 'react'

const Cubo = (props) => {
    const { iconImage,iconDescription} = props;

    return (
        <div className="cube">
            <div className="cube-face front-face d-flex flex-column justify-content-center align-items-center">
                <img className='w-50' src={iconImage} alt="" />
                <h3 className='fs-5 text-center pt-3'>{iconDescription}</h3>
            </div>
            <div className="cube-face right-face"></div>
            {/* <div className="cube-face back-face">
                <p className='text-center'>{content}</p>
            </div> */}
        </div>
    )
}

export default Cubo