import posgradoNutricion from '../multimedia/imagenes/educativo/cursos/portada/posgrado-nutricion.jpg'
import diplomaturaBioremodelacionFacial from '../multimedia/imagenes/educativo/cursos/portada/remodelacion-facial.jpg'
import diplomaturaCuidadosPediatricosRespiratorios from '../multimedia/imagenes/educativo/cursos/portada/pediatrico-respiratoria.jpg'
import posgradoNutricionMicrobiota from '../multimedia/imagenes/educativo/cursos/portada/nutricion-microbiota.jpg'
import diplomaturaNutricionDeportiva from '../multimedia/imagenes/educativo/cursos/portada/nutricion-deportiva.jpg'
import diplomaturaEnfermeriaOncologica from '../multimedia/imagenes/educativo/cursos/portada/enfermeria-oncologica.jpg'
import posgradoDermatologiaEsteticaTerapeutica from '../multimedia/imagenes/educativo/cursos/portada/dermatologia-estetica-terapeutica.jpg'
import cursoPrehospitalarioEmergencias from '../multimedia/imagenes/educativo/cursos/portada/prehospitalario-emergencias.jpg'
import diplomaturaEnfermeriaCuidadosPaliativos from '../multimedia/imagenes/educativo/cursos/portada/enfermeria-cuidados-paliativos.jpg'
import diplomaturaGestionServiciosEmpresasSalud from '../multimedia/imagenes/educativo/cursos/portada/gestion-servicios-empresas-salud.jpg'
import diplomaturaGestionAdministracionTelemedicina from '../multimedia/imagenes/educativo/cursos/portada/gestion-administracion-telemedicina.jpg'
import diplomaturaNutricionDiabetes from '../multimedia/imagenes/educativo/cursos/portada/nutricion-y-diabetes.png'
import diplomaturaSkincare from '../multimedia/imagenes/educativo/cursos/portada/skincare.png'
import diplomaturaEnfermeriaNeurologica from '../multimedia/imagenes/educativo/cursos/portada/enfermeria-neurologica.png'
import diplomaturaTCA from '../multimedia/imagenes/educativo/cursos/portada/tca.png'
import diplomaturaTerapiaIntensivaPediatrica from '../multimedia/imagenes/educativo/cursos/portada/terapia-intensiva-pediatrica.png'
import posgradoGestionAdministracionEnfermeria from '../multimedia/imagenes/educativo/cursos/portada/gestion-administracion-enfermeria.jpg'
import diplomaturaToxinaBotulinica from '../multimedia/imagenes/educativo/cursos/portada/toxina-botulinica.jpg'
import diplomaturaUrgenciasToxicologia from '../multimedia/imagenes/educativo/cursos/portada/urgencias-toxicologia.jpg'
import programaInmunizaciones from '../multimedia/imagenes/educativo/cursos/portada/temp-programa-inmunizaciones.jpg'
import peelingCosmiatras from '../multimedia/imagenes/educativo/cursos/portada/temp-peeling-cosmiatras.png'
import peelingDermatologos from '../multimedia/imagenes/educativo/cursos/portada/temp-peeling-dermatologos.png'
import nutricionVidaSaludable from '../multimedia/imagenes/educativo/cursos/portada/temp-nutricion-vida-saludable.png'
import posgradoPsiquiatriaClinica from '../multimedia/imagenes/educativo/cursos/portada/psiquiatria-clinica.png'
import diplomaturaUrgenciasNeurocardiologicas from '../multimedia/imagenes/educativo/cursos/portada/urgencias-neurocardiologicas.png'
import posgradoMedicinaPrecisionGenomica from '../multimedia/imagenes/educativo/cursos/portada/precision-genomica.png'
import diplomaturaInteligenciaArtificialMedica from '../multimedia/imagenes/educativo/cursos/portada/inteligencia-artificial.png'
import diplomaturaEnfermeriaConsumosProblematicas from '../multimedia/imagenes/educativo/cursos/portada/consumos-problematicos.png'
import RCP from '../multimedia/imagenes/educativo/cursos/portada/rcp.jpg'

export const cursos = [
    {
        imagen: posgradoNutricion,
        categoria: "nutricion",
        tipoCapacitacion: "posgrado",
        director: "Dra. Elena Pastor Manfredi",
        especialidad: "Posgrado en Nutrición Clínica",
        fecha: "13 de Marzo - 2025",
        descripcion: "La nutrición tiene un papel fundamental en el estado de la microbiota intestinal, sobre todo, cuando el paciente padece algún tipo de patología ...",
        titulo: "Título FISP y Facultad de Medicina de la UBA",
        link: "/educativo/lista-posgrados/nutricion-clinica"
    },{
        imagen: posgradoDermatologiaEsteticaTerapeutica,
        categoria: "dermatologia",
        tipoCapacitacion: "posgrado",
        director: "Dra. Elena Pastor Manfredi",
        especialidad: "Posgrado en Dermatología Estética y Terapéutica",
        fecha: "12 de Marzo - 2025",
        descripcion: "Consiste en brindar los conocimientos necesarios, actualizados y con los más altos estándares tecnológicos para la realización de procedimientos ...",
        titulo: "Título FISP y Facultad de Medicina de la UBA",
        link: "/educativo/lista-posgrados/dermatologia-estetica-terapeutica"
    },{
        imagen: posgradoGestionAdministracionEnfermeria,
        categoria: "enfermeria",
        tipoCapacitacion: "posgrado",
        director: "Lic. Matías Isólica",
        especialidad: "Posgrado de Gestión y Administración en Enfermería",
        fecha: "18 de Marzo - 2025",
        descripcion: "La gestión y administración efectiva de los servicios de enfermería se ha convertido en un factor clave para asegurar la calidad y eficiencia ...",
        titulo: "Título FISP y Facultad de Medicina de la UBA",
        link: "/educativo/lista-posgrados/gestion-y-administracion-en-enfermeria"
    },{
        imagen: posgradoPsiquiatriaClinica,
        categoria: "psiquiatria",
        tipoCapacitacion: "posgrado",
        director: "Dr. Oscar Eduardo Slipak",
        especialidad: "Posgrado en Psiquiatría Clínica",
        fecha: "17 de Marzo - 2025",
        descripcion: "En la actualidad, el campo de la psiquiatría clínica se enfrenta a un creciente número de desafíos, particularmente en el manejo de ...",
        titulo: "Título FISP y Facultad de Medicina de la UBA",
        link: "/educativo/lista-posgrados/psiquiatria-clinica"
    }
    // ,{
    //     imagen: diplomaturaCuidadosPediatricosRespiratorios,
    //     categoria: "enfermeria",
    //     tipoCapacitacion: "diplomatura",
    //     director: "Stella Pierini",
    //     especialidad: "Diplomatura en Cuidados Pediátricos Respiratorios",
    //     fecha: "11 de Abril - 2024",
    //     descripcion: "Adquirir las competencias que permitan proporcionar un cuidado integral y seguro a los pacientes en las unidades pediátricas...",
    //     titulo: "Título FISP y Facultad de Medicina de la UBA",
    //     link: "/educativo/lista-diplomaturas/cuidados-pediatricos-respiratorios"
    // }
    ,{
        imagen: posgradoNutricionMicrobiota,
        categoria: "nutricion",
        tipoCapacitacion: "posgrado",
        director: "Dra. Elena Pastor Manfredi",
        especialidad: "Posgrado en Nutrición y Microbiota",
        fecha: "21 de Marzo - 2025",
        descripcion: "La nutrición tiene un papel fundamental en el estado de la microbiota intestinal, sobre todo, cuando el paciente padece algún tipo de patología ...",
        titulo: "Título FISP y Facultad de Medicina de la UBA",
        link: "/educativo/lista-posgrados/nutricion-y-microbiota"
    },{
        imagen: posgradoMedicinaPrecisionGenomica,
        categoria: "genetica",
        tipoCapacitacion: "posgrado",
        director: "Dr. Raúl F. Pastor",
        especialidad: "Posgrado en Medicina de Precisión y Genómica",
        fecha: "21 de Marzo - 2025",
        descripcion: "La medicina de precisión es un enfoque emergente en los últimos 30 años para el tratamiento y prevención de enfermedades ...",
        titulo: "Título FISP y Facultad de Medicina de la UBA",
        link: "/educativo/lista-posgrados/medicina-precision-y-genomica"
    },{
        imagen: diplomaturaBioremodelacionFacial,
        categoria: "dermatologia",
        tipoCapacitacion: "diplomatura",
        director: "Dr. Ricardo Lingua",
        especialidad: "Diplomatura en Bioremodelación Facial",
        fecha: "12 de Marzo - 2025",
        descripcion: "Optimizar el conocimiento de los diferentes tipos de implantes de ácido hialurónico y otros semipermanentes, tales como la hidroxiapatita de calcio ...",
        titulo: "Título FISP y Facultad de Medicina de la UBA",
        link: "/educativo/lista-diplomaturas/bioremodelacion-facial"
    },{
        imagen: diplomaturaEnfermeriaOncologica,
        categoria: "enfermeria",
        tipoCapacitacion: "diplomatura",
        director: "Lic. Cynthia de Arma",
        especialidad: "Diplomatura en Enfermería Oncológica",
        fecha: "15 de Marzo - 2025",
        descripcion: "Te brindaremos las herramientas necesarias para que continúes especializándote y adquiriendo herramientas científicas que te ayudarán a implementar ...",
        titulo: "Título FISP y Facultad de Medicina de la UBA",
        link: "/educativo/lista-diplomaturas/enfermeria-oncologica"
    },{
        imagen: diplomaturaEnfermeriaCuidadosPaliativos,
        categoria: "enfermeria",
        tipoCapacitacion: "diplomatura",
        director: "Lic. Cynthia De Armas",
        especialidad: "Diplomatura en Enfermería: Cuidados Paliativos",
        fecha: "15 de Marzo - 2025",
        descripcion: "Capacitar al Enfermero/a en la disciplina de los Cuidados Paliativos en pacientes pediátricos y adultos ...",
        titulo: "Título FISP y Facultad de Medicina de la UBA",
        link: "/educativo/lista-diplomaturas/enfermeria-cuidados-paliativos-en-pacientes-adultos-y-pediatricos"
    },{
        imagen: diplomaturaGestionServiciosEmpresasSalud,
        categoria: "gestion",
        tipoCapacitacion: "diplomatura",
        director: "Dr. Carlos Di Pietrantonio",
        especialidad: "Diplomatura en Gestión de Servicios y Empresas de Salud",
        fecha: "18 de Marzo - 2025",
        descripcion: "Brindará los conocimientos y herramientas necesarias para gestionar y administrar empresas y servicios de salud ...",
        titulo: "Título FISP y Facultad de Medicina de la UBA",
        link: "/educativo/lista-diplomaturas/gestion-de-servicios-y-empresas-de-salud"
    },{
        imagen: diplomaturaNutricionDeportiva,
        categoria: "nutricion",
        tipoCapacitacion: "diplomatura",
        director: "Dra. Elena Pastor Manfredi",
        especialidad: "Diplomatura en Nutrición Deportiva de Alto Rendimiento",
        fecha: "13 de Marzo - 2025",
        descripcion: "Esta diplomatura le brindará a los participantes las herramientas necesarias para diseñar planes de alimentación adecuados para atletas de alto rendimiento ...",
        titulo: "Título FISP",
        link: "/educativo/lista-diplomaturas/nutricion-deportiva-de-alto-rendimiento"
    },{
        imagen: diplomaturaUrgenciasToxicologia,
        categoria: "toxicologia",
        tipoCapacitacion: "diplomatura",
        director: "Dr. Dadic Francisco",
        especialidad: "Diplomatura de Urgencias en Toxicología",
        fecha: "13 de Marzo - 2025",
        descripcion: "La diplomatura brindará los conocimientos y herramientas necesarias para desarrollar una práctica médica de alto nivel en el manejo de un paciente intoxicado ...",
        titulo: "Título FISP y Facultad de Medicina de la UBA",
        link: "/educativo/lista-diplomaturas/urgencias-en-toxicologia"
    }
    // ,{
    //     imagen: diplomaturaNutricionDiabetes,
    //     categoria: "nutricion",
    //     tipoCapacitacion: "diplomatura",
    //     director: "Dra. Elena Pastor Manfredi",
    //     especialidad: "Diplomatura de Nutrición y Diabetes tipo 2",
    //     fecha: "Modalidad Asincrónica",
    //     descripcion: "El profesional adquirirá los conocimientos científicos actualizados sobre diabetes tipo 2. Se establecerá un programa de tratamiento que incluya ...",
    //     titulo: "Título FISP y Facultad de Medicina de la UBA",
    //     link: "/educativo/lista-diplomaturas/nutricion-y-diabetes-tipo-2"
    // }
    ,{
        imagen: diplomaturaSkincare,
        categoria: "dermatologia",
        tipoCapacitacion: "diplomatura",
        director: "Dr. Diego Slipak",
        especialidad: "Diplomatura especializada en Skincare",
        fecha: "18 de Marzo - 2025",
        descripcion: "Los participantes adquirirán los conocimientos teóricos y prácticos necesarios para comprender los fundamentos científicos del cuidado de ...",
        titulo: "Título FISP",
        link: "/educativo/lista-diplomaturas/skincare"
    }
    // ,{
    //     imagen: diplomaturaEnfermeriaNeurologica,
    //     categoria: ["neurologia","enfermeria"],
    //     tipoCapacitacion: "diplomatura",
    //     director: "Lic. Maximiliano Santos",
    //     especialidad: "Diplomatura en Enfermería Neurológica",
    //     fecha: "19 de Agosto",
    //     descripcion: "Una oportunidad única para aquellos profesionales de enfermería interesados en adquirir conocimientos especializados en el cuidado de pacientes ...",
    //     titulo: "Título FISP",
    //     link: "/educativo/lista-diplomaturas/enfermeria-neurologica"
    // }
    // ,{
    //     imagen: diplomaturaTCA,
    //     categoria: "psicologia",
    //     tipoCapacitacion: "diplomatura",
    //     director: "Lic. Sol Buscio",
    //     especialidad: "Diplomatura en TCA, Abordaje integral y Enfoque práctico",
    //     fecha: "12 de Agosto",
    //     descripcion: "Debido a la prevalencia y a las consecuencias que producen los trastornos de alimentación es imprescindible realizar una labor preventiva, especialmente ...",
    //     titulo: "Título FISP y Facultad de Medicina de la UBA",
    //     link: "/educativo/lista-diplomaturas/tca-abordaje-integral-y-enfoque-practico"
    // }
    ,{
        imagen: diplomaturaTerapiaIntensivaPediatrica,
        categoria: "enfermeria",
        tipoCapacitacion: "diplomatura",
        director: "Mg. Pierini Stella Maris",
        especialidad: "Diplomatura para Enfermería en terapia intensiva pediátrica",
        fecha: "15 de Marzo - 2025",
        descripcion: "La atención de los pacientes internados en las Unidades de terapia intensiva pediátricas requieren que el personal logre actualización en su...",
        titulo: "Título FISP y Facultad de Medicina de la UBA",
        link: "/educativo/lista-diplomaturas/terapia-intensiva-pediatrica"
    },{
        imagen: diplomaturaUrgenciasNeurocardiologicas,
        categoria: ["neurologia", "cardiologia"],
        tipoCapacitacion: "diplomatura",
        director: "Dr. Matías J. Alet",
        especialidad: "Diplomatura en Urgencias Neurocardiológicas",
        fecha: "7 de Abril - 2025",
        descripcion: "La Diplomatura en Urgencias Neurocardiológicas desempeña un papel crucial en la formación de profesionales de la salud al brindarles...",
        titulo: "Título FISP y Facultad de Medicina de la UBA",
        link: "/educativo/lista-diplomaturas/urgencias-neurocardiologicas"
    },{
        imagen: diplomaturaInteligenciaArtificialMedica,
        categoria: "inteligencia-artificial",
        tipoCapacitacion: "diplomatura",
        director: "Dr. Paolo Lauretta",
        especialidad: "Diplomatura en Inteligencia Artificial Médica",
        fecha: "17 de Marzo - 2025",
        descripcion: "La ciencia siempre ha respondido a las necesidades de la sociedad con avances tecnológicos. Hoy en día la Inteligencia Artificial...",
        titulo: "Título FISP y Facultad de Medicina de la UBA",
        link: "/educativo/lista-diplomaturas/inteligencia-artificial-medica"
    },{
        imagen: diplomaturaEnfermeriaConsumosProblematicas,
        categoria: "enfermeria",
        tipoCapacitacion: "diplomatura",
        director: "Lic. Leonardo De Vincentiis",
        especialidad: "Diplomatura de Enfermeria en Consumos Problemáticos",
        fecha: "22 de Marzo - 2025",
        descripcion: "El rol de la enfermería en la atención integral de pacientes con consumo problemático de sustancias es fundamental. Como profesionales...",
        titulo: "Título FISP y Facultad de Medicina de la UBA",
        link: "/educativo/lista-diplomaturas/enfermeria-consumos-problematicos"
    }
    // ,{
    //     imagen: cursoPrehospitalarioEmergencias,
    //     categoria: "emergencias",
    //     tipoCapacitacion: "curso",
    //     director: "Dr. Jorge Montessi",
    //     especialidad: "Curso de Operador prehospitalario de emergencias",
    //     fecha: "08 de Abril - 2024",
    //     descripcion: "Proporcionar una formación profesional, profundizando el conocimiento semiológico y toma de decisiones en el campo de la atención prehospitalaria ...",
    //     titulo: "Certificación FISP y Facultad de Medicina de la UBA",
    //     link: "/educativo/lista-cursos/operador-prehospitalario-de-emergencias"
    // },{
    //     imagen: programaInmunizaciones,
    //     categoria: "enfermeria",
    //     tipoCapacitacion: "curso",
    //     director: "Dr. Abel Luis Agüero",
    //     especialidad: "Programa Ampliado de Inmunizaciones",
    //     fecha: "Modalidad Asincrónica",
    //     descripcion: "El programa brindará conocimientos acerca de la inmunización por vacunas y las técnicas de aplicación de las mismas para ...",
    //     titulo: "Certificación FISP",
    //     link: "/educativo/lista-cursos/programa-ampliado-de-inmunizaciones"
    // },{
    //     imagen: RCP,
    //     categoria: "emergencias",
    //     tipoCapacitacion: "curso",
    //     director: "Dr. Jorge Montessi",
    //     especialidad: "RCP",
    //     fecha: "Modalidad Asincrónica",
    //     descripcion: "El programa brindará conocimientos para reconocer a la víctima, organizar el evento, iniciar las maniobras de RCP básicas que permitirán ...",
    //     titulo: "Certificación FISP",
    //     link: "/educativo/lista-cursos/curso-de-rcp"
    // },{
    //     imagen: peelingCosmiatras,
    //     categoria: "dermatologia",
    //     tipoCapacitacion: "curso",
    //     director: "Dr. Diego Slipak",
    //     especialidad: "Peeling para Cosmiatras",
    //     fecha: "Modalidad Asincrónica",
    //     descripcion: "El programa brindará conocimientos sobre tratamiento con diferentes tipos de peelings químicos y su asociación con peelings mecánicos y ...",
    //     titulo: "Certificación FISP",
    //     link: "/educativo/lista-cursos/peeling-cosmiatras"
    // },{
    //     imagen: peelingDermatologos,
    //     categoria: "dermatologia",
    //     tipoCapacitacion: "curso",
    //     director: "Dr. Diego Slipak",
    //     especialidad: "Peeling para Dermatólogos",
    //     fecha: "Modalidad Asincrónica",
    //     descripcion: "Capacitar al alumno para el correcto empleo de diferentes sustancias químicas y porcentaje de las mismas según la patología y necesidades ...",
    //     titulo: "Certificación FISP",
    //     link: "/educativo/lista-cursos/peeling-dermatologos"
    // },{
    //     imagen: nutricionVidaSaludable,
    //     categoria: "nutricion",
    //     tipoCapacitacion: "curso",
    //     director: "Dra. Elena Pastor Manfredi",
    //     especialidad: "Curso de Nutrición y Estilo de Vida Saludable",
    //     fecha: "Modalidad Asincrónica",
    //     descripcion: "La idea de este curso es aprender sobre las bases para tener una alimentación equilibrada, proporcionar los conocimientos básicos sobre la nutrición ...",
    //     titulo: "Certificación FISP",
    //     link: "/educativo/lista-cursos/nutricion-vida-saludable"
    // }
]
