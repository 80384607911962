import React, { useContext, useEffect, useState } from 'react'
import Axios from "axios";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import parse from 'html-react-parser';
import NavbarPortal from '../Navbars/NavbarPortal';
import FooterPortal from './FooterPortal';
import transicionEscalera from '../Transiciones/transicionEscalera';
import transicionBottomTop from '../Transiciones/transicionBottomTop';
import editorSkeleton from '../multimedia/imagenes/portal/editor.png'
import { context } from '../Context/Context';

const OrganizarNotas = () => {
    
    const [noticias,setNoticias] = useState([])

    const [ordenHome,setOrdenHome] = useState()
    const [nota,setNota] = useState({})


    const obtenerNotasActualizadas = ()=>{
        Axios.get("https://srv452353.hstgr.cloud/obtenernotas/")
        .then((response) => {
            // sessionStorage.setItem("NoticiasCargadas", JSON.stringify(Array.from(response.data)));
            console.log(response.data)
            setNoticias(Array.from(response.data))
        })
        .catch((error) => {
            console.log("Error al traer las noticias")
            console.error(error);
        })
    }

    const actualizarOrden = ()=>{

        //Checkear que no haya una nota previamente en esa posición en el home
        const notaPrevia = noticias.find(noticia=>noticia.presente_home == 1 && noticia.orden_home == ordenHome)
        if(notaPrevia){
            //Retirar la previa nota del home
            Axios.post("https://srv452353.hstgr.cloud/ordenarhome",{
                idnota: notaPrevia.id,
                presenteHome: 0,
                ordenHome: 0
            }).then((result)=>{
                console.log(result)
                console.log("La noticia de id: '", notaPrevia.id, "' fue retirada del home con éxito")
            }).catch((e)=>{
                console.log("Error al retirar la nota del home")
                console.log(e)
            })

            //Agregar la nueva nota al home
            Axios.post("https://srv452353.hstgr.cloud/ordenarhome",{
                idnota: nota.id,
                presenteHome: 1,
                ordenHome: ordenHome
            }).then((result)=>{
                console.log(result)
                console.log("Se agregó la nota de id: '", nota.id, "' al home con éxito")
            }).catch((e)=>{
                console.log("Error al agregar la nota al home")
                console.log(e)
            }).finally(()=>{
                obtenerNotasActualizadas()
            })
        } else{
            //Agregar la nueva nota al home
            Axios.post("https://srv452353.hstgr.cloud/ordenarhome",{
                idnota: nota.id,
                presenteHome: 1,
                ordenHome: ordenHome
            }).then((result)=>{
                console.log(result)
                console.log("Se agregó la nota de id: '", nota.id, "' al home con éxito")
            }).catch((e)=>{
                console.log("Error al agregar la nota al home")
                console.log(e)
            }).finally(()=>{
                obtenerNotasActualizadas()
            })
        }
    }

    useEffect(()=>{
        obtenerNotasActualizadas()
    },[])

    return (
        <main className='main-editor-container'>
            <NavbarPortal />
            <div className='container-xxl editor-container d-flex flex-column align-items-center'>
                <div className="editor d-flex align-items-center flex-column gap-2 w-100">
                    <div className="col-12 pb-5 d-flex flex-column px-4">
                        <div className="w-100 d-flex flex-column justify-content-center py-4 mb-3">
                            <p className='text-center poppins fs-2 border-bottom pb-2 uppercase'>Layout Home</p>
                            <img className='w-100 rounded-4 box-shadow' src={editorSkeleton} alt="" />
                        </div>
                        <div className="d-flex align-items-center justify-content-center gap-4 mb-4">
                            <label htmlFor="article-select">Nota: </label>
                            <select 
                                name="article"
                                id="article-select"
                                onChange={(e)=>{
                                    const selectedNoticia = noticias.find(noticia => noticia.titulo === e.target.value);
                                    setNota(selectedNoticia);
                                }}
                                className="p-2 col-7 rounded-3"
                                aria-label="Default select example">
                                <option value="" disabled selected  ></option>
                                {
                                    noticias.map((noticia,index)=>{
                                        return(
                                            <option key={index} value={noticia.titulo}>{noticia.titulo}</option>
                                        )
                                    })
                                }
                                
                            </select>
                            <label htmlFor="orden-home">Orden Home: </label>
                            <select 
                                name="orden-home"
                                id="orden-home" 
                                className='p-2 col-1 rounded-3'
                                onChange={(e)=>{setOrdenHome(e.target.value)}}
                                >
                                <option value="" disabled selected></option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                            </select>
                        </div>
                        <div className="w-100 p-5">
                            <p className='text-secondary'><span className='text-danger'>IMPORTANTE: </span>Debe siempre haber una noticia por cada posición, de lo contrario habrá una posición vacía</p>
                            {[1, 2, 3, 4, 5, 6].map(index => (
                                <div key={index} className='my-2 border d-flex align-items-center'>
                                    <p className='col-2 mb-0 p-2 border text-center'>
                                        Posición: {index}
                                    </p>
                                    <svg className='col-1' width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                        <path d="M12.089 3.634a2 2 0 0 0 -1.089 1.78l-.001 2.586h-4.999a1 1 0 0 0 -1 1v6l.007 .117a1 1 0 0 0 .993 .883l4.999 -.001l.001 2.587a2 2 0 0 0 3.414 1.414l6.586 -6.586a2 2 0 0 0 0 -2.828l-6.586 -6.586a2 2 0 0 0 -2.18 -.434l-.145 .068z" strokeWidth="0" fill="currentColor" />
                                        <path d="M3 8a1 1 0 0 1 .993 .883l.007 .117v6a1 1 0 0 1 -1.993 .117l-.007 -.117v-6a1 1 0 0 1 1 -1z" strokeWidth="0" fill="currentColor" />
                                    </svg>
                                    <p className='col-9 mb-0 p-2 border'>
                                        {noticias.find(nota => nota.orden_home === index) ? 
                                            noticias.find(nota => nota.orden_home === index).titulo : 
                                            <span className="text-danger">VACÍO - ASIGNAR NOTICIA</span>}
                                    </p>
                                </div>
                            ))}
                        </div>

                        <div className="p-2">
                            <button className='px-5 w-100 py-3 border-0 rounded-3' onClick={()=>{
                                actualizarOrden()
                            }}>
                                Actualizar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <FooterPortal />
        </main>
    )
}

export default window.innerWidth >= 1000 ? transicionEscalera(OrganizarNotas) : transicionBottomTop(OrganizarNotas);
