import { useParams } from 'react-router-dom';
import OperadorPrehospitalarioEmergencias from '../OperadorPrehospitalarioEmergencias';
import PaginaError from '../../../PaginaError';
import ProgramaInmunizaciones from '../ProgramaInmunizaciones';
import CursoRCP from '../CursoRCP';
import PeelingCosmiatras from '../PeelingCosmiatras';
import PeelingDermatologos from '../PeelingDermatologos';
import NutricionVidaSaludable from '../NutricionVidaSaludable';

const CursoRedirect = () => {
    const { curso } = useParams();
    let serviceComponent;
    switch (curso) {
        // case 'operador-prehospitalario-de-emergencias':
        //     serviceComponent = <OperadorPrehospitalarioEmergencias />
        //     break;  
        // case 'programa-ampliado-de-inmunizaciones':
        //     serviceComponent = <ProgramaInmunizaciones />
        //     break;
        // case 'curso-de-rcp':
        //     serviceComponent = <CursoRCP />
        //     break;
        // case 'peeling-cosmiatras':
        //     serviceComponent = <PeelingCosmiatras />
        //     break;
        // case 'peeling-dermatologos':
        //     serviceComponent = <PeelingDermatologos />
        //     break;
        // case 'nutricion-vida-saludable':
        //     serviceComponent = <NutricionVidaSaludable />
        //     break;
        default:
            serviceComponent = <PaginaError />
            break;
    }

    return serviceComponent
};

export default CursoRedirect;