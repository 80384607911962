import React from 'react'

const TemarioTCA = () => {
    return (
        <div className='temario-container border w-100 p-5'>
            <span>Módulo 1. CONCEPTOS BASE</span>
            <br /><br />
            <ul className='d-flex flex-column-reverse'>
                <li className='fw-light'>Alimentación saludable. Definición de conceptos: hambre, apetito, palatabilidad, saciedad, hábitos alimentarios. Comer emocional. Culto al cuerpo y a la imagen. </li>
                <br /><br />
                <span>Objetivos: identificar rasgos y conceptos elementales que funcionan como base de los TCA</span>
            </ul>
            <br />
            <span>Módulo 2. TCA DSM IV Y DSM V</span>
            <br /><br />
            <ul className='d-flex flex-column-reverse'>
                <li className='fw-light'>Criterios diagnósticos: DSM IV Y DSM V. Definición y características de los TCA. Pica. Trastorno de rumiación. Trastorno de evitación/restricción de la ingesta de alimentos. Anorexia Nerviosa. Bulimia Nerviosa. Trastorno de Atracones. Otros trastornos alimentarios o de la ingestión de alimentos especificados. Trastornos alimentarios o de la ingestión de alimentos no especificados. Comorbilidades. Etiología y prevalencia. </li>
                <br /><br />
                <span>Objetivo: identificación, diferenciación y diagnóstico de TCA. </span>
            </ul>
            <br />
            <span>Módulo 3. ANOREXIA NERVIOSA, BULIMIA NERVIOSA Y TRASTORNO DE ATRACONES</span>
            <br /><br />
            <ul className='d-flex flex-column-reverse'>
                <li className='fw-light'>Anorexia Nerviosa. Diagnóstico. Descripción del cuadro. Prevalencia, desarrollo y curso. Bulimia Nerviosa. Descripción del cuadro. Prevalencia, desarrollo y curso. Trastorno de Atracones. Descripción del cuadro. Prevalencia, desarrollo y curso. </li>
                <br /><br />
                <span>Objetivo: identificación, diferenciación y diagnóstico de los distintos cuadros de TCA. </span>
            </ul>
            <br />
            <span>Módulo 4.  ASPECTOS COGNITIVOS, CONDUCTUALES Y EMOCIONALES</span>
            <br /><br />
            <ul className='d-flex flex-column-reverse'>
                <li className='fw-light'>Características principales de las personas con TCA. Imagen corporal y sus distorsiones. Autoestima asociada al cuerpo y a la imagen. Aspectos cognitivos, conductuales y emocionales de las personas con TCA. Sintomatología asociada a TCA.</li>
                <br /><br />
                <span>Objetivo: entender cómo piensa, siente y actúa una persona con TCA.	</span>
            </ul>
            <br />
            <span>Módulo 5. TRATAMIENTOS</span>
            <br /><br />
            <ul className='d-flex flex-column-reverse'>
                <li className='fw-light'>Modelo de tratamiento trans-diagnótisco de Fairburn para los TCA. Terapia Cognitivo Conductual: objetivos. Fases del tratamiento. Intervenciones. Técnicas para los distintos cuadros.</li>
                <br /><br />
                <span>Objetivo: conocer y abordar los tratamientos para personas con TCA.  </span>
            </ul>
            <br />
            <span>Módulo 6. OBESIDAD</span>
            <br /><br />
            <ul className='d-flex flex-column-reverse'>
                <li className='fw-light'>Obesidad. Definición. IMC. Grados de obesidad. Terapia Cognitiva Conductual: objetivos, intervenciones y técnicas específicas en obesidad. Hábitos alimentarios saludables. </li>
                <br /><br />
                <span>Objetivo: identificación y conocimiento del cuadro de obesidad y su tratamiento.</span>
            </ul>
            <br />
            <span>Módulo 7. MINDFULNESS. SEXUALIDAD </span>
            <br /><br />
            <ul className='d-flex flex-column-reverse'>
                <li className='fw-light'>Conciencia plena aplicada a los TCA y obesidad. Técnicas de Mindfulness. Técnicas de relajación. Entrenamiento en habilidades sociales. Sexualidad. Placer en el cuerpo. Disfunciones sexuales asociadas a los TCA. Dificultad en los encuentros íntimos, en el contacto con el otro y con el propio cuerpo.</li>
                <br /><br />
                <span>Objetivo: conocer las técnicas de Mindfulness. Identificar cómo influyen los TCA en la sexualidad. </span>
            </ul>
            <br />
            <span>Módulo 8. MIRADA PSICOANALÍTICA </span>
            <br /><br />
            <ul className='d-flex flex-column-reverse'>
                <li className='fw-light'>Mirada psicoanalítica de los TCA. Neurosis y psicosis. Histeria. Melancolía. Inhibición. </li>
                <br /><br />
                <span>Objetivo: conocer la perspectiva psicoanalítica sobre los trastornos de la alimentación</span>
            </ul>
        </div>
    )
}
/*
            <br />
            <span></span>
            <br /><br />
            <ul>
                <span></span>
                <br /><br />
                <li className='fw-light'></li>
                <li className='fw-light'></li>
                <li className='fw-light'></li>
            </ul>
*/

export default TemarioTCA