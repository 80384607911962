import React from 'react'

const TemarioCuidadosPaliativos = () => {
    return (
        <div className='temario-container border w-100 p-5'>
            <span>“Gestión del cuidado Enfermero/a en Cuidado Paliativo en paciente adulto y pediátrico. Historia de los CP. Comunicación. Génesis del dolor, tratamiento farmacológico según OMS”</span>
            <br /><br />
            <ul>
                <li className='fw-light'>Cuidados Paliativos: Historia, Definición, objetivos y bases fundamentales de los Cuidados Paliativos. “Rol de la Enfermera”</li>
                <li className='fw-light'>Implicancias legales: Ley de Cuidados Paliativos 2022. Decisiones anticipadas.</li>
                <li className='fw-light'>Equipo Interdisciplinario y los Modelos de Intervención de los CP.</li>
                <li className='fw-light'>Comunicación, definición, modalidades de comunicación. Adulto y Pediátrico.</li>
                <li className='fw-light'>Claudicación familiar. Conspiración de silencio.</li>
                <li className='fw-light'>Génesis del Dolor: definición, clasificación, fisiología. Protocolo farmacológico OMS</li>
                <li className='fw-light'>Usos de Opioide, cálculos de rescates. Mitos de la Morfina. Adulto y Pediátrico.</li>
                <li className='fw-light'>Instrumentos para evaluar el dolor y control de síntomas. Aplicando la gestión del cuidado de Enfermería en CP paciente Adulto y Pediátrico.</li>
                <li className='fw-light'>Proceso de Atención de Enfermería en el dolor, administración de analgesia y efectos adversos según escalera analgésica de la OMS. Adulto y Pediátrico.</li>
            </ul>
            <br />
            <span>“Gestión del cuidado Enfermero/a en Cuidado Paliativo en paciente adulto y pediátrico. Proceso evolutivo de la enfermedad. Monitoreo en el control de los síntomas. Vías de administración utilizadas en CP”</span>
            <br /><br />
            <ul>
                <li className='fw-light'>Complicaciones respiratorias: disnea, tos, hemoptisis, secreciones respiratorias.</li>
                <li className='fw-light'>Manejo de la disnea. Gestión del cuidado de Enfermería</li>
                <li className='fw-light'>Vía Subcutánea. Hidratación, administración de opioides y otros. Gestión del cuidado de Enfermería. Adulto y pediátrico</li>
                <li className='fw-light'>Enfermedades psiquiátricas: demencia, depresión, crisis de angustia, pánico.</li>
                <li className='fw-light'>Gestión del cuidado de Enfermería en úlceras por decúbito y tumorales.</li>
                <li className='fw-light'>Nutrición en pacientes con cáncer. Tipos de alimentación y dispositivos para alimentación. Adulto y Pediátrica</li>
                <li className='fw-light'>Intervenciones de Enfermería según alimentación indicada y dispositivos Adulto y Pediátrico.</li>
                <li className='fw-light'>Casos clínicos: resolución de los casos clínicos en pacientes en C.P y Elaboración y ejecución del Proceso de Atención de Enfermería. (PAE).</li>
            </ul>
            <br />
            <span>“Gestión del cuidado Enfermero/a en Cuidado Paliativo en paciente adulto y pediátrico. Manejo de los Síntomas refractarios. Terapia de sedación. Fin de vida. Medicina integrativa. Estrategias de autocuidados para familia y equipo de interdisciplinario”</span>
            <br /><br />
            <ul>
                <li className='fw-light'>Síndrome confusional. Delirium. Terapia de sedación paliativa. Eutanasia definición.</li>
                <li className='fw-light'>Manejo de Enfermería durante las crisis. Delirium y en terapia de sedación paliativa.</li>
                <li className='fw-light'>Las últimas 24 hs de vida. Escala de valoración, ansiedad, depresión, desasosiego, insomnio, trastornos de adaptación, síntomas psicológicos.</li>
                <li className='fw-light'>Duelo normal, duelo patológico: intervenciones de Enfermería</li>
                <li className='fw-light'>Intervenciones de Enfermería en atención domiciliaria en pacientes en cuidados paliativos.</li>
                <li className='fw-light'>Decisiones Anticipadas. Derecho del Paciente en Cuidados Paliativos</li>
                <li className='fw-light'>Espiritualidad. Rol de Enfermería.</li>
                <li className='fw-light'>Autocuidado para profesionales de Enfermería en Cuidados Paliativos.</li>
            </ul>
        </div>
    )
}

export default TemarioCuidadosPaliativos