import React from 'react'

const TemarioRemodelacionFacial = () => {
    return (
        <div className='temario-container border w-100 p-5'>
            <span>Introducción: </span>
            <br /><br />
            <ul>
                <li className='fw-light'>Anatomía e histología de rostro y cuello.</li>
                <li className='fw-light'>Repaso de la histología de la piel y de la anatomía de rostro y cuello.</li>
                <li className='fw-light'>Se pondrá énfasis en la musculatura de rostro y cuello y los reparos y referencias anatómicas en lo que hace al trayecto de nervios faciales, recorrido arterial y venoso.</li>
            </ul>
            <br />
            <span>Tipos de Ácidos Hialurónicos y Fillers Semipermanentes:</span>
            <br />
            <ul>
                <li className="fw-light">Estructura química del Ácido Hialurónico, biocompatibilidad, características. Diferencias con los implantes semipermanentes.</li>
                <li className="fw-light">Clasificación de los Implantes según el tiempo de permanencia en la piel, estructura química (ácidos reticulados o de cadena simple) y densidad (destinados a corrección de arrugas, volumen, labios, contorno facial)</li>
            </ul>
            
            <br />
            <span>Usos de productos de baja densidad:</span>
            <br />
            <ul>
                <li className="fw-light">Mesoterapia: principios y definición de mesoterapia, usos de la mesoterapia en rostro y cuello, aplicación de ácidos hialurónicos a diferentes concentraciones con mesoterapia.</li>
                <li className="fw-light">Skin Boosters de Ácido Hialurónico: definición, aplicación, alcances. Rostro, cuello, escote, dorso de las manos.</li>
            </ul>
            <br />
            <span>Empleo de implantes de Ácido Hialurónico en Arrugas finas, profundas y surcos:</span>
            <br />
            <ul>
                <li className="fw-light">Se conocerán los diferentes tipos de implantes según su grado de reticulación, durabilidad y densidad y la correlación de estas características con la corrección de arrugas finas, arrugas profundas, surcos y arrugas dinámicas.</li>
                <li className="fw-light">También se tratará la corrección de cicatrices deprimidas y secuelas cicatrízales de acné</li>
            </ul>
            <br />
            <span>Empleo de ácido hialurónico en aumento de volumen facial (pómulos, contorno del rostro). Empleo de ácidos hialurónicos inteligentes (ej. Long Lasting, Profhilo). Aplicación de Radiesse y combinación de este producto con ácido hialurónico:</span>
            <br />
            <ul>
                <li className='fw-light'>Se profundizará en el conocimiento de las últimas tendencias en implantes "inteligentes" como los conocidos como Long Lasting y Profhilo y otros similares, y sus similitudes y diferencias con la hidroxiapatita de calcio. </li>
            </ul>
            <br />
            <span>Rinomodelación, aplicación de ácido hialurónico en la corrección de ojeras, surco lagrimal y aumento de volumen en pómulos y labios:</span>
            <br />
            <ul>
                <li className="fw-light">Elección de los materiales más eficaces para lograr estas correcciones.</li>
                <li className="fw-light">Densidad y aplicación adecuada de ácidos hialurónicos en la remodelación de labios.</li>
            </ul>
        </div>
    )
}

export default TemarioRemodelacionFacial